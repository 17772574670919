
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Computational model`}</h1>
    <p>{`Our `}<em parentName="p">{`computational model`}</em>{` describes how programmable transactions and programmable data behave on the lower level. It consists of several `}<em parentName="p">{`scripting languages`}</em>{` which allow developers to fully use the Zenotta blockchain capabilities.`}</p>
    <p>{`Compared to the `}<em parentName="p">{`symbolic model`}</em>{`, the computational model does not strictly verify or execute `}<em parentName="p">{`Smart Contracts`}</em>{` which are more complex and can be made up of multiple transactions and protocols. By contrast, transaction scripts execution is self-contained and atomic, meaning that scripts are always executed in their entirety and do not depend on external factors.`}</p>
    <h2>{`Transactions`}</h2>
    <p>{`The Zenotta blockchain follows the UTxO model. Similarly to the Bitcoin's distributed ledger, ownership of data and tokens is inferred from `}<em parentName="p">{`unspent`}</em>{` transaction outputs, and these records are permanent and immutable.`}</p>
    <p>{`By creating, signing, and sending transactions, users can transfer ownership of tokens and data. The Zenotta scripting language can be used to set conditions under which a transaction is spendable.`}</p>
    <p>{`Our scripting language is based on `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Script"
      }}>{`Bitcoin Script`}</a>{` and uses a similar `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Forth_(programming_language)"
      }}>{`Forth`}</a>{`-like, stack-based programming approach. Its byte code is executed by miners during the transaction verification on a stack virtual machine. Every node in the Zenotta network runs this VM.`}</p>
    <p>{`We provide extra features on top of the Bitcoin Script. As an example, an uniquely to our UTxO-based blockchain, users can employ a secure random number generator in their scripts. This paves the way for advanced Smart Contracts and use cases like fair lotteries or games.`}</p>
    <p>{`The language is purposely not Turing-complete. This approach makes it easier to formally verify scripts to make sure that transactions will not become unspendable and that there are no underhanded instructions that would render a contract unfair.`}</p>
    <p>{`The transaction scripting language is used as one of the compiler targets for our higher-level language, `}<em parentName="p">{`ZLang`}</em>{`, which composes more complex scenarios out of simpler scripts.`}</p>
    <p>{`You can find more details in an RFC that will be published on zenotta.io shortly.`}</p>
    <h2>{`Smart data`}</h2>
    <p>{`Optionally, users can use another scripting language -- `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PowerShell"
      }}><em parentName="a">{`PowerShell`}</em></a>{` -- for handling smart data. PowerShell is widely used in the industry to automate various tasks in systems administration and it's well-suited to automatically manage smart data files as part of Smart Contracts. PowerShell scripts are stored as part of `}<em parentName="p">{`smart data`}</em>{`, making them inextricably attached to the file, and when necessary, they can provide additional APIs for developers building on the Zenotta platform.`}</p>
    <p><em parentName="p">{`PowerShell`}</em>{` is also one of the targets for our high-level language `}<em parentName="p">{`ZLang`}</em>{`, meaning that in most cases you would not be required to write these scripts by hand -- the scripts will be automatically generated and verified by our compiler.`}</p>
    <p>{`Our team works on adapting `}<em parentName="p">{`PowerShell`}</em>{` to work in more restrictive environments, e.g., blockchain has a limited storage capacity, so we wrote an proposal document that describes how PowerShell scripts can be serialized down into a binary abstract syntax tree representation. You can find the document `}<a parentName="p" {...{
        "href": "https://hackmd.io/Aas4HLKFRKG5PPyJd-M3Dw"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Security considerations are also very important both for Zenotta and PowerShell. Scripts are executed in a virtualized environment and get a limited view of the host system.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;