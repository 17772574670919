
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nœud de stockage`}</h1>
    <p>{`Le nœud de stockage a une responsabilité simple et unique : stocker l'historique complet de la blockchain. Bien qu'il s'agisse d'une tâche simple, elle implique le stockage d'un certain nombre de composants dans le système Zenotta, qui seront examinés plus en détail ci-dessous.`}</p>
    <h2>{`Structure du nœud de stockage`}</h2>
    <p>{`Le nœud de stockage est géré par : `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageNode`}</inlineCode>{`:`}</strong>{` structure de données`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageInterface`}</inlineCode>{`:`}</strong>{` un trait qui définit la plupart de ses méthodes externes.`}</li>
    </ul>
    <p>{`Il existe un nombre limité de nœuds de stockage qui sont (pour le moment) sous la gestion directe de Zenotta. Tout mineur ou utilisateur public peut demander à devenir un nouveau nœud de stockage s'il le souhaite, mais cela n'est pas nécessaire pour que le système fonctionne correctement.`}</p>
    <p>{`L'anneau de nœuds de stockage fonctionne sous `}<a parentName="p" {...{
        "href": "https://raft.github.io/"
      }}>{`consensus RAFT`}</a>{` ensemble, où chaque nœud de stockage reçoit tous les blocs minés de tous les nœuds Mempool et ajoute le résultat combiné à leur grand livre interne. Les nœuds de stockage doivent également vérifier que toutes les transactions sont identiques entre les blocs.`}</p>
    <h2>{`Réception de blocs`}</h2>
    <p>{`Le nœud de stockage est principalement concerné par la réception de blocs, ce qu'il fait par le biais de la méthode `}<inlineCode parentName="p">{`receive_block`}</inlineCode>{`. Cette méthode prend le bloc et effectue les opérations suivantes :`}</p>
    <ul>
      <li parentName="ul">{`compare l'ID de son bloc à tous les autres blocs qu'il a reçus.`}</li>
      <li parentName="ul">{`fait correspondre les transactions entre tous les blocs ayant le même ID, en signalant les blocs non conformes comme étant invalides`}</li>
      <li parentName="ul">{`réalise un consensus RAFT avec les nœuds de stockage pairs et, une fois atteint, enregistre tous les blocs valides dans la base de données.`}</li>
    </ul>
    <h2>{`Réception des valeurs d'UNiCORN`}</h2>
    <p>{`Afin de valider que tous les blocs dans l'historique sont corrects et n'ont pas été altérés, la blockchain de Zenotta utilise une valeur spéciale appelée `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` en combinaison avec un hachage de bloc.`}</p>
    <p>{`Afin de prouver que les UNiCORN passés étaient effectivement aléatoires de manière incontestable et non pré-calculés, les tables de valeurs qui composent l'UNiCORN doivent être préservées pour l'audit. Cette tâche incombe également aux nœuds de stockage, sous la méthode `}<inlineCode parentName="p">{`receive_unicorn_table`}</inlineCode>{`. Là encore, le processus de réception est simple :`}</p>
    <ul>
      <li parentName="ul">{`Utilisez la fonction d'évaluation intégrée d'UNiCORN pour prouver que les entrées sont valides pour la valeur UNiCORN fournie.`}</li>
      <li parentName="ul">{`Effectuez un consensus RAFT avec les nœuds de stockage pairs dans le cadre d'une validation régulière du bloc et, une fois atteint, enregistrez le bloc dans la base de données.`}</li>
    </ul>
    <h2>{`Réception des DRUIDs`}</h2>
    <p>{`Il est important d'enregistrer l'historique des `}<a parentName="p" {...{
        "href": "https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df"
      }}>{`DRUID`}</a>{` dans le système. Ces valeurs permettent à quiconque de vérifier le moment où un actif a été échangé contre un autre, qu'il s'agisse de données ou de jetons. `}</p>
    <p>{`Là encore, le stockage des DRUIDs incombe aux nœuds de stockage. Le processus est géré par la méthode `}<inlineCode parentName="p">{`receive_druids`}</inlineCode>{` et consiste simplement à sauvegarder les DRUIDs directement sur le disque. Il n'y a actuellement aucune exigence de validation prévue pour ces sauvegardes.`}</p>
    <p>{`Le processus de stockage séparé des historiques des DRUIDs n'est actuellement pas implémenté mais est prévu pour le futur. Pour l'instant, les utilisateurs peuvent construire ces historiques manuellement en recherchant les transactions liées aux DRUID dans le même bloc.`}</p>
    <h2>{`Consensus des nœuds de stockage`}</h2>
    <p>{`Pour qu'un nouveau bloc soit ajouté avec succès à la blockchain, il doit être jugé valide et ensuite répliqué sur tous les nœuds de stockage actuels. Le processus est le suivant :`}</p>
    <ul>
      <li parentName="ul">{`RAFT est d'abord mis en place et l'élection du leader est organisée.`}</li>
      <li parentName="ul">{`Un nouveau bloc gagnant est envoyé à chaque nœud de stockage par son nœud Mempool connecté (chaque nœud Mempool à un nœud de stockage différent).`}</li>
      <li parentName="ul">{`Tous ces blocs sont poussés vers RAFT et sortiront consensuels dans chaque nœud de stockage.`}</li>
      <li parentName="ul">{`Sur `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{`, après chaque tour de bloc, chaque noeud pousse vers le radeau un `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Lorsqu'une majorité de `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{` et une majorité de `}<inlineCode parentName="li">{`block(blocknum)`}</inlineCode>{` sont consensuelles, un nouveau `}<inlineCode parentName="li">{`valid_block`}</inlineCode>{` est créé :`}
        <ul parentName="li">
          <li parentName="ul">{`En-tête de bloc et transactions communes`}</li>
          <li parentName="ul">{`Nonce gagnant consensuel et transaction Coinbase associée.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Le `}<inlineCode parentName="li">{`valid_block`}</inlineCode>{` est stocké et le `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{` est fixé à `}<inlineCode parentName="li">{`now + 1 seconde`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`L'événement de stockage est envoyé au nœud Mempool associé`}</li>
      <li parentName="ul">{`Le noeud Mempool reçoit `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{`, le pousse vers son RAFT.`}</li>
      <li parentName="ul">{`Lorsque la majorité des noeuds Mempool sont d'accord avec `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{`, le prochain tour d'extraction de blocs commence.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;