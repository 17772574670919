import styles from './UsefulLinks.module.scss';
import { Card } from 'react-bootstrap';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const UsefulLinks = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.content}>
      <h1> {t("Useful links")}</h1>
      <Card className={styles.linkCard} onClick={() => window.open('https://andersbrownworth.com/blockchain/', '_blank')}>
        <Card.Body>
          <Card.Title className={`${styles.title}`}>{t("Blockchain Demo")}</Card.Title>
          <Card.Subtitle className={`text-muted ${styles.link}`}> - https://andersbrownworth.com/blockchain/</Card.Subtitle>
          <Card.Text>
            {t("When I first tried to get an intuition for blockchain technology it was frustrating not to have something interactive and visual to understand the terms, concepts and how they interact to produce a blockchain. This link was the resource I wish I had back then. Anders Brownworth has done an amazing job at cutting out the boiler plate, providing tabs of increasing complexity from foundational concepts to integration, plus a handy video with commentary. Start here when learning blockchain technology!")}
          </Card.Text>
          <Card.Text className={`text-muted ${styles.signature}`}>- Andrew Kessler</Card.Text>
        </Card.Body>
      </Card>

      <Card className={styles.linkCard} onClick={() => window.open('http://unenumerated.blogspot.com/', '_blank')}>
        <Card.Body>
          <Card.Title className={`${styles.title}`}>Nick Szabo's Unenumerated blog</Card.Title>
          <Card.Subtitle className={`text-muted ${styles.link}`}> - http://unenumerated.blogspot.com/</Card.Subtitle>
          <Card.Text>
            {t("It seems impossible to truly understand the impact of cryptocurrencies until you understand the history of money and why we use it. Nick Szabo's blog is a treasure trove on numerous topics, but his posts on the development of human economy have been profoundly influential on my understanding of exactly how much we need cryptocurrencies in our world")}
          </Card.Text>
          <Card.Text className={`text-muted ${styles.signature}`}>- Byron Houwens</Card.Text>
        </Card.Body>
      </Card>

      <Card className={styles.linkCard} onClick={() => window.open('https://ocw.mit.edu/courses/sloan-school-of-management/15-s12-blockchain-and-money-fall-2018/index.htm', '_blank')}>
        <Card.Body>
          <Card.Title className={`${styles.title}`}>MIT OpenCourseWare — “Blockchain and Money” (Prof. Gary Gensler)</Card.Title>
          <Card.Subtitle className={`text-muted ${styles.link}`}> - https://ocw.mit.edu/courses/sloan-school-of-management/15-s12-blockchain-and-money-fall-2018/index.htm</Card.Subtitle>
          <Card.Text>
            {t("While most people’s point of entry into the crypto space is via clever Medium articles and flashy YouTube videos, these failed to provide me with a sufficiently structured and comprehensive explanation. Perhaps because I found myself in crypto after a long academic career, a traditional lecture style proved more conducive to my learning style. Sometimes the old ways are the best. Most people can’t afford an Ivy League education, but fortunately MIT offers a highly informative course on blockchain and its related economic concepts for free under its OpenCourseWare platform. This course it taught by none other than Prof. Gary Gensler, current Chairman of the SEC.")}
          </Card.Text>
          <Card.Text className={`text-muted ${styles.signature}`}>- Miles Timpe</Card.Text>
        </Card.Body>
      </Card>

      <Card className={styles.linkCard} onClick={() => window.open('https://medium.com/@aantonop', '_blank')}>
        <Card.Body>
          <Card.Title className={`${styles.title}`}>Andreas Antonopoulos' blog</Card.Title>
          <Card.Subtitle className={`text-muted ${styles.link}`}> - https://medium.com/@aantonop</Card.Subtitle>
          <Card.Text>
            {t("One of the pioneers of introducing Bitcoin and blockchain technology to the masses, Andreas Antonopoulos is a true master of unpacking the fundamentals of this new paradigm from all angles. It was blog posts and videos of presentations/lectures that gave me an insight into not just how Bitcoin and blockchain technology function, but why they should function, and how they can change the world.")}
          </Card.Text>
          <Card.Text className={`text-muted ${styles.signature}`}>- Alexander Hobbs</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default hot(UsefulLinks);
