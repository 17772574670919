import styles from './Button.module.scss'; // Personnalised styles for this component
import { Button as BsBtn } from 'react-bootstrap';

interface Props {
  content: any;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
}

/**
 * Component template
 * @returns Base component
 */
const Button = (props: Props) => {

  return (
    <BsBtn
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      className={`${props.disabled ? styles.Disabled : ''} ${props.className ? props.className : ''} ${styles.Btn}`}
      disabled={props.disabled ? true : false}
    >
      {props.content}
    </BsBtn>
  );
};

export default Button;
