import { useEffect } from 'react';
import styles from './Toc.module.scss'; // Personnalised styles for this component
import Headings from './Headings';
import { useSelector } from 'react-redux';


/**
 * Table of content component
 * @returns Base component
 */
const Toc = () => { // Create interface for props
  const [tocItems, activeId] = useSelector((state: any) => {return [state.tocItems, state.activeId]});
  
  useEffect(() => {
    if(tocItems.length < 2) {
      document.getElementById('tocContainer')!.classList.add(styles.hidden);
      document.getElementById('tocContainer')!.classList.remove(styles.visible);
      document.getElementById('contentContainer')!.style.paddingRight = '0px';
    } else {
      document.getElementById('tocContainer')!.classList.add(styles.visible);
      document.getElementById('tocContainer')!.classList.remove(styles.hidden);
      document.getElementById('contentContainer')!.style.paddingRight = '18vw';
    }
  }, [tocItems]);

  return (
    <div className={styles.tocContainer} id="tocContainer">
      <Headings tocItems={tocItems} activeId={activeId} />
    </div>
  );
};

export default Toc;
