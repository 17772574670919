
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MempoolMiner from '../img/e234a5fa86923a5e87e4b9ee2742f01d/compute-miner.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Nœud minier`}</h1>
    <p>{`Le nœud Miner représente le mineur moyen du système Zenotta. Les mineurs dans leur ensemble sont chargés de veiller à ce que les blocs de transactions soient minés et ajoutés à l'historique de la blockchain. Pour leurs efforts, les mineurs gagnants reçoivent une récompense sous forme de jeton, de la même manière que dans Bitcoin. Cependant, contrairement au bitcoin, Zenotta utilise une méthode de minage à consommation d'énergie réduite tout en conservant les aspects utiles de la `}{`[Proof-of-Work (PoW)]`}{` (`}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Proof_of_work"
      }}>{`https://en.bitcoin.it/wiki/Proof_of_work`}</a>{`). Cette méthode sera abordée plus en détail dans la section `}<a parentName="p" {...{
        "href": "#partitionnement"
      }}>{`Partitionnement`}</a>{`.`}</p>
    <h2>{`L'équilibre des pouvoirs`}</h2>
    <p>{`Il existe un équilibre de style romain entre les nœuds Mempool, de type Sénat, et les nœuds Miner, de type Public, qui permet au système Zenotta de ne pas perdre confiance. Alors que les nœuds Mempool regroupent les transactions en blocs après les avoir validées, il appartient toujours aux nœuds Miner de s'assurer que ces blocs sont minés et ajoutés aux nœuds de stockage. Si les mineurs décident à la majorité de rejeter un bloc qu'un nœud Mempool a construit, le bloc ne peut pas être ajouté au grand livre. Pour aller plus loin, les mineurs pourraient choisir de rejeter purement et simplement un nœud Mempool de mauvaise foi, le rendant inutile, et dans un cas extrême, ils pourraient abandonner complètement le système.`}</p>
    <p>{`La manière dont l'équité est assurée en ce qui concerne l'exploitation des blocs et la participation des mineurs sera examinée dans la section suivante.`}</p>
    <h2>{`Partitionnement`}</h2>
    <p>{`Le système de partitionnement est à la base de l'équilibre Mempool-Miner. Au lieu que tous les mineurs minent le bloc à chaque tour (ce qui gaspille d'énormes quantités d'énergie), seuls quelques mineurs sélectionnés sont choisis à chaque tour pour participer. Les mineurs qui n'ont pas été choisis peuvent donc économiser leur énergie et tenter de participer à nouveau au tour suivant. En termes de minage, la structure du réseau à chaque tour est illustrée dans ce diagramme, où seuls les nœuds mineurs (désignés par un "M") à l'intérieur de l'espace orange sont en mesure de miner pour ce nœud Mempool donné :`}</p>
    <img src={MempoolMiner} alt="compute-miner" />
    <p>{`À chaque tour, les mineurs peuvent soumettre une demande à un nœud Mempool auquel ils se sont connectés afin de participer au minage du bloc à venir. Chaque nœud Mempool ne peut accepter qu'un nombre limité de mineurs dans sa partition, et une fois cette limite atteinte, le bloc peut être envoyé pour être miné.`}</p>
    <p>{`Les nœuds Mempool font ce qui suit lorsqu'ils reçoivent une demande de partition d'un mineur :`}</p>
    <ul>
      <li parentName="ul">{`Le mineur A envoie au Mempool A une demande pour rejoindre une partition. Cette demande contient une petite preuve de travail pour s'assurer que le nœud Mempool n'est pas exploité par un ou plusieurs mineurs malveillants.`}</li>
      <li parentName="ul">{`Mempool A utilise ensuite le résultat de l'algorithme `}<a parentName="li" {...{
          "href": "licornes"
        }}>{`UNiCORN`}</a>{` pour sélectionner les mineurs de la partition qui se joindront au processus de minage.`}</li>
      <li parentName="ul">{`Mempool A fournit le bloc à tous les mineurs sélectionnés dans la partition, y compris le mineur A, et les mineurs peuvent maintenant commencer à extraire.`}</li>
      <li parentName="ul">{`Les mineurs ont un temps déterminé pour miner le bloc, dans lequel ils peuvent soumettre leur preuve de travail gagnante pour le bloc lui-même.`}</li>
      <li parentName="ul">{`À la fin du temps de ronde, les nœuds de Mempool utilisent à nouveau l'UNiCORN pour sélectionner le mineur gagnant parmi l'ensemble des soumissions de PoW valides, et le bloc est envoyé avec leur coinbase aux nœuds de stockage.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;