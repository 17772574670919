/* eslint-disable */
import { useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { setActiveId } from 'store/actions';


export const useIntersectionObserver = () => {
  const dispatch = useDispatch();
  const headingElementsRef = useRef({});

  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) => headingElements.findIndex((heading) => parseInt(heading.id) === id);

      if (visibleHeadings.length === 1) {
        dispatch(setActiveId(visibleHeadings[0].target.id));
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        dispatch(setActiveId(sortedVisibleHeadings[0].target.id));
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -40% 0px',
    });

    const headingElements = Array.from(document.querySelectorAll('h1, h2, h3'));
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);
};

export const getHeadingsData = () => {
  const headingElements = Array.from(document.querySelectorAll('h1, h2, h3'));
  const nestedHeadings = getNestedHeadings(headingElements);
  return nestedHeadings;
};

const getAnchor = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/[ ]/g, '-');
}

const checkForId = (heading) => {
  if (!heading.id) heading.id = getAnchor(heading.innerText);
  return heading.id;
}

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading) => {
    const { innerText: title } = heading;

    if (heading.nodeName === 'H1' || heading.nodeName === 'H2') {
      let id = checkForId(heading);
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
      let id = checkForId(heading);
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });
  return nestedHeadings;
};