
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`UNiCORNs`}</h1>
    <p>{`UN-COntestable Random Numbers (UNiCORNs) are numbers generated by a group with the property that no member of the group can influence the UNiCORN to produce anything other than a random number. This makes UNiCORNs ideal numbers to seed cryptographic protocols and prove fair (random) selection of elements from an enumerated list.`}</p>
    <p>{`In the Zenotta blockchain system, UNiCORNs are used for 3 fundamental processes:`}</p>
    <ul>
      <li parentName="ul">{`Selecting transactions to be packaged into a block`}</li>
      <li parentName="ul">{`Selecting miners for a block round partition`}</li>
      <li parentName="ul">{`Selecting the winning miner from the participating miners who submit successful Proof-of-Works within a blocktime`}</li>
    </ul>
    <p>{`The original `}<a parentName="p" {...{
        "href": "https://eprint.iacr.org/2015/366.pdf"
      }}>{`UNiCORN`}</a>{` paper details its construction and applicability.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;