import styles from './LayerMask.module.scss'; // Personnalised styles for this component
import { closeSideNav } from 'components/layout/SideNav/SideNav';

const widthThreshold = parseInt(styles.widthThreshold); // Sass const from _variables.scss

/**
 * Component template
 * @returns Base component
 */
const Base = () => {
  return (
    <div // Create a component for layerMask
    className={styles.layerMask}
    id="layerMask"
    onClick={() => {
      if (window.innerWidth < widthThreshold) closeSideNav();
    }}
  />
  );
}

export default Base