import { useState } from 'react';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import styles from './CodeBlock.module.scss';
import menuArrow from 'static/img/triangle.svg';
import TypeScriptLogo from 'static/img/ts_logo.svg';
import RustLogo from 'static/img/rust_logo.svg';
import PythonLogo from 'static/img/python_logo.svg';

interface CodeBlockProps {
    defaultLang: string;
    content: string[];
    selectable: boolean;
}

export const CodeBlock = (props: CodeBlockProps) => {
    const [currentLang, setCurrentLang] = useState(props.defaultLang);
    const [activeListClass, setActiveListClass] = useState('');
    const langs: any = {
        'TypeScript': TypeScriptLogo,
        'Rust': RustLogo,
        'Python': PythonLogo,
    };

    const onLangSelectClick = () => {
        if (props.selectable) { 
            setActiveListClass(activeListClass === styles.active ? '' : styles.active) 
        }
    }

    return (
        <div className={styles.codeBlock}>
                {props.defaultLang !== "text" && (
                <div className={styles.lang} onClick={() => onLangSelectClick()}>
                    <img src={langs[currentLang]} alt="TypeScript" className={styles.langSelect} />
                    {props.selectable === true && <img src={menuArrow} alt="Language selection" className={`${styles.langSelectArrow} ${activeListClass}`} />}
                </div>
                )}
                
                <ul className={`${styles.langList} ${activeListClass}`}>
                    {Object.keys(langs).filter(e => e !== currentLang).map(e => (
                        <li key={e} onClick={() => { 
                            setCurrentLang(e);
                            setActiveListClass('');
                        }}>
                            <img src={langs[e]} alt={e} className={styles.langSelect} />
                        </li>
                    ))}
                </ul>

                <CopyBlock 
                    language={currentLang.toLowerCase()}
                    text={props.content.join('\n')}
                    showLineNumbers={false}
                    theme={monoBlue}
                    wrapLines={true}
                    />
            </div>
    );
}