
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import ShurikenAudit from '../img/d06cd07ea832dcd43423bf1b9c6b2311/shuriken-audit.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Shuriken-Prüfung`}</h1>
    <p>{`Der Prozess der Anlageerstellung im ZNP-System funktioniert ähnlich wie eine Coinbase-Transaktion: Nutzer erstellen eine Transaktion zur Erzeugung eines Vermögenswerts, die einen Wert aus dem Nichts hervorbringt, und übermitteln ihn an den `}<a parentName="p" {...{
        "href": "Mempool-Knoten"
      }}>{`Mempool`}</a>{` Ring zur Aufnahme in die Blockchain.`}</p>
    <p>{`Das Problem bei diesem Prozess ist, dass er "kostenlos" ist, da er weder materielle Ressourcen wie Token noch Rechenleistung zur Durchführung benötigt. Dies wiederum führt zu potenziellen Problemen für das Netzwerk im Allgemeinen und insbesondere für DDoS-Angriffe. Um dieses Problem zu lösen, führt das ZNP eine Anforderung für Nutzer ein, die neue Quittungen oder Transaktionen für Datenbestände erstellen möchten.`}</p>
    <h2>{`Auditing, eine Voraussetzung für die Erstellung neuer Assets`}</h2>
    <p>{`Die Voraussetzung für die Erstellung neuer Assets ist, dass der Ersteller die letzten `}<em parentName="p">{`n`}</em>{` Blöcke in der Blockchain auf Korrektheit prüft, und insbesondere wollen wir prüfen, ob ein bestimmter Eintrag in einem `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` tatsächlich Teil des Seeds für dieses Unicorn ist. Auf diese Weise können wir die Gültigkeit der Kette als Ganzes überprüfen und eine Möglichkeit bieten, DDoS bei der Erstellung von Vermögenswerten in einem einzigen Schritt zu entschärfen.`}</p>
    <h2>{`Prozessablauf`}</h2>
    <p>{`Als Beispiel für den Prozessablauf stellen wir uns vor, dass Alice 5 Quittungen erstellen möchte. Sie tut dies durch den folgenden Prozess:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Der `}{`[Lagerung]`}{`-(Storage-Node)-Ring baut einmal pro Blockrunde eine Präsenztabelle auf, die als Array oder Vektor boolescher Werte strukturiert sein kann.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Für jeden Eintrag in der Präsenztabelle muss der Speicher-RAFT einen UNiCORN-Seed-Hash und einen vollständigen UNiCORN aus der Blockchain auswählen. Einige dieser Einträge müssen zufällig aus verschiedenen Teilen der Blockchain ausgewählt werden (z. B. UNiCORN-Eintrag 1 aus Block 5), der Rest muss ein Eintrag aus demselben Block sein (z. B. UNiCORN-Eintrag 3 aus Block 3). Ersteres wird `}<inlineCode parentName="p">{`false`}</inlineCode>{` (nicht in der UNiCORN vorhanden) und letzteres `}<inlineCode parentName="p">{`true`}</inlineCode>{` (vorhanden) sein und für jeden Fall wird dieser boolesche Wert in die Präsenztabelle eingefügt.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Die Anwesenheitstabelle, die von der Storage RAFT gemeinsam genutzt wird, ist nun fertig. Wenn Alice ihre neuen Quittungen erstellen möchte, sendet sie die Erstellungstransaktion an einen zufälligen Speicherknoten und erhält im Gegenzug einen Eintrag in der Anwesenheitstabelle, zusammen mit den zugehörigen Daten, die erforderlich sind, um zu überprüfen, ob er vorhanden ist oder nicht (dies beinhaltet eine Schwierigkeit für einen leichten PoW).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice muss überprüfen, ob der Eintrag "wahr" oder "falsch" ist, und führt dann ihren PoW mit diesem booleschen Wert und dem Hash ihrer Erstellungstransaktion durch. Anschließend sendet sie das Endergebnis an den Speicherknoten zurück.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wenn die Überprüfung von Alice mit dem Speicherknoten übereinstimmt, wird ein Bestätigungswert für diesen Präsenztabelleneintrag vom Speicher-RAFT inkrementiert, und die Transaktion von Alice wird an den Mempool-Knoten gesendet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wenn die Verifizierung von Alice `}<strong parentName="p">{`nicht`}</strong>{` übereinstimmt, sendet der Storage-Knoten (A) den Eintrag an Bob weiter, der ebenfalls eine Quittung erstellen möchte, und die Unstimmigkeit wird in der RAFT vermerkt.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob sendet seine Verifizierung an den nächsten verfügbaren Speicherknoten (B) weiter. Wenn er ebenfalls nicht einverstanden ist, wird der Prozess in Schritt 6 wiederholt, aber die Schwierigkeit, die der Speicherknoten bietet, erhöht sich um den Faktor 10.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Werden drei Unstimmigkeiten festgestellt, werden die Erstellungsvorgänge für alle drei Benutzer sofort an den Mempool-Ring gesendet, und ein Speicherknoten wird die Gültigkeit des Eintrags manuell überprüfen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wenn sich herausstellt, dass tatsächlich eine Unstimmigkeit vorliegt, muss im Mempool-Ring eine Art Anhalteprozess eingeleitet werden.`}</p>
      </li>
    </ol>
    <h2>{`Diagramm`}</h2>
    <p>{`Das nachstehende Diagramm zeigt einen typischen Shuriken-Prüfungsprozess:`}</p>
    <img src={ShurikenAudit} alt="shuriken-audit" />
    <p>{`Die Wertetabelle im oberen Teil des Diagramms ist die vereinbarte Präsenztabelle für die Blockrunde, wobei die Zahl den Index und die Werte "T" und "F" die Werte "wahr" bzw. "falsch" angeben. Der hervorgehobene Pfad zeigt ein Szenario, in dem die Speicherknoten einem Benutzerknoten einen vermeintlich wahren Eintrag senden, den der Benutzer dann aber als falsch erkennt.`}</p>
    <p>{`Die Speicherknoten müssen dann den Vorgang des Sendens an aufeinanderfolgende Benutzerknoten wiederholen, bis sie den dritten Eintrag erhalten. In diesem Fall wurden alle drei als falsch eingestuft, so dass der vierte Speicherknoten eine manuelle Prüfung dieses speziellen Eintrags vornehmen muss, um das Prädikat zu bestimmen.`}</p>
    <p>{`Die eingeblendeten Szenarien zeigen Fälle, in denen die Benutzerknoten mit dem Glauben der Speicherknoten übereinstimmen. Die resultierende Form der Struktur ist shuriken- oder sternförmig.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;