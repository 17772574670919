import styles from './Toc.module.scss';
import { props } from './Toc.interfaces';
import { useTranslation } from 'react-i18next';

export const Headings = ({ tocItems, activeId }: props) => {
  const { t } = useTranslation();
  
  return (
  <ul className={styles.headings}>
    {tocItems.map((heading: any, index: number) => {
      if (index === 0) {
        return (
          <li key={heading.id} className={styles.first}>
            <a href={`#${heading.id}`} className={`${heading.id === activeId ? styles.active : ''}`}>
              {t(heading.title)}
            </a>
          </li>
        );
      } else {
        return (
          <li key={heading.id}>
            <a href={`#${heading.id}`} className={heading.id === activeId ? styles.active : ''}>
              {t(heading.title)}
            </a>
            {heading.items.length > 0 && (
              <ul>
                {heading.items.map((child: any) => (
                  <li key={child.id}>
                    <a href={`#${child.id}`} className={child.id === activeId ? styles.active : ''}>
                      {t(child.title)}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      }
    })}
  </ul>
)}
export default Headings;
