import { NavRoutes } from 'utils/interfaces';
import { ContentType } from 'utils/enums';

/* eslint import/no-webpack-loader-syntax: off */
import Home from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Home.mdx';
import NodeTypes from '!babel-loader!@mdx-js/loader!./content/znp_wiki/ZNP-Node-Types.mdx';
import MempoolNode from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Mempool-Node.mdx';
import MinerNode from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Miner-Node.mdx';
import StorageNode from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Storage-Node.mdx';
import Unicorns from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Unicorns.mdx';
import Transactions from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Transactions.mdx';
import BlockMining from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Block-Mining-Process.mdx';
import DualDoubleEntry from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Dual-Double-Entry-Transactions.mdx';
import ShurikenAuditing from '!babel-loader!@mdx-js/loader!./content/znp_wiki/Shuriken-Auditing.mdx';

import HomeDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Home.mdx';
import NodeTypesDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/ZNP-Node-Types.mdx';
import MempoolNodeDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Mempool-Node.mdx';
import MinerNodeDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Miner-Node.mdx';
import StorageNodeDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Storage-Node.mdx';
import UnicornsDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Unicorns.mdx';
import TransactionsDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Transactions.mdx';
import BlockMiningDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Block-Mining-Process.mdx';
import DualDoubleEntryDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Dual-Double-Entry-Transactions.mdx';
import ShurikenAuditingDe from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_de/Shuriken-Auditing.mdx';

import HomeIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Home.mdx';
import NodeTypesIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/ZNP-Node-Types.mdx';
import MempoolNodeIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Mempool-Node.mdx';
import MinerNodeIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Miner-Node.mdx';
import StorageNodeIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Storage-Node.mdx';
import UnicornsIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Unicorns.mdx';
import TransactionsIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Transactions.mdx';
import BlockMiningIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Block-Mining-Process.mdx';
import DualDoubleEntryIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Dual-Double-Entry-Transactions.mdx';
import ShurikenAuditingIt from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_it/Shuriken-Auditing.mdx';

import HomeFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Home.mdx';
import NodeTypesFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/ZNP-Node-Types.mdx';
import MempoolNodeFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Mempool-Node.mdx';
import MinerNodeFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Miner-Node.mdx';
import StorageNodeFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Storage-Node.mdx';
import UnicornsFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Unicorns.mdx';
import TransactionsFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Transactions.mdx';
import BlockMiningFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Block-Mining-Process.mdx';
import DualDoubleEntryFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Dual-Double-Entry-Transactions.mdx';
import ShurikenAuditingFr from '!babel-loader!@mdx-js/loader!./content/znp_wiki/wiki_fr/Shuriken-Auditing.mdx';

//  {'en':, 'fr':, 'de':, 'it':}
export const znpRoutes: NavRoutes[] = [
  { path: '/home', name: 'The Zenotta Network Protocol', Component: {'en':Home, 'fr':HomeFr, 'de':HomeDe, 'it':HomeIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/node-types', name: 'Node Types', Component: {'en':NodeTypes, 'fr':NodeTypesFr, 'de':NodeTypesDe, 'it':NodeTypesIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/mempool-node', name: 'Mempool Node', Component: {'en':MempoolNode, 'fr':MempoolNodeFr, 'de':MempoolNodeDe, 'it':MempoolNodeIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/miner-node', name: 'Miner Node', Component: {'en':MinerNode, 'fr':MinerNodeFr, 'de':MinerNodeDe, 'it':MinerNodeIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/storage-node', name: 'Storage Node', Component: {'en':StorageNode, 'fr':StorageNodeFr, 'de':StorageNodeDe, 'it':StorageNodeIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/unicorns', name: 'Unicorns', Component: {'en':Unicorns, 'fr':UnicornsFr, 'de':UnicornsDe, 'it':UnicornsIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/transactions', name: 'Transactions', Component: {'en':Transactions, 'fr':TransactionsFr, 'de':TransactionsDe, 'it':TransactionsIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/block-mining', name: 'Block Mining Process', Component: {'en':BlockMining, 'fr':BlockMiningFr, 'de':BlockMiningDe, 'it':BlockMiningIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/dual-double-entry', name: 'Dual Double Entry Transactions', Component: {'en':DualDoubleEntry, 'fr':DualDoubleEntryFr, 'de':DualDoubleEntryDe, 'it':DualDoubleEntryIt}, contentType: ContentType.Mdx, toc: true },
  { path: '/shuriken-auditing', name: 'Shuriken Auditing', Component: {'en':ShurikenAuditing, 'fr':ShurikenAuditingFr, 'de':ShurikenAuditingDe, 'it':ShurikenAuditingIt}, contentType: ContentType.Mdx, toc: true },
];
