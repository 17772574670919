import styles from './BurgerMenu.module.scss';
import { openSideNav, closeSideNav } from 'components/layout/SideNav/SideNav';

export function showNavIco() {
    const navIco = document.getElementById('burger-menu');
    navIco!.classList.remove(styles.hidden);
}

export function hideNavIco() {
    const navIco = document.getElementById('burger-menu');
    navIco!.classList.add(styles.hidden);
}

export function openedNavIco() {
    document.getElementById('burger-menu')!.classList.add("active");
}

export function closedNavIco() {
    document.getElementById('burger-menu')!.classList.remove("active");
}

const toggleSideNav = () => {
    const sideNav = document.getElementById('sideNav');
    if (sideNav) {
      if (sideNav.offsetLeft < 0) {
        openSideNav();
      } else if (sideNav.offsetLeft === 0) {
        closeSideNav();
      }
    }
  };

export const BurgerMenu = () => {
    return (
        <div id="burger-menu" className={`${styles.container} ${styles.hidden}`} onClick={toggleSideNav}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};
