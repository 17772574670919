
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`UNiCORNs`}</h1>
    <p>{`I numeri casuali non testabili (UNiCORN) sono numeri generati da un gruppo con la proprietà che nessun membro del gruppo può influenzare l'UNiCORN per produrre qualcosa di diverso da un numero casuale. Ciò rende gli UNiCORN i numeri ideali per l'avvio di protocolli crittografici e per dimostrare una selezione equa (casuale) di elementi da un elenco enumerato.`}</p>
    <p>{`Nel sistema blockchain di Zenotta, gli UNiCORN sono utilizzati per 3 processi fondamentali:`}</p>
    <ul>
      <li parentName="ul">{`selezione delle transazioni da inserire in un blocco`}</li>
      <li parentName="ul">{`Selezione dei minatori per la partizione di un blocco`}</li>
      <li parentName="ul">{`Selezione del minatore vincente tra i minatori partecipanti che inviano Proof-of-Work con successo entro un tempo di blocco.`}</li>
    </ul>
    <p>{`L'articolo originale di `}<a parentName="p" {...{
        "href": "https://eprint.iacr.org/2015/366.pdf"
      }}>{`UNiCORN`}</a>{` ne descrive in dettaglio la costruzione e l'applicabilità.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;