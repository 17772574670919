
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Le protocole de réseau Zenotta`}</h1>
    <p>{`Le protocole de réseau Zenotta (ZNP) est responsable de tout le code lié au réseau dans le système Zenotta. Il s'agit du répertoire responsable de la communication entre les nœuds du réseau minier, ainsi que de l'intégration avec la structure de données de la blockchain qui se trouve dans le répertoire `}<inlineCode parentName="p">{`NAOM`}</inlineCode>{`. Vous trouverez ci-dessous la table des matières de la documentation complémentaire, qui vous renseignera sur l'architecture du projet dans son ensemble :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "node-types"
        }}>{`Types de nœuds`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mempool-node"
        }}>{`Nœud Mempool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "miner-node"
        }}>{`Nœud minier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "storage-node"
        }}>{`Nœud de stockage`}</a>
        {/* - [The Wallet](wallet) */}
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "unicorns"
        }}>{`UNiCORNs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "transactions"
        }}>{`Transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "block-mining"
        }}>{`Processus d'extraction des blocs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "dual-double-entry"
        }}>{`Opérations en double entrée`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "shuriken-auditing"
        }}>{`Audit Shuriken`}</a></li>
    </ul>
    <h1>{`Pour commencer`}</h1>
    <p>{`Pour commencer, il est important de comprendre comment le ZNP s'intègre au système Zenotta dans son ensemble. Le système Zenotta se compose principalement de 3 éléments majeurs :`}</p>
    <ul>
      <li parentName="ul">{`Le protocole de réseau Zenotta (ZNP)`}</li>
      <li parentName="ul">{`La Blockchain double entrée (DDE)`}</li>
      <li parentName="ul">{`Le protocole de données Zenotta (ZDP)`}</li>
    </ul>
    <p>{`Pour l'instant, nous ne considérerons que les deux premiers éléments de cette liste. Le `}<strong parentName="p">{`ZNP`}</strong>{` envoie et reçoit des messages qui sont transmis entre différents types de nœuds, et relaie également le stockage vers la `}<strong parentName="p">{`blockchain DDE`}</strong>{` afin de conserver un enregistrement de l'historique de la blockchain.`}</p>
    <h2>{`Architecture du système`}</h2>
    <p>{`L'architecture du système Zenotta reflète en grande partie ce que vous trouverez dans l'architecture Bitcoin, puisque notre blockchain est basée sur le consensus Proof-of-Work (PoW). Cependant, à la différence du Bitcoin traditionnel, notre blockchain est capable d'effectuer des transactions plus rapides (ce qui entraîne des périodes d'apurement plus rapides) et notre système PoW est léger et plus inclusif (mais nous y reviendrons plus tard).`}</p>
    <p>{`L'autre différence majeure entre Zenotta et les blockchains traditionnelles est l'introduction du système de double entrée (DDE). Contrairement aux blockchains traditionnelles, notre blockchain peut enregistrer à la fois le mouvement des jetons et le mouvement des actifs pour ces jetons. Grâce à notre `}<strong parentName="p">{`ZDP`}</strong>{` ou Zenotta Data Protocol, ces actifs peuvent être des actifs de données que vous pouvez posséder et transiger.`}</p>
    <p>{`Pour faciliter cela, l'architecture Zenotta utilise différents types de nœuds dans son réseau. Vous pouvez en savoir plus sur les différents types de nœuds `}<a parentName="p" {...{
        "href": "node-types"
      }}>{`ici.`}</a>{`.`}</p>
    <h2>{`Conception du code`}</h2>
    <p>{`Nous avons une philosophie de conception spécifique concernant le code écrit chez Zenotta. L'idéal est d'avoir un code qui soit facilement réutilisable, mais surtout facilement compréhensible. Nous traitons notre code comme une relation client-serveur, où le code est le serveur et vous, l'utilisateur humain, êtes le client. À cette fin, nous avons adopté l'approche de `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multitier_architecture#Three-tier_architecture"
      }}>{`l'architecture à trois`}</a>{` niveaux popularisée par `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_J._Donovan"
      }}>{`John J. Donovan`}</a>{`, qui se compose en bref des éléments suivants :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Niveau de présentation`}</strong>{` : À traiter comme l'interface utilisateur. À ce niveau, il devrait y avoir les abstractions les plus élevées pour tout module que nous écrivons, consistant en quelques fonctions faciles à utiliser qui peuvent être implémentées dans une API pour une consommation générale.
`}<strong parentName="p"><em parentName="strong">{`Niveau développeur : Débutant`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Niveau logique`}</strong>{` : Il s'agit d'un niveau inférieur au niveau de présentation, où le code est plus complexe mais capable de fonctions plus puissantes. La logique et les opérations commerciales peuvent être trouvées à ce niveau, aidant à faciliter les abstractions de plus haut niveau dans le niveau de présentation. `}<strong parentName="p">{`Niveau développeur : Intermédiaire`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Tier de données`}</strong>{` : Il s'agit du niveau le plus bas de l'architecture du code, et en tant que tel, c'est le plus complexe. Les fonctions et les opérations à ce niveau interagissent directement avec les opérations au niveau du système, telles que la gestion des entrées/sorties et des connexions réseau, ainsi que l'interaction directe avec la blockchain. `}<strong parentName="p"><em parentName="strong">{`Niveau développeur : Avancé`}</em></strong></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;