
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import ShurikenAudit from '../img/d06cd07ea832dcd43423bf1b9c6b2311/shuriken-audit.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Audit Shuriken`}</h1>
    <p>{`Le processus de création d'actifs dans le système ZNP fonctionne à peu près de la même manière qu'une transaction Coinbase : Les utilisateurs construisent une transaction de création d'actifs, créant une valeur à partir de rien, et la soumettent à l'anneau `}<a parentName="p" {...{
        "href": "Mempool-Node"
      }}>{`Mempool`}</a>{` pour l'inclure dans la blockchain.`}</p>
    <p>{`Le problème de ce processus est qu'il est "gratuit", ne coûtant ni ressource matérielle comme les jetons ni puissance de calcul pour être exécuté. Cela entraîne donc des problèmes potentiels pour le réseau en général, et en particulier des attaques DDoS. Afin de résoudre ce problème, le ZNP introduit une exigence pour les utilisateurs qui espèrent créer de nouveaux reçus ou transactions de données.`}</p>
    <h2>{`L'audit, une nécessité pour créer de nouveaux atouts`}</h2>
    <p>{`La condition pour créer de nouveaux actifs est que le créateur vérifie l'exactitude des `}<em parentName="p">{`n`}</em>{` derniers blocs de la blockchain, et en particulier nous voulons vérifier si une entrée donnée dans un `}<a parentName="p" {...{
        "href": "licornes"
      }}>{`UNiCORN`}</a>{` fait réellement partie de la graine de cette licorne. De cette façon, nous pouvons vérifier la validité de la chaîne dans son ensemble et fournir un moyen d'atténuer les DDoS dans la création d'actifs en une seule étape.`}</p>
    <h2>{`Déroulement du processus`}</h2>
    <p>{`Pour illustrer le déroulement du processus, nous allons imaginer qu'Alice souhaite créer 5 reçus. Pour ce faire, elle suit le processus suivant :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`L'anneau `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{` établit une table de présence une fois par tour de bloc, qui peut être structurée comme un tableau ou un vecteur de valeurs booléennes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pour chaque entrée de la table de présence, le RAFT de stockage doit choisir un hachage de graine UNiCORN et un UNiCORN complet dans la blockchain. Certaines de ces entrées devront être choisies au hasard dans différentes parties de la blockchain (par exemple, l'entrée UNiCORN 1 du bloc 5) et le reste devra être une entrée du même bloc (par exemple, l'entrée UNiCORN 3 du bloc 3). La première valeur sera `}<inlineCode parentName="p">{`false`}</inlineCode>{` (non présent dans l'UNiCORN) et la seconde sera `}<inlineCode parentName="p">{`true`}</inlineCode>{` (est présent) et pour chaque cas cette valeur booléenne sera insérée dans la table de présence.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`La table de présence, partagée entre le RAFT de stockage, est maintenant prête. Quand Alice veut créer ses nouveaux reçus, elle envoie la transaction de création à un nœud de stockage aléatoire et reçoit en retour une entrée dans la table de présence, ainsi que les données associées nécessaires pour vérifier si elle est présente ou non (cela inclura une difficulté pour un PoW léger).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice doit vérifier si l'entrée doit être `}<inlineCode parentName="p">{`vrai`}</inlineCode>{` ou `}<inlineCode parentName="p">{`faux`}</inlineCode>{`, puis effectue son PoW sur cette valeur booléenne et le hash de sa transaction de création. Elle renvoie ensuite le résultat final au nœud de stockage.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Si la vérification d'Alice est en accord avec les nœuds de stockage, une valeur de confirmation est incrémentée pour cette entrée de la table de présence par le RAFT de stockage et la transaction d'Alice est envoyée au nœud Mempool.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Si la vérification d'Alice n'est `}<strong parentName="p">{`pas`}</strong>{` en accord, le nœud de stockage (A) enverra l'entrée à Bob qui veut également créer un reçu et le désaccord sera marqué dans le RAFT.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob envoie sa vérification au prochain nœud de stockage disponible (B). S'il est également en désaccord, le processus de l'étape 6 se répète mais la difficulté que le nœud de stockage fournit augmente d'un facteur 10.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Si 3 désaccords sont découverts, la transaction de création pour les 3 utilisateurs est immédiatement envoyée à l'anneau Mempool et un nœud de stockage traitera manuellement la validité de l'entrée.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`S'il s'avère qu'il y a vraiment une incohérence, une sorte de processus d'arrêt devra être mis en place sur l'anneau Mempool.`}</p>
      </li>
    </ol>
    <h2>{`Diagramme`}</h2>
    <p>{`Le diagramme ci-dessous montre un processus typique de vérification des shurikens :`}</p>
    <img src={ShurikenAudit} alt="shuriken-audit" />
    <p>{`La table de valeurs en haut du diagramme est la table de présence convenue pour le tour de bloc, où le numéro indique l'index et les valeurs "T" et "F" indiquent respectivement `}<inlineCode parentName="p">{`vrai`}</inlineCode>{` et `}<inlineCode parentName="p">{`faux`}</inlineCode>{`. Le chemin en surbrillance montre un scénario dans lequel les nœuds de stockage envoient à un nœud utilisateur une entrée supposée vraie, que l'utilisateur détermine ensuite comme étant en réalité fausse.`}</p>
    <p>{`Les nœuds de stockage doivent ensuite répéter le processus d'envoi aux nœuds d'utilisateurs successifs jusqu'à la troisième réception. Dans ce cas, les trois entrées ont été évaluées comme fausses, et le quatrième nœud de stockage doit donc effectuer une vérification manuelle de cette entrée spécifique pour déterminer le prédicat.`}</p>
    <p>{`Les scénarios décolorés montrent les cas où les nœuds d'utilisateurs sont d'accord avec la croyance des nœuds de stockage. La forme résultante de la structure est en forme de shuriken ou d'étoile.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;