import styles from './Link.module.scss'; // Personnalised styles for this component
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

interface props {
  to: string;
  children: any;
  out?: boolean;
}

/**
 * Component template
 * @returns Base component
 */
const Link = ({ to, children, out }: props) => {
  return out ? (
    <a href={to} target={'_blank'} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Nav.Link as={NavLink} to={to} className={styles.link}>
      {children}
    </Nav.Link>
  );
};

export default Link;
