
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`ZNP-Knoten-Typen`}</h1>
    <p>{`Im Gegensatz zu Bitcoin, wo alle Full Node Miner identische Funktionen erfüllen, verwendet Zenotta im ZNP verschiedene Knotentypen. Unterschiedliche Knotentypen ermöglichen es der ZNP, schnelle Zahlungsabwicklungszeiten zu ermöglichen, geografische Vielfalt zu gewährleisten, geringere Speicheranforderungen für die Blockchain zu stellen und vor allem Token-for-Asset-Zahlungen zu ermöglichen.`}</p>
    <h2>{`Die Typen auf einen Blick`}</h2>
    <p>{`Das ZNP besteht aus 3 Hauptknotenarten:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Mempool-Node"
          }}><strong parentName="a">{`Mempool-Knoten`}</strong></a>{`: Der Mempool-Knoten ist eine besondere Art von Knoten, der für das Sammeln von Transaktionen und deren Bündelung zu neuen Blöcken zuständig ist. Sie partitionieren auch das Mining-Netzwerk für den Abbau und validieren die Blöcke, die diese Partitionen abbauen. `}<em parentName="p">{`Es gibt nur eine begrenzte Anzahl von Mempool-Knoten`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Miner-Node"
          }}><strong parentName="a">{`Bergbauknoten`}</strong></a>{`: Der Miner-Knoten ist dem Bitcoin-Miner am ähnlichsten, den Sie vielleicht kennen. Er ist dafür verantwortlich, neue Blöcke zu schürfen, die er von den Mempool-Knoten erhält, und kassiert für seine Bemühungen die entsprechende Blockbelohnung. Sie sind nur dann in der Lage, einen Block zu schürfen, wenn sie es schaffen, in die Partition für diese Runde zu gelangen. *Es kann eine unbegrenzte Anzahl von Miner-Knoten geben.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Storage-Node"
          }}><strong parentName="a">{`Speicher-Knoten`}</strong></a>{`: Der Speicherknoten ist für die Speicherung der gesamten Blockchain-Historie verantwortlich. Sobald die Blöcke von den Minern abgebaut und zur Validierung an die Mempool-Knoten gesendet wurden, werden sie zur langfristigen Speicherung an die Storage-Knoten weitergeleitet. Jeder kann einen vollständigen Verlauf der Blockchain von den Speicherknoten anfordern, aber sie sind nicht verpflichtet, einen solchen Verlauf zu führen, um am Netzwerk teilzunehmen. `}<em parentName="p">{`Es gibt nur eine begrenzte Anzahl von Speicherknoten`}</em>{`.`}</p>
      </li>
    </ul>
    <h2>{`Warum verschiedene Knotentypen?`}</h2>
    <p>{`Eine gute Frage, denn viele Blockchains scheinen mit einem einzigen Knotentyp zu funktionieren. Es gibt ein paar wichtige Aspekte, die das Zenotta-System berücksichtigen muss und die durch verschiedene Knotentypen gelöst werden können:`}</p>
    <h3>{`Schnelle Zahlungsabwicklung`}</h3>
    <p>{`Derzeit empfiehlt Bitcoin eine Mindestwartezeit von 6 Blöcken, bevor eine Zahlungstransaktion akzeptiert wird. Das liegt an der Architektur von Bitcoin, bei der die Existenz des Besitzers eines Tokens erst verifiziert werden kann, `}<em parentName="p">{`nachdem`}</em>{` der Besitzer diesen Token ausgegeben hat. Eine solche Struktur nennen wir `}<strong parentName="p">{`vergangenheitsorientiert`}</strong>{`, weil sie in die Vergangenheit schauen muss, um eine Transaktion zu validieren. Ein solcher Blick in die Vergangenheit (zusätzlich zu den verteilten verwaisten Blöcken) bedeutet, dass wir eine gewisse Zeit warten müssen, bevor wir mit Sicherheit sagen können, dass eine Transaktion enthalten ist. Diese Struktur ist großartig für die Sicherheit, aber schrecklich für die Abwicklungsgeschwindigkeit und führt zu einer Reihe von nicht idealen Patches, die es zu lösen gilt (wie Lightning).`}</p>
    <p>{`Bei Zenotta hingegen sind die schnelle Abrechnung und die hohe Sicherheit direkt in die Blockchain-Struktur eingebaut, was uns das Beste aus beiden Welten bietet. Der Schlüssel zu einer solchen Struktur ist der Einsatz von Mempool-Knoten, die im Vergleich zu den Miner-Knoten relativ wenige sind. Alle Transaktionen müssen über die Mempool-Knoten laufen, die die Transaktionsausgaben koordinieren und die Eigentumsverhältnisse sofort überprüfen können, ohne dass verwaiste Blöcke oder lange Wartezeiten erforderlich sind. Die Frage "Wer im Netzwerk hat die längste Kette?", die zu jedem Zeitpunkt in Bitcoin mehrere Antworten haben kann, ist für Zenotta immer die gleiche: die Mempool-Knoten.`}</p>
    <h3>{`Geografische Vielfalt`}</h3>
    <p>{`Bei vielen Kryptowährungen ist das Mining derzeit auf eine bestimmte geografische Region beschränkt. Die Konzentration der Mining-Pools für Bitcoin lag für einen beträchtlichen Zeitraum bei bis zu 81 % in China. Dies birgt gewisse Risiken für eine Kryptowährung, z. B. plötzliche Änderungen der Gesetzgebung, die zu Störungen oder sogar zum Zusammenbruch führen können. `}</p>
    <p>{`Für Zenotta ist diese Herausforderung einfach zu bewältigen. Jeder der relativ wenigen Mempool-Knotenpunkte befindet sich in einer anderen geografischen Region. Dadurch wird verhindert, dass eine einzelne Nation oder ein einzelnes Rechtssystem die Funktionsweise von Zenotta beeinträchtigt, und das potenzielle Risiko wird minimiert.`}</p>
    <h3>{`Geringere Speicheranforderungen`}</h3>
    <p>{`Der Beitritt zu den Bitcoin- oder Ethereum-Netzwerken als vollwertiger Miner erfordert das Herunterladen der gesamten Blockchain, um teilnehmen zu können. Dies kann dazu führen, dass Terabytes an Speicherplatz verbraucht werden, was eine hohe Einstiegshürde für neue Teilnehmer darstellt.`}</p>
    <p>{`Bei Zenotta hingegen wird die Last der Blockchain-Speicherung an die Storage-Knoten übergeben. Jeder kann den Speicherknoten auf Wunsch nach der vollständigen Blockchain-Historie befragen, aber das ist keine Voraussetzung für die Teilnahme am Mining, sodass potenzielle Miner so schnell wie möglich loslegen können.`}</p>
    <h3>{`Token-For-Asset-Zahlungen`}</h3>
    <p>{`Bislang war die Aufzeichnung von Token-Zahlungen für ein bestimmtes Asset auf keiner Blockchain möglich. Die Zenotta DDE Blockchain ist die erste Blockchain, die es ermöglicht, die Übertragung von Token für Vermögenswerte als Zahlungen aufzuzeichnen. Leider bedeutet die Anforderung, die Übertragung eines Vermögenswerts für einen anderen aufzuzeichnen, dass die `}<strong parentName="p">{`vergangene`}</strong>{` Architektur anderer Blockchains für Zenotta nicht funktionieren wird.`}</p>
    <p>{`Die Einführung von Mempool-Knoten ermöglicht es daher, zwei "Halbtransaktionen" zu kompilieren und in denselben Block als ein einziges Transaktionspaar aufzunehmen, auch wenn jede Hälfte von einem anderen Nutzer stammt. Dies ist nur möglich, weil die Mempool-Knoten existieren und alle Transaktionen im System ermöglichen, wodurch eine neuartige `}<strong parentName="p">{`Gegenwartsarchitektur`}</strong>{` für das Zenotta-Netzwerk entsteht.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;