import { NavRoutes } from 'utils/interfaces';
import { ContentType } from 'utils/enums';

// Content
// pdf
import whitePaper from './content/pdf/whitepaper.pdf';
import randomReduction from './content/pdf/random_reduction.pdf';
import probabilisticMerklePdf from './content/pdf/probabilistic_merkle.pdf';
import BitMlOverview from './content/BitMlOverview/BitMlOverview';
import bitcoinSmartConttracts from './content/pdf/bitml.pdf';
import bitcoinCovenantsUnchained from './content/pdf/bitcoin_covenants_unchained.pdf';
import balances from './content/pdf/balances.pdf';
import transactionsPerBlock from './content/pdf/transactions_per_block.pdf';
import litepaper from './content/pdf/litepaper.pdf';

import whitePaperFr from './contentFr/pdf/whitepaper.pdf';
import randomReductionFr from './contentFr/pdf/random_reduction.pdf';
import probabilisticMerklePdfFr from './contentFr/pdf/probabilistic_merkle.pdf';
import bitcoinSmartConttractsFr from './contentFr/pdf/bitml.pdf';
import bitcoinCovenantsUnchainedFr from './contentFr/pdf/bitcoin_covenants_unchained.pdf';
import balancesFr from './contentFr/pdf/balances.pdf';
import transactionsPerBlockFr from './contentFr/pdf/transactions_per_block.pdf';

import whitePaperDe from './contentDe/pdf/whitepaper.pdf';
import randomReductionDe from './contentDe/pdf/random_reduction.pdf';
import probabilisticMerklePdfDe from './contentDe/pdf/probabilistic_merkle.pdf';
import bitcoinSmartConttractsDe from './contentDe/pdf/bitml.pdf';
import bitcoinCovenantsUnchainedDe from './contentDe/pdf/bitcoin_covenants_unchained.pdf';
import balancesDe from './contentDe/pdf/balances.pdf';
import transactionsPerBlockDe from './contentDe/pdf/transactions_per_block.pdf';

import whitePaperIt from './contentIt/pdf/whitepaper.pdf';
import randomReductionIt from './contentIt/pdf/random_reduction.pdf';
import probabilisticMerklePdfIt from './contentIt/pdf/probabilistic_merkle.pdf';
import bitcoinSmartConttractsIt from './contentIt/pdf/bitml.pdf';
import bitcoinCovenantsUnchainedIt from './contentIt/pdf/bitcoin_covenants_unchained.pdf';
import balancesIt from './contentIt/pdf/balances.pdf';
import transactionsPerBlockIt from './contentIt/pdf/transactions_per_block.pdf';
// py
import probabilisticMerklePy from './content/ipynb/probabilistic_merkle_tree_checking.json';

/**
 * These routes will be automatically generated in Research.tsx.
 * The item is also generated as an menu item in SideNav.tsx.
 * @prop path      : Route path
 * @prop name      : Page name (if nav is set to true, it is used as display name)
 * @prop Component : Component to display when routed to path
 */
export const researchRoutes: NavRoutes[] = [
  { path: '', name: 'Home'},
  { path: '/philosophy', name: 'Philosophy', sub: [
    { path: '/litepaper', name: 'The Zenotta Litepaper', Component: {'en':litepaper, 'fr':litepaper, 'de':litepaper, 'it':litepaper}, contentType: ContentType.Pdf  },
    { path: '/peer-to-peer-electronic-trade', name: 'Peer-to-peer electronic trade (white paper)', Component: {'en':whitePaper, 'fr':whitePaperFr, 'de':whitePaperDe, 'it':whitePaperIt}, contentType: ContentType.Pdf  },
  ]},
  {
    path: '/economics', name: 'Economics', sub: [
      { path: '/balances', name: 'Balances', Component: {'en':balances, 'fr':balancesFr, 'de':balancesDe, 'it':balancesIt}, contentType: ContentType.Pdf },
    ]
  },
  { path: '/computer-science', name: 'Computer Science', sub: [
    { path: '/random-reduction-fault-tolerance', name: 'Random reduction and fault tolerance', Component: {'en':randomReduction, 'fr':randomReductionFr, 'de':randomReductionDe, 'it':randomReductionIt}, contentType: ContentType.Pdf},
    { path: '/transactions-per-block', name: 'Transactions per block', Component: {'en':transactionsPerBlock, 'fr':transactionsPerBlockFr, 'de':transactionsPerBlockDe, 'it':transactionsPerBlockIt}, contentType: ContentType.Pdf },
    { path: '/merkel-tree-verification', name: 'Merkle tree verification', sub: [
      { path: '/pdf', name: 'Document', Component: {'en':probabilisticMerklePdf, 'fr':probabilisticMerklePdfFr, 'de':probabilisticMerklePdfDe, 'it':probabilisticMerklePdfIt}, contentType: ContentType.Pdf },
      { path: '/ipynb', name: 'Notebook', Component: probabilisticMerklePy, link: 'https://colab.research.google.com/drive/1QMyVor5_bSAhRSj9GBgx5yUXRH62l4Hh?usp=sharing', contentType: ContentType.Ipynb},
    ]},
    { path: '/bitml', name: 'BitML', sub: [
      { path: '/overview', name: 'BitMl Overview', Component: BitMlOverview, contentType: ContentType.Jsx },
      { path: '/bitcoin-smart-contracts', name: 'A Calculus for Bitcoin Smart Contracts', Component: {'en':bitcoinSmartConttracts, 'fr':bitcoinSmartConttractsFr, 'de':bitcoinSmartConttractsDe, 'it':bitcoinSmartConttractsIt}, contentType: ContentType.Pdf },
      { path: '/bitcoin-covenants-unchained', name: 'Bitcoin covenants unchained', Component: {'en':bitcoinCovenantsUnchained, 'fr':bitcoinCovenantsUnchainedFr, 'de':bitcoinCovenantsUnchainedDe, 'it':bitcoinCovenantsUnchainedIt}, contentType: ContentType.Pdf },

    ] },
    // { path: '/keccak-prime', name: 'Keccak prime', sub: [
    //   { path: '/construction', name: 'Construction', disabled: true },
    //   { path: '/models-benchmarking', name: 'Models and benchmarking', disabled: true },
    //   { path: '/materials-methods', name: 'Materials and methods', disabled: true}
    // ]},
  ]}
];