
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Unicorn from '../img/743546cf1b1fd9d143e7899b8a6388de/unicorn_construction.png';
import Pipelining from '../img/433e26e95b0c450bcdbd02fc36f2b509/pipelining.png';
import Block from '../img/2a338d39d11d6068544a8ac89b11040f/block_add_pipelining.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Processus d'extraction des blocs`}</h1>
    <p>{`Le processus d'extraction d'un bloc dans ZNP est quelque peu complexe. Il commence de la manière générale suivante :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Les utilisateurs envoient leurs transactions à l'anneau `}{`[Mempool]`}{` (mempool-node), qui place ensuite toutes les transactions valides dans un pool d'attente pour être incluses dans un bloc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Une fois que le processus de bloc commence, les transactions du pool d'attente sont ajoutées au bloc suivant et ensuite envoyées à tous les `}<a parentName="p" {...{
            "href": "miner-node"
          }}>{`Miners`}</a>{` participant à ce tour.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Les mineurs exploitent le bloc et renvoient ensuite leurs preuves de travail (PoWs) à l'anneau Mempool.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`L'anneau Mempool sélectionne ensuite le gagnant final, qui reçoit une récompense sous forme de jeton, et le bloc et les informations de gain associées sont envoyés à l'anneau `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{`.
Les détails du processus de minage, tels que décrits dans ce document, concernent les communications entre l'anneau Mempool et les mineurs dans le processus de minage direct du bloc.`}</p>
      </li>
    </ol>
    <h2>{`Construction d'UNiCORN`}</h2>
    <p>{`L'ensemble du processus de minage nécessite l'utilisation d'un seul `}{`[UNiCORN]`}{` (unicorn), qui est généré à partir d'une valeur de départ. Cette valeur d'amorçage est composée des transactions qui entrent dans le bloc à miner, des demandes de tous les mineurs qui veulent miner le bloc et de la liste des mineurs qui ont réussi il y a deux blocs (plus d'informations sur cette dernière partie plus tard).`}</p>
    <p>{`Cet UNiCORN est utilisé pour un certain nombre de processus, notamment :`}</p>
    <ol>
      <li parentName="ol">{`Sélection du sous-ensemble de mineurs`}</li>
      <li parentName="ol">{`Sélection du gagnant du mineur`}</li>
    </ol>
    <p>{`La construction est donc la suivante, avec un élagage des valeurs d'entrée à leurs hachages afin d'économiser sur le coût de stockage :`}</p>
    <img src={Unicorn} alt="unicorn_construction" />
    <h2>{`Processus pour un seul bloc`}</h2>
    <p>{`Une fois qu'un UNiCORN est construit, la sortie peut être placée dans un générateur de nombres pseudo-aléatoires (PRNG) comme graine afin de sélectionner un sous-ensemble des mineurs qui ont postulé. Ce sous-ensemble est le seul groupe qui peut alors commencer à extraire le bloc de la manière prévue. Une fois qu'un mineur a trouvé un PoW valide, il peut le soumettre à l'anneau Mempool, qui stockera tous les PoWs réussis des mineurs participants dans une liste en attente.`}</p>
    <p>{`Une fois que le tour est considéré comme clos, le gagnant du tour est sélectionné une fois de plus via l'UNiCORN pré-calculé. Après cela, le bloc est envoyé à l'anneau de stockage pour un stockage permanent.`}</p>
    <h2>{`Pipeline parallèle pour tous les blocs`}</h2>
    <p>{`En pratique, bien que le processus ci-dessus fonctionne, il est inefficace et exige que le bloc suivant attende inutilement que le bloc précédent soit terminé avant de pouvoir commencer. Ce que nous pouvons faire à la place est de créer un processus parallèle où deux blocs sont minés simultanément, comme dans le diagramme suivant :`}</p>
    <img src={Pipelining} alt="pipelining" />
    <p>{`Pour qu'un tel processus fonctionne, il est important de s'assurer que le champ `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` de l'en-tête de bloc est correct puisque le bloc précédent ne sera pas encore complet. Ainsi, la valeur `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` ne pointera plus vers le bloc immédiatement précédent dans la chaîne mais sera plutôt composée des valeurs des deux blocs précédents. En termes simples, la valeur `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` du bloc `}<inlineCode parentName="p">{`t`}</inlineCode>{` sera composée de valeurs provenant des blocs `}<inlineCode parentName="p">{`t - 2`}</inlineCode>{` et `}<inlineCode parentName="p">{`t - 3`}</inlineCode>{`, plutôt que de `}<inlineCode parentName="p">{`t - 1`}</inlineCode>{`.`}</p>
    <p>{`Le diagramme suivant décrit ce processus pour le bloc `}<inlineCode parentName="p">{`n + 2`}</inlineCode>{`, qui a un hash précédent composé du hash précédent du bloc `}<inlineCode parentName="p">{`n - 1`}</inlineCode>{` et du hash du bloc entier dans `}<inlineCode parentName="p">{`n`}</inlineCode>{` :`}</p>
    <img src={Block} alt="block_add_pipelining" />
    <p>{`Enfin, une fois qu'un gagnant a été trouvé et sélectionné, l'anneau Mempool envoie le bloc, ainsi que les valeurs d'entrée d'UNiCORN, à l'anneau de stockage pour être stockés.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;