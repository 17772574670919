
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nodo Mempool`}</h1>
    <p>{`Il nodo Mempool è una metà dell'importante equilibrio di potere nella rete Zenotta. Mentre il mining completamente distribuito, come quello di Bitcoin, è ideale per la sicurezza, è inadeguato per altri fattori come la velocità. Di conseguenza, noi di Zenotta ci siamo resi conto che un buon compromesso è costituito da un anello di nodi Mempool decentralizzati per facilitare le transazioni, mentre i nodi Miner pubblici, completamente distribuiti, sono ancora responsabili dell'estrazione dei blocchi che li contengono e di fornire l'input nel processo decisionale equo e verificabile per la selezione delle transazioni.`}</p>
    <h2>{`Equilibrio di potere`}</h2>
    <p>{`È importante considerare come un anello di nodi Mempool sotto il controllo diretto di Zenotta possa influire sulla sicurezza e sull'affidabilità della rete. Facendo in modo che i nodi Mempool creino i blocchi e i nodi Miner li estraggano, assicuriamo che ci sia un equilibrio di controllo; se si toglie uno dei due gruppi di nodi dal sistema, l'intero sistema crolla. C'è quindi un equilibrio di tipo romano tra i nodi Mempool simili al Senato e i nodi Miner simili al Pubblico.`}</p>
    <h2>{`Struttura del nodo Mempool`}</h2>
    <p>{`Il nodo Mempool è composto da:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolNode`}</inlineCode></strong>{` la struttura dati`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolInterface`}</inlineCode></strong>{` un tratto per molti dei metodi rivolti verso l'esterno`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolRequest`}</inlineCode></strong>{` un insieme di richieste che il nodo Mempool può fare e ricevere`}</li>
    </ul>
    <p>{`Esiste un numero limitato di nodi Mempool, tutti in collaborazione con una serie di partner di livello aziendale, che raccolgono token per l'elaborazione delle transazioni e la convalida dei blocchi.`}</p>
    <h2>{`Costruzione dei blocchi`}</h2>
    <p>{`In ogni round, la responsabilità della costruzione dei blocchi è condivisa da tutti i nodi dell'anello Mempool. Ogni nodo Mempool ha un pool di transazioni personale (l'attributo `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` nel `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) che raccoglie dagli utenti. Regolarmente, essi effettuano il consensus delle transazioni RAFT da quel `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` e formano il `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{` condiviso da tutti loro. È da questo pool consensualizzato che vengono costruiti i nuovi blocchi.`}</p>
    <p>{`Quando si ricevono le informazioni dal blocco precedente memorizzato dagli StorageNodes e la maggioranza dei voti per quell'evento è consensuale, viene generato un blocco dalle transazioni già consensuali in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`.
L'ordine delle transazioni inviate e il numero di transazioni nel blocco appartenenti a un particolare nodo Mempool non sono importanti.`}</p>
    <p>{`Un blocco verrà generato anche se non contiene transazioni. Se sono disponibili troppe transazioni in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`, solo un sottoinsieme sarà utilizzato per questo blocco e il rimanente andrà in uno dei blocchi successivi. Tutti i nodi avranno generato un blocco identico dalle informazioni di Consensused e comunicheranno ai minatori della loro partizione di iniziare l'estrazione.`}</p>
    <h2>{`Nodi Mempool e nodi Miner`}</h2>
    <p>{`Una volta generato un blocco, mentre un blocco viene estratto e memorizzato, i nodi minerari inviano richieste a tutti i nodi Mempool per partecipare al successivo ciclo di estrazione dei blocchi. Questo avviene perché, a ogni tornata, non è l'intera rete a minare (a differenza di Bitcoin). Invece, il protocollo di rete Zenotta permette solo a `}<em parentName="p">{`partizioni`}</em>{` o sottoinsiemi della rete di minare in un dato momento, e ogni nodo Mempool ha la sua partizione di minatori. Questo elenco di partizioni (memorizzato nella `}<inlineCode parentName="p">{`partition_list`}</inlineCode>{` in `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) cambia a ogni turno, consentendo ai nodi minatori una nuova opportunità di minare a ogni turno.`}</p>
    <p>{`Una volta che l'elenco di partizioni di un nodo Mempool è completo, i nodi Miner di quel nodo Mempool sono considerati selezionati e quando il nuovo blocco è pronto per essere estratto, il nodo Mempool lo invia ai nodi Miner che sono entrati nell'elenco di partizioni. I Miner che trovano una Proof-of-Work valida per il blocco entro il tempo del round di mining sono considerati i vincitori, tra i quali viene selezionato casualmente un Miner tramite `}{`[UNiCORN]`}{` (unicorni), che invia il proprio blocco al nodo Mempool per la convalida e l'archiviazione. Per informazioni specifiche sul funzionamento dei Miner, è possibile `}<a parentName="p" {...{
        "href": "Miner-Node"
      }}>{`leggere questa pagina wiki`}</a>{`.`}</p>
    <h2>{`Nodi Mempool e nodi di archiviazione`}</h2>
    <p>{`Una volta che il nodo Mempool ha ricevuto un blocco valido, dichiara il vincitore per la sua partizione e invia il blocco al nodo Storage associato per la memorizzazione a lungo termine. A questo punto, tutti gli attributi relativi al round nel nodo Mempool vengono cancellati e svuotati e il round successivo può iniziare.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;