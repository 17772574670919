import styles from './NotFound.module.scss';
import Error404 from 'static/img/404.svg';
import Button from 'components/inputs/Button/Button';
import { useHistory } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <img src={Error404} alt={t('Error 404')} />
      <h2>{t("We can't find that page")}</h2>
      <p>{t("It seems this page doesn't exist anymore. We're sorry about that.")}</p>
      <Button content={t('Back to home')} onClick={() => history.push('/')} />
    </div>
  );
};

export default hot(NotFound);
