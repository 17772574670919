
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Unicorn from './img/743546cf1b1fd9d143e7899b8a6388de/unicorn_construction.png';
import Pipelining from './img/433e26e95b0c450bcdbd02fc36f2b509/pipelining.png';
import Block from './img/2a338d39d11d6068544a8ac89b11040f/block_add_pipelining.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Block Mining Process`}</h1>
    <p>{`The process for mining a block in ZNP is somewhat complex. It starts with the following general way:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Users send their transactions to the `}<a parentName="p" {...{
            "href": "mempool-node"
          }}>{`Mempool`}</a>{` ring, which then place all valid transactions into a waiting pool to be included in a block.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once the block process starts, transactions from the waiting pool are added to the next block and then sent to all `}<a parentName="p" {...{
            "href": "miner-node"
          }}>{`Miners`}</a>{` participating in this round.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Miners mine the block and then send their Proof-of-Works (PoWs) back to the Mempool ring.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Mempool ring then selects the final winner, who receives a token reward, and the block and its associated winning information gets sent to the `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{` ring.`}</p>
      </li>
    </ol>
    <p>{`The details of the mining process, as laid out in this doc, involve the communications between the Mempool ring and the Miners in the process of the direct mining of the block.`}</p>
    <h2>{`UNiCORN Construction`}</h2>
    <p>{`The entire mining process requires the use of a single `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{`, which is generated from a seed value. This seed value is composed of the transactions that are going into the block to be mined, the applications of all Miners who want to mine the block, and the list of successful Miners from 2 blocks ago (more info on this last part later).`}</p>
    <p>{`This UNiCORN is used for a number of processes, most notably:`}</p>
    <ol>
      <li parentName="ol">{`Miner subset selection`}</li>
      <li parentName="ol">{`Miner winner selection`}</li>
    </ol>
    <p>{`The construction is therefore the following, with pruning of input values to their hashes in order to save on storage cost:`}</p>
    <img src={Unicorn} alt="unicorn_construction" />
    <h2>{`Process for a Single Block`}</h2>
    <p>{`Once a UNiCORN is constructed, the output can be placed into a pseudo-random number generator (PRNG) as seed in order to select a subset of the Miners who applied. The subset is the only group who can then start mining the block in the expected fashion. Once a miner has found a valid PoW they can submit it to the Mempool ring, who will store all successful PoWs from participating miners into a pending list.`}</p>
    <p>{`Once the round is considered closed, the winner for the round is selected once again via the pre-calculated UNiCORN. After this, the block is sent to the Storage ring for permanent storage.`}</p>
    <h2>{`Parallel Pipeline for All Blocks`}</h2>
    <p>{`In practice, although the above process works, it is inefficient and requires the following block to needlessly wait for the previous block to finish before it can begin. What we can do instead is create a parallel process where two blocks are mined simultaneously, as in the following diagram:`}</p>
    <img src={Pipelining} alt="pipelining" />
    <p>{`In order for such a process to work, it's important to ensure that the `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` field of the block header is correct since the previous block will not be complete yet. As such, the `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` value will no longer point to the immediately preceding block in the chain but will rather be composed of values from the two blocks previous to the preceding block. In simple terms, the `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` for block `}<inlineCode parentName="p">{`t`}</inlineCode>{` will be composed of values from blocks `}<inlineCode parentName="p">{`t - 2`}</inlineCode>{` and `}<inlineCode parentName="p">{`t - 3`}</inlineCode>{`, rather than from `}<inlineCode parentName="p">{`t - 1`}</inlineCode>{`.`}</p>
    <p>{`The following diagram describes this process for block `}<inlineCode parentName="p">{`n + 2`}</inlineCode>{`, which has a previous hash composed of the previous hash of block `}<inlineCode parentName="p">{`n - 1`}</inlineCode>{` and the hash of the entire block in `}<inlineCode parentName="p">{`n`}</inlineCode>{`:`}</p>
    <img src={Block} alt="block_add_pipelining" />
    <p>{`Finally, after a winner has been found and selected the Mempool ring then sends the block, as well as the UNiCORN input values, to the Storage ring for storage.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;