
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Unicorn from '../img/743546cf1b1fd9d143e7899b8a6388de/unicorn_construction.png';
import Pipelining from '../img/433e26e95b0c450bcdbd02fc36f2b509/pipelining.png';
import Block from '../img/2a338d39d11d6068544a8ac89b11040f/block_add_pipelining.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Prozess des Blockabbaus`}</h1>
    <p>{`Das Verfahren zum Schürfen eines Blocks in ZNP ist etwas komplex. Er beginnt mit der folgenden allgemeinen Vorgehensweise:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Die Benutzer senden ihre Transaktionen an den `}{`[Mempool]`}{` (mempool-Node) Ring, der dann alle gültigen Transaktionen in einen Wartepool legt, um sie in einen Block aufzunehmen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Sobald der Blockprozess beginnt, werden die Transaktionen aus dem Wartepool dem nächsten Block hinzugefügt und dann an alle `}<a parentName="p" {...{
            "href": "Miner-Knoten"
          }}>{`Miner`}</a>{` gesendet, die an dieser Runde teilnehmen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Die Miner bauen den Block ab und senden dann ihre Proof-of-Works (PoWs) zurück an den Mempool-Ring.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Der Mempool-Ring wählt dann den endgültigen Gewinner aus, der eine Token-Belohnung erhält, und der Block und die zugehörigen Gewinninformationen werden an den `}{`[Storage]`}{`-(Storage-Node)-Ring gesendet.`}</p>
      </li>
    </ol>
    <p>{`Die Details des Mining-Prozesses, wie sie in diesem Dokument dargelegt sind, betreffen die Kommunikation zwischen dem Mempool-Ring und den Minern beim direkten Mining des Blocks.`}</p>
    <h2>{`UNiCORN-Konstruktion`}</h2>
    <p>{`Der gesamte Mining-Prozess erfordert die Verwendung eines einzigen `}{`[UNiCORN]`}{` (unicorns), der aus einem Seed-Wert erzeugt wird. Dieser Seed-Wert setzt sich zusammen aus den Transaktionen, die in den zu minenden Block einfließen, den Bewerbungen aller Miner, die den Block minen wollen, und der Liste der erfolgreichen Miner von vor 2 Blöcken (mehr Infos zu diesem letzten Teil später).`}</p>
    <p>{`Dieses UNiCORN wird für eine Reihe von Prozessen verwendet, vor allem für:`}</p>
    <ol>
      <li parentName="ol">{`Auswahl der Miner-Teilmenge`}</li>
      <li parentName="ol">{`Auswahl der Miner-Gewinner`}</li>
    </ol>
    <p>{`Die Konstruktion sieht daher wie folgt aus, wobei die Eingabewerte auf ihre Hashes reduziert werden, um Speicherkosten zu sparen:`}</p>
    <img src={Unicorn} alt="unicorn_construction" />
    <h2>{`Prozess für einen einzelnen Block`}</h2>
    <p>{`Sobald ein UNiCORN konstruiert ist, kann die Ausgabe in einen Pseudo-Zufallszahlengenerator (PRNG) als Seed eingesetzt werden, um eine Teilmenge der Miner auszuwählen, die sich beworben haben. Diese Untergruppe ist die einzige Gruppe, die den Block auf die erwartete Weise schürfen kann. Sobald ein Schürfer einen gültigen PoW gefunden hat, kann er ihn an den Mempool-Ring übermitteln, der alle erfolgreichen PoWs der teilnehmenden Schürfer in einer Pending-Liste speichern wird.`}</p>
    <p>{`Sobald die Runde als abgeschlossen gilt, wird der Gewinner der Runde erneut über den vorberechneten UNiCORN ermittelt. Danach wird der Block zur dauerhaften Speicherung an den Speicherring gesendet.`}</p>
    <h2>{`Parallele Pipeline für alle Blöcke`}</h2>
    <p>{`In der Praxis funktioniert der obige Prozess zwar, ist aber ineffizient und erfordert, dass der folgende Block unnötigerweise auf die Beendigung des vorherigen Blocks wartet, bevor er beginnen kann. Stattdessen können wir einen parallelen Prozess erstellen, bei dem zwei Blöcke gleichzeitig abgebaut werden, wie im folgenden Diagramm dargestellt:`}</p>
    <img src={Pipelining} alt="pipelining" />
    <p>{`Damit ein solcher Prozess funktioniert, muss sichergestellt werden, dass das Feld `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` des Blockheaders korrekt ist, da der vorherige Block noch nicht vollständig ist. Der Wert "previous_hash" verweist also nicht mehr auf den unmittelbar vorangehenden Block in der Kette, sondern setzt sich aus den Werten der beiden Blöcke zusammen, die dem vorangehenden Block vorausgehen. Vereinfacht ausgedrückt setzt sich der `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` für den Block `}<inlineCode parentName="p">{`t`}</inlineCode>{` aus den Werten der Blöcke `}<inlineCode parentName="p">{`t - 2`}</inlineCode>{` und `}<inlineCode parentName="p">{`t - 3`}</inlineCode>{` zusammen, anstatt aus `}<inlineCode parentName="p">{`t - 1`}</inlineCode>{`.`}</p>
    <p>{`Das folgende Diagramm beschreibt diesen Vorgang für den Block `}<inlineCode parentName="p">{`n + 2`}</inlineCode>{`, der einen vorherigen Hash hat, der sich aus dem vorherigen Hash des Blocks `}<inlineCode parentName="p">{`n - 1`}</inlineCode>{` und dem Hash des gesamten Blocks in `}<inlineCode parentName="p">{`n`}</inlineCode>{` zusammensetzt:`}</p>
    <img src={Block} alt="block_add_pipelining" />
    <p>{`Nachdem ein Gewinner gefunden und ausgewählt wurde, sendet der Mempool-Ring den Block sowie die UNiCORN-Eingabewerte zur Speicherung an den Storage-Ring.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;