
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Transactions`}</h1>
    <p>{`Transactions in the Zenotta system are structured similarly to those found in Bitcoin. They consist primarily of a reference to a previous spendable amount on the blockchain (for Zenotta this can be tokens or data assets) and a reference to an address to send the spendable amount to. To accomplish this, the spender is required to verify that they are the original owner of the spendable amount through public key signature schemes.`}</p>
    <h2>{`Structure`}</h2>
    <p>{`In the codebase, a transaction is structured as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct Transaction {
    pub inputs: Vec<TxIn>,
    pub outputs: Vec<TxOut>,
    pub version: usize,
    pub druid: Option<String>,
    pub druid_participants: Option<usize>,
    pub expect_value: Option<Asset>,
    pub expect_value_amount: Option<TokenAmount>,
}
`}</code></pre>
    <p>{`The attributes we care about for ownership verification are `}<inlineCode parentName="p">{`inputs`}</inlineCode>{` and `}<inlineCode parentName="p">{`outputs`}</inlineCode>{`, corresponding to the `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` and `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` data structures respectively.`}</p>
    <h2>{`Inputs`}</h2>
    <p>{`The inputs to a transaction are codified in the `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` data structure, which looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxIn {
    pub previous_out: Option<OutPoint>,
    pub script_signature: Script,
}
`}</code></pre>
    <p>{`where the `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` referenced in `}<inlineCode parentName="p">{`previous_out`}</inlineCode>{` looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct OutPoint {
    pub t_hash: String,
    pub n: i32,
}
`}</code></pre>
    <p>{`Here the `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` has a `}<inlineCode parentName="p">{`t_hash`}</inlineCode>{` corresponding to the hash of the previous spendable transaction in the blockchain. Within the `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{` attribute we find a transaction execution script which will define how the spendable amount should actually be spent. Included in all `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{` entries is at least one signature which matches the public key of the spendable transaction already in the blockchain. The public key for this specific transaction will be found in that transaction's referenced output.`}</p>
    <h2>{`Outputs`}</h2>
    <p>{`The outputs of a transactions are codified in the `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` data structure, which looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxOut {
    pub value: Option<Asset>,
    pub amount: TokenAmount,
    pub locktime: u64,
    pub drs_block_hash: Option<String>,
    pub drs_tx_hash: Option<String>,
    pub script_public_key: Option<String>,
}
`}</code></pre>
    <p>{`Here we'll focus on the `}<inlineCode parentName="p">{`script_public_key`}</inlineCode>{`, which is the public key that the owner of the transaction needs to use (and sign with) in order to spend the amount on in the future.`}</p>
    <h2>{`Signing`}</h2>
    <p>{`In the Zenotta system, in order to tie the `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` of the current transaction to the `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` of the previous the user needs to sign the `}<strong parentName="p">{`hash of the `}<inlineCode parentName="strong">{`OutPoint`}</inlineCode></strong>{` referencing that `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`. In this way it becomes impossible to spend a `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` amount without`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`(A)`}</strong>{` The `}<inlineCode parentName="li">{`OutPoint`}</inlineCode>{` referencing a specific `}<inlineCode parentName="li">{`TxOut`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`(B)`}</strong>{` The user having the private key corresponding to the `}<inlineCode parentName="li">{`TxOut`}</inlineCode>{`'s public key`}</li>
      <li parentName="ul"><strong parentName="li">{`(C)`}</strong>{` Being able to sign with the private key to on-spend the tokens`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;