
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mempool-Knoten`}</h1>
    <p>{`Der Mempool-Knoten ist die eine Hälfte des sehr wichtigen Kräfteverhältnisses im Zenotta-Netzwerk. Während vollständig verteiltes Mining, wie bei Bitcoin, ideal für die Sicherheit ist, ist es für andere Faktoren, wie z.B. Geschwindigkeit, unzureichend. Daher haben wir bei Zenotta erkannt, dass ein Ring dezentraler Mempool-Knoten zur Erleichterung von Transaktionen einen guten Kompromiss darstellt. Die öffentlichen, vollständig verteilten Miner-Knoten sind weiterhin für das Mining der Blöcke verantwortlich, die sie enthalten, und liefern den Input für den nachweislich fairen Entscheidungsprozess zur Auswahl der Transaktionen.`}</p>
    <h2>{`Gleichgewicht der Kräfte`}</h2>
    <p>{`Es ist wichtig zu bedenken, wie sich ein Ring von Mempool-Knoten unter der direkten Kontrolle von Zenotta auf die Sicherheit und Vertrauenswürdigkeit des Netzwerks auswirken kann. Indem wir Mempool-Knoten Blöcke erstellen und Miner-Knoten sie abbauen lassen, stellen wir sicher, dass es ein Gleichgewicht der Kontrolle gibt; nimmt man eine der beiden Gruppen von Knoten aus dem System heraus, fällt das Ganze auseinander. Es besteht also ein Gleichgewicht im römischen Stil zwischen den senatsähnlichen Mempool-Knoten und den öffentlichkeitsähnlichen Miner-Knoten.`}</p>
    <h2>{`Struktur des Mempool-Knotens`}</h2>
    <p>{`Der Mempool-Knoten besteht aus:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolNode`}</inlineCode></strong>{` die Datenstruktur`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolInterface`}</inlineCode></strong>{` ein Merkmal für viele der nach außen gerichteten Methoden`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolRequest`}</inlineCode></strong>{` ein Merkmal für viele der nach außen gerichteten Methoden`}</li>
    </ul>
    <p>{`Es gibt eine begrenzte Anzahl von Mempool-Knoten, die alle mit einer Reihe von Unternehmenspartnern zusammenarbeiten und Token-Belohnungen für die Verarbeitung von Transaktionen und die Validierung von Blöcken sammeln.`}</p>
    <h2>{`Blöcke konstruieren`}</h2>
    <p>{`In jeder Runde teilen sich alle Knoten innerhalb des Mempool-Rings die Verantwortung für den Blockaufbau. Jeder Mempool-Knoten hat einen persönlichen Transaktionspool (das Attribut `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` im `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`), den er von den Nutzern sammelt. Regelmäßig werden RAFT-Transaktionen aus diesem `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` konsensiert und bilden den `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`, der von allen genutzt wird. Aus diesem konsensualen Pool werden neue Blöcke erstellt.`}</p>
    <p>{`Wenn die Informationen aus dem vorherigen, von den StorageNodes gespeicherten Block empfangen werden und eine Mehrheit der Stimmen für dieses Ereignis konsensiert wird, wird ein Block aus bereits konsensierten Transaktionen in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{` erzeugt.
Die Reihenfolge der gesendeten Transaktionen und die Anzahl der Transaktionen im Block, die zu einem bestimmten Mempool-Knoten gehören, sind nicht wichtig.`}</p>
    <p>{`Ein Block wird auch dann erzeugt, wenn er keine Transaktionen enthält. Wenn zu viele Transaktionen in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{` vorhanden sind, wird nur eine Teilmenge für diesen Block verwendet, und die verbleibenden gehen in einen der nächsten Blöcke. Alle Knoten werden einen identischen Block aus den konsensierten Informationen generieren und die Miner in ihrer Partition benachrichtigen, mit dem Mining zu beginnen.`}</p>
    <h2>{`Mempool-Knoten und Miner-Knoten`}</h2>
    <p>{`Sobald ein Block generiert ist, während ein Block abgebaut und gespeichert wird, senden die Mining-Knoten Anfragen an alle Mempool-Knoten, um an der nächsten Runde des Block-Minings teilzunehmen. Dies geschieht, weil nicht in jeder Runde das gesamte Netzwerk schürft (anders als bei Bitcoin). Stattdessen erlaubt das Zenotta-Netzwerkprotokoll nur `}<em parentName="p">{`Partitionen`}</em>{` oder Untergruppen des Netzwerks, zu einem bestimmten Zeitpunkt zu schürfen, und jeder Mempool-Knoten hat seine eigene Partition von Schürfern. Diese Partitionsliste (gespeichert in der `}<inlineCode parentName="p">{`partition_list`}</inlineCode>{` in `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) ändert sich jede Runde, so dass die Miner-Knoten jede Runde eine neue Gelegenheit zum Mining erhalten.`}</p>
    <p>{`Sobald die Partitionsliste für einen Mempool-Knoten voll ist, werden die Miner-Knoten für diesen Mempool-Knoten als ausgewählt betrachtet, und wenn der neue Block zum Mining bereit ist, sendet der Mempool-Knoten ihn an die Miner-Knoten, die es in die Partitionsliste geschafft haben. Diejenigen Miner, die innerhalb der Mining-Runde ein gültiges Proof-of-Work für den Block finden, werden als Gewinner betrachtet, von denen ein Miner zufällig durch den `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` ausgewählt wird und seinen Block zur Validierung und Speicherung an den Mempool-Knoten sendet. Einzelheiten zur Funktionsweise der Miner können Sie `}<a parentName="p" {...{
        "href": "Miner-Node"
      }}>{`auf dieser Wiki-Seite`}</a>{` nachlesen.`}</p>
    <h2>{`Mempool-Knoten und Speicherknoten`}</h2>
    <p>{`Sobald der Mempool-Knoten einen gültigen Block erhalten hat, erklärt er den Gewinner für seine Partition und sendet den Block zur Langzeitspeicherung an den zugehörigen Speicherknoten. Danach werden alle rundenbezogenen Attribute im Mempool-Knoten gelöscht und geleert und die nächste Runde kann beginnen.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;