
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Das Zenotta-Netzwerkprotokoll`}</h1>
    <p>{`Das Zenotta-Netzwerkprotokoll(ZNP) ist für den gesamten netzwerkbezogenen Code im Zenotta-System verantwortlich. Es ist das Repo, das für die Kommunikation zwischen den Knoten im Mining-Netzwerk verantwortlich ist, sowie für die Integration mit der Blockchain-Datenstruktur, die sich im Repo "NAOM" befindet. Im Folgenden finden Sie das Inhaltsverzeichnis der weiteren Dokumentation, die Sie über die Architektur des gesamten Projekts informieren wird:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "node-types"
        }}>{`Knotentypen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mempool-node"
        }}>{`Mempool-Knoten`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "miner-node"
        }}>{`Miner-Knoten`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "storage-node"
        }}>{`Storage Node`}</a>
        {/* - [The Wallet](wallet) */}
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "unicorns"
        }}>{`UNiCORNs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "Transaktionen"
        }}>{`Transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "Block-Mining"
        }}>{`Block-Mining-Prozess`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "dual-double-entry"
        }}>{`Duale Doppeleintrags-Transaktionen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "shuriken-auditing"
        }}>{`Shuriken-Prüfung`}</a></li>
    </ul>
    <h1>{`Erste Schritte`}</h1>
    <p>{`Zu Beginn ist es wichtig zu verstehen, wie das ZNP in das größere Zenotta-System passt. Das Zenotta-System besteht hauptsächlich aus 3 Hauptkomponenten:`}</p>
    <ul>
      <li parentName="ul">{`Das Zenotta-Netzwerkprotokoll (ZNP)`}</li>
      <li parentName="ul">{`Die Dual Double Entry (DDE) Blockchain`}</li>
      <li parentName="ul">{`Das Zenotta-Datenprotokoll (ZDP)`}</li>
    </ul>
    <p>{`Vorerst werden wir nur die ersten beiden Punkte dieser Liste betrachten. Der `}<strong parentName="p">{`ZNP`}</strong>{` sendet und empfängt Nachrichten, die zwischen verschiedenen Knotentypen weitergegeben werden, und leitet außerdem Speicher an die `}<strong parentName="p">{`DDE Blockchain`}</strong>{` weiter, um die Blockchain-Geschichte aufzuzeichnen.`}</p>
    <h2>{`Systemarchitektur`}</h2>
    <p>{`Die Zenotta-Systemarchitektur spiegelt viel von dem wider, was Sie in der Bitcoin-Architektur finden, da unsere Blockchain auf Proof-of-Work (PoW) Konsens basiert. Im Gegensatz zum traditionellen Bitcoin ist unsere Blockchain jedoch zu schnelleren Transaktionsgeschwindigkeiten fähig (was zu schnelleren Freigabezeiten führt) und unser PoW-System ist leichter und umfassender (aber dazu später mehr).`}</p>
    <p>{`Der andere große Unterschied zwischen Zenotta und traditionellen Blockchains ist die Einführung des Dual Double Entry (DDE) Systems. Im Gegensatz zu traditionellen Blockchains kann unsere Blockchain sowohl die Bewegung von Token `}<em parentName="p">{`als auch`}</em>{` die Bewegung von Vermögenswerten für diese Token aufzeichnen. Dank unseres `}<strong parentName="p">{`ZDP`}</strong>{` oder Zenotta-Datenprotokolls können diese Vermögenswerte Ihre eigenen und transaktionsfähigen Datenwerte sein.`}</p>
    <p>{`Um dies zu ermöglichen, nutzt die Zenotta-Architektur verschiedene Arten von Knotenpunkten in ihrem Netzwerk. Mehr über die verschiedenen Knotentypen erfahren Sie `}<a parentName="p" {...{
        "href": "node-types"
      }}>{`hier`}</a>{`.`}</p>
    <h2>{`Code Entwurf`}</h2>
    <p>{`Wir haben eine bestimmte Design-Philosophie in Bezug auf den bei Zenotta geschriebenen Code. Das Ideal ist ein Code, der leicht wiederverwendbar, aber vor allem leicht verständlich ist. Wir behandeln unseren Code wie eine Client-Server-Beziehung, bei der der Code der Server ist und Sie, der menschliche Benutzer, der Client sind. Zu diesem Zweck haben wir den Ansatz der `}{`[Three Tier Architecture]`}{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multitier_architecture#Three-tier_architecture"
      }}>{`https://en.wikipedia.org/wiki/Multitier_architecture#Three-tier_architecture`}</a>{`) übernommen, der von `}{`[John J. Donovan]`}{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_J._Donovan"
      }}>{`https://en.wikipedia.org/wiki/John_J._Donovan`}</a>{`) populär gemacht wurde und der kurz gesagt aus Folgendem besteht:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Präsentation Ebene`}</strong>{`: Wie die Benutzeroberfläche zu behandeln. Auf dieser Ebene sollte es die höchsten Abstraktionen für jedes Modul geben, das wir schreiben, bestehend aus ein paar einfach zu verwendenden Funktionen, die in einer API für den allgemeinen Gebrauch implementiert werden können.
`}<strong parentName="p"><em parentName="strong">{`Entwickler Level: Anfänger`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Logische Ebene`}</strong>{`: Dies ist eine Ebene unterhalb der Präsentationsebene, auf der der Code komplexer ist, aber auch leistungsfähigere Funktionen bietet. Logik und Geschäftsvorgänge sind auf dieser Ebene zu finden und helfen dabei, die Abstraktionen auf höherer Ebene in der Präsentationsebene zu erleichtern. `}<strong parentName="p"><em parentName="strong">{`Entwickler Level: Fortgeschrittene`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Datenebene`}</strong>{`: Dies ist die unterste Ebene der Code-Architektur und als solche die komplexeste. Funktionen und Operationen auf dieser Ebene interagieren direkt mit Operationen auf Systemebene, wie z. B. E/A- und Netzwerkverbindungshandling, sowie mit der direkten Interaktion mit der Blockchain. `}<strong parentName="p"><em parentName="strong">{`Entwickler Level: Fortgeschrittene`}</em></strong></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;