import styles from './ZScriptEmulator.module.scss';
import Editor from './playground/Editor';
import AceEditor from "react-ace";
import Link from 'components/layout/Link/Link';
import { useTranslation } from 'react-i18next';

export const ZScriptEmulator = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1>{t("ZScript Emulator")}</h1>

            <p>
                {t("Zenotta makes use of ops codes in order to execute scripts within transactions. We call the scripting language ZScript, and you can play with the ZScript emulator below")}
            </p>
            <Editor />
            <p>
                {t("There are of course a number of transaction scripts that we use in the Zenotta blockchain as well, which you can take a look at below.")}
            </p>

            <h2>{t("Pay to Public Key Hash")} (p2pkh)</h2>

            <p>
                {t("The first, and most important, script is the p2pkh script. This script is used to send an asset to an address so long as the sender is able to sign for the on-spend of said asset, and is modelled largely after the kind of p2pkh script you might find in ")}
                <Link to={'https://en.bitcoin.it/wiki/Script#Standard_Transaction_to_Bitcoin_address_.28pay-to-pubkey-hash.29'} out={true}>{t('BitCoin.')}</Link>
                
            </p>

            <div className={styles.editorContainer}>
                <AceEditor
                    onChange={() => {}}
                    mode={"zscript"}
                    width={"100%"}
                    height={"100%"}
                    theme={"zenotta-light"}
                    fontSize={'13pt'}
                    value={`<bytes> <sig> <pubkey>
OP_DUP OP_HASH256 <pubkey_hash>
OP_EQUALVERIFY OP_CHECKSIG`}
                />
            </div>

            <h2>{t("Lock Funds until a Given Time")}</h2>

            <p>
                {t("In addition to regular payments, we also have the ability to lock funds for a given time before enabling on-spending. Again, this script is similar to the ")}
                <Link to={'https://en.bitcoin.it/wiki/Script#Freezing_funds_until_a_time_in_the_future'} out={true}>{t('one found in BitCoin')}</Link>
                {t(" but in Zenotta's case only block times can be used as valid time locks.")}
            </p>

            <div className={styles.editorContainer}>
                <AceEditor
                    onChange={() => {}}
                    mode={"zscript"}
                    height={"100%"}
                    width={"100%"}
                    theme={"zenotta-light"}
                    fontSize={'13pt'}
                    value={`<expiry time> OP_CHECKLOCKTIMEVERIFY OP_DROP
OP_DUP OP_HASH160 <pubKeyHash>
OP_EQUALVERIFY OP_CHECKSIG`}
                />
            </div>
        </div>
    );
}