
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MempoolMiner from '../img/e234a5fa86923a5e87e4b9ee2742f01d/compute-miner.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Nodo Minatore`}</h1>
    <p>{`Il nodo Miner rappresenta il minatore medio del sistema Zenotta. L'insieme dei minatori è responsabile di garantire che i blocchi di transazioni vengano estratti e aggiunti alla cronologia della blockchain. Per i loro sforzi, i minatori vincenti ricevono una ricompensa in token come in Bitcoin. A differenza di Bitcoin, tuttavia, Zenotta utilizza un metodo di mining a ridotto consumo energetico, pur mantenendo gli aspetti utili di `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Proof_of_work"
      }}>{`Proof-of-Work (PoW)`}</a>{`. Questo metodo sarà discusso ulteriormente in `}<a parentName="p" {...{
        "href": "#partitioning"
      }}>{`Partitioning`}</a>{`.`}</p>
    <h2>{`Equilibrio di potere`}</h2>
    <p>{`Esiste un equilibrio di tipo romano tra i nodi Mempool, simili al Senato, e i nodi Miner, simili al Pubblico, che mantiene il sistema Zenotta privo di fiducia. Mentre i nodi Mempool raggruppano le transazioni in blocchi dopo averle convalidate, spetta comunque ai nodi Miner garantire che questi blocchi vengano estratti e aggiunti ai nodi Storage. Se i minatori decidono a maggioranza di rifiutare un blocco costruito da un nodo Mempool, il blocco non può essere aggiunto al libro mastro. Inoltre, i minatori potrebbero decidere di rifiutare del tutto un particolare nodo Mempool in malafede, rendendolo inutile, e in un caso estremo potrebbero abbandonare del tutto il sistema.`}</p>
    <p>{`Il modo in cui viene garantita l'equità per quanto riguarda l'estrazione dei blocchi e la partecipazione dei minatori sarà discusso nella prossima sezione.`}</p>
    <h2>{`Partizionamento`}</h2>
    <p>{`Alla base dell'equilibrio Mempool-Miner c'è il sistema di partizionamento. Invece di tutti i minatori che estraggono il blocco a ogni round (sprecando enormi quantità di energia), solo alcuni minatori selezionati vengono scelti ogni round per partecipare. I minatori che non sono stati scelti possono quindi risparmiare la loro energia e tentare di partecipare nuovamente al turno successivo. In termini di mining, la struttura della rete ad ogni round è mostrata in questo diagramma, dove solo i nodi miner (indicati con una "M") all'interno dello spazio arancione sono in grado di effettuare il mining per quel dato nodo Mempool:`}</p>
    <img src={MempoolMiner} alt="compute-miner" />
    <p>{`A ogni round, i minatori possono inviare una richiesta a un nodo Mempool a cui si sono collegati per partecipare al mining del blocco in arrivo. Ogni nodo Mempool può accogliere solo un numero limitato di minatori nella propria partizione e, una volta raggiunto il limite della partizione, il blocco può essere inviato per il mining.`}</p>
    <p>{`Quando ricevono una richiesta di partizione da parte di un minatore, i nodi Mempool eseguono le seguenti operazioni:`}</p>
    <ul>
      <li parentName="ul">{`Il minatore A invia alla Mempool A una richiesta di adesione a una partizione. Questa richiesta contiene una piccola Proof-of-Work per garantire che il nodo Mempool non sia sfruttato da uno o più minatori malintenzionati.`}</li>
      <li parentName="ul">{`Mempool A utilizza quindi il risultato dell'algoritmo `}<a parentName="li" {...{
          "href": "unicorni"
        }}>{`UNiCORN`}</a>{` per selezionare i minatori della partizione che si uniranno al processo di estrazione.`}</li>
      <li parentName="ul">{`La Mempool A fornisce il blocco a tutti i minatori selezionati nella partizione, compreso il minatore A, e i minatori possono ora iniziare l'estrazione.`}</li>
      <li parentName="ul">{`I minatori hanno a disposizione un tempo deterministico per estrarre il blocco, entro il quale possono inviare la loro Proof-of-Work vincente per il blocco stesso.`}</li>
      <li parentName="ul">{`Al termine del tempo di round, i nodi Mempool utilizzano nuovamente l'UNiCORN per selezionare il minatore vincente dall'insieme di invii PoW validi, e il blocco viene inviato con la sua coinbase ai nodi Storage.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;