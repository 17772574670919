
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Transactions`}</h1>
    <p>{`Les transactions dans le système Zenotta sont structurées de manière similaire à celles que l'on trouve dans Bitcoin. Elles consistent principalement en une référence à un montant dépensable précédent sur la blockchain (pour Zenotta, il peut s'agir de jetons ou de données) et en une référence à une adresse à laquelle envoyer le montant dépensable. Pour ce faire, le dépensier doit vérifier qu'il est le propriétaire initial du montant à dépenser au moyen de systèmes de signature à clé publique.`}</p>
    <h2>{`Structure`}</h2>
    <p>{`Dans le codebase, une transaction est structurée comme suit :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct Transaction {
    pub inputs: Vec<TxIn>,
    pub outputs: Vec<TxOut>,
    pub version: usize,
    pub druid: Option<String>,
    pub druid_participants: Option<usize>,
    pub expect_value: Option<Asset>,
    pub expect_value_amount: Option<TokenAmount>,
}
`}</code></pre>
    <p>{`Les attributs qui nous intéressent pour la vérification de la propriété sont `}<inlineCode parentName="p">{`inputs`}</inlineCode>{` et `}<inlineCode parentName="p">{`outputs`}</inlineCode>{`, correspondant respectivement aux structures de données `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` et `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`.`}</p>
    <h2>{`Entrées`}</h2>
    <p>{`Les entrées d'une transaction sont codifiées dans la structure de données `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{`, qui ressemble à ce qui suit :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxIn {
    pub previous_out: Option<OutPoint>,
    pub script_signature: Script,
}
`}</code></pre>
    <p>{`où le `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` référencé dans `}<inlineCode parentName="p">{`previous_out`}</inlineCode>{` ressemble à ce qui suit :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct OutPoint {
    pub t_hash: String,
    pub n: i32,
}
`}</code></pre>
    <p>{`Ici, le `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` a un `}<inlineCode parentName="p">{`t_hash`}</inlineCode>{` correspondant au hash de la transaction dépensable précédente dans la blockchain. Dans l'attribut `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{`, nous trouvons un script d'exécution de la transaction qui définira comment le montant à dépenser doit être dépensé. Toutes les entrées de `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{` comprennent au moins une signature qui correspond à la clé publique de la transaction dépensable déjà dans la blockchain. La clé publique pour cette transaction spécifique sera trouvée dans la sortie référencée de cette transaction.`}</p>
    <h2>{`Sorties`}</h2>
    <p>{`Les sorties d'une transaction sont codifiées dans la structure de données `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`, qui ressemble à ce qui suit :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxOut {
    pub value: Option<Asset>,
    pub amount: TokenAmount,
    pub locktime: u64,
    pub drs_block_hash: Option<String>,
    pub drs_tx_hash: Option<String>,
    pub script_public_key: Option<String>,
}
`}</code></pre>
    <p>{`Nous nous concentrerons ici sur la `}<inlineCode parentName="p">{`script_public_key`}</inlineCode>{`, qui est la clé publique que le propriétaire de la transaction doit utiliser (et signer avec) afin de dépenser le montant dans le futur.`}</p>
    <h2>{`Signature de`}</h2>
    <p>{`Dans le système Zenotta, afin de lier le `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` de la transaction en cours au `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` de la précédente, l'utilisateur doit signer le `}<strong parentName="p">{`hash du `}<inlineCode parentName="strong">{`OutPoint`}</inlineCode></strong>{` référençant ce `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`. De cette façon, il devient impossible de dépenser un montant de `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` sans`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`(A)`}</strong>{` Le `}<inlineCode parentName="li">{`OutPoint`}</inlineCode>{` référençant un `}<inlineCode parentName="li">{`TxOut`}</inlineCode>{` spécifique`}</li>
      <li parentName="ul"><strong parentName="li">{`(B)`}</strong>{` L'utilisateur possédant la clé privée correspondant à la clé publique du `}<inlineCode parentName="li">{`TxOut`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`(C)`}</strong>{` Pouvoir signer avec la clé privée pour dépenser les jetons.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;