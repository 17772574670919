
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nœud Mempool`}</h1>
    <p>{`Le nœud Mempool est la moitié du très important équilibre des pouvoirs dans le réseau Zenotta. Si le minage entièrement distribué, comme c'est le cas pour le bitcoin, est idéal pour la sécurité, il est inadéquat pour d'autres facteurs tels que la vitesse. Zenotta a donc réalisé qu'un bon compromis consistait en un anneau de nœuds Mempool décentralisés pour faciliter les transactions, les nœuds Miner publics, entièrement distribués, étant toujours responsables de l'extraction des blocs qui les contiennent et de l'apport de données au processus de décision équitable et vérifiable pour la sélection des transactions.`}</p>
    <h2>{`L'équilibre des pouvoirs`}</h2>
    <p>{`Il est important de considérer comment un anneau de nœuds Mempool sous le contrôle direct de Zenotta peut affecter la sécurité et la confiance dans le réseau. En faisant en sorte que les nœuds Mempool créent des blocs et que les nœuds Miner les extraient, nous garantissons l'équilibre du contrôle ; retirez l'un des deux ensembles de nœuds du système et tout s'écroule. Il y a donc un équilibre à la romaine entre les nœuds Mempool de type Sénat et les nœuds Miner de type Public.`}</p>
    <h2>{`Structure des nœuds Mempool`}</h2>
    <p>{`Le nœud Mempool est composé de :`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolNode`}</inlineCode></strong>{` la structure des données`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolInterface`}</inlineCode></strong>{` un trait de caractère pour de nombreuses méthodes tournées vers l'extérieur`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolRequest`}</inlineCode></strong>{` un ensemble de demandes que le nœud Mempool peut faire et recevoir`}</li>
    </ul>
    <p>{`Il existe un nombre limité de nœuds Mempool, tous situés auprès d'une série de partenaires de niveau entreprise et collectant des jetons pour le traitement des transactions et la validation des blocs.`}</p>
    <h2>{`Construire des blocs`}</h2>
    <p>{`A chaque tour, la responsabilité de la construction des blocs est partagée par tous les noeuds de l'anneau Mempool. Chaque noeud Mempool a un pool de transactions personnel (l'attribut `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` dans le `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) qu'il collecte auprès des utilisateurs. Régulièrement, ils consensualiseront par des transactions RAFT à partir de ce `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{`, et formeront le `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{` partagé par tous. C'est à partir de ce pool consensuel que les nouveaux blocs sont construits.`}</p>
    <p>{`Lorsqu'on reçoit l'information du bloc précédent stockée par les StorageNodes, et qu'une majorité de votes pour cet événement est consensuelle, un bloc est généré à partir des transactions déjà consensuelles dans `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`.
L'ordre des transactions envoyées et le nombre de transactions dans le bloc appartenant à un nœud Mempool particulier ne sont pas importants.`}</p>
    <p>{`Un bloc sera généré même s'il ne contient aucune transaction. Si trop de transactions sont disponibles dans `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`, seul un sous-ensemble sera utilisé pour ce bloc, et le reste ira dans un des prochains blocs. Tous les noeuds auront généré un bloc identique à partir des informations consensuelles et notifieront les mineurs de leur partition pour qu'ils commencent à miner.`}</p>
    <h2>{`Nœuds Mempool et Nœuds Miner`}</h2>
    <p>{`Une fois qu'un bloc est généré, pendant qu'un bloc est extrait et stocké, les nœuds d'extraction envoient des demandes à tous les nœuds Mempool pour participer au prochain tour d'extraction du bloc. Ceci est fait parce que, à chaque tour, ce n'est pas l'ensemble du réseau qui extrait les blocs (contrairement à Bitcoin). Au lieu de cela, le protocole du réseau Zenotta ne permet qu'à des `}<em parentName="p">{`partitions`}</em>{` ou sous-ensembles du réseau de miner à un moment donné, et chaque nœud Mempool a sa propre partition de mineurs. Cette liste de partition (stockée dans la `}<inlineCode parentName="p">{`partition_list`}</inlineCode>{` de `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) change à chaque tour, permettant aux nœuds de mineurs une nouvelle opportunité de miner à chaque tour.`}</p>
    <p>{`Une fois que la liste de partition d'un nœud Mempool est pleine, les nœuds Miner de ce nœud Mempool sont considérés comme sélectionnés, et lorsque le nouveau bloc est prêt à être miné, le nœud Mempool l'envoie aux nœuds Miner qui sont entrés dans la liste de partition. Les mineurs qui trouvent une preuve de travail valide pour le bloc dans le temps du tour de minage sont considérés comme les gagnants, parmi lesquels un mineur est sélectionné au hasard par l'`}{`[UNiCORN]`}{` (licornes), et envoie son bloc au nœud Mempool pour validation et stockage. Pour plus de détails sur le fonctionnement des mineurs, vous pouvez `}{`[lire cette page wiki]`}{` (Miner-Node).`}</p>
    <h2>{`Nœuds Mempool et nœuds de stockage`}</h2>
    <p>{`Une fois que le nœud Mempool a reçu un bloc valide, il déclare le gagnant pour sa partition et envoie le bloc au nœud de stockage associé pour un stockage à long terme. Après ce point, tous les attributs liés au tour dans le nœud Mempool sont effacés et vidés et le prochain tour peut commencer.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;