
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`The Zenotta Network Protocol`}</h1>
    <p>{`The Zenotta Network Protocol (ZNP) is responsible for all the network-related code in the Zenotta system. It is the repo responsible for communication between nodes in the mining network, as well as integration with the blockchain data structure found in the `}<inlineCode parentName="p">{`NAOM`}</inlineCode>{` repo. The following are the table of contents for further documentation below, which will fill you in on the architecture of the project as a whole:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "node-types"
        }}>{`Node Types`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mempool-node"
        }}>{`Mempool Node`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "miner-node"
        }}>{`Miner Node`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "storage-node"
        }}>{`Storage Node`}</a>
        {/* - [The Wallet](wallet) */}
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "unicorns"
        }}>{`UNiCORNs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "transactions"
        }}>{`Transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "block-mining"
        }}>{`Block Mining Process`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "dual-double-entry"
        }}>{`Dual Double Entry Transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "shuriken-auditing"
        }}>{`Shuriken Auditing`}</a></li>
    </ul>
    <h1>{`Getting Started`}</h1>
    <p>{`To start, it is important to understand how the ZNP fits into the greater Zenotta system. The Zenotta system consists primarily of 3 major components:`}</p>
    <ul>
      <li parentName="ul">{`The Zenotta Network Protocol (ZNP)`}</li>
      <li parentName="ul">{`The Dual Double Entry (DDE) Blockchain`}</li>
      <li parentName="ul">{`The Zenotta Data Protocol (ZDP)`}</li>
    </ul>
    <p>{`For now we will only consider the first two items on this list. The `}<strong parentName="p">{`ZNP`}</strong>{` sends and receives messages that are passed between different node types, and also relays storage to the `}<strong parentName="p">{`DDE Blockchain`}</strong>{` in order to keep a record of blockchain history.`}</p>
    <h2>{`System Architecture`}</h2>
    <p>{`The Zenotta system architecture mirrors much of what you'll find in the Bitcoin architecture, as our blockchain is based on Proof-of-Work (PoW) consensus. Unlike traditional Bitcoin, however, our blockchain is capable of faster transaction speeds (leading to faster clearance periods) and our PoW system is light and more inclusive (but more on this later).`}</p>
    <p>{`The other major difference between Zenotta and traditional blockchains is the introduction of the dual double entry (DDE) system. Unlike traditional blockchains, our blockchain can record both the movement of tokens `}<em parentName="p">{`and`}</em>{` the movement of assets for those tokens. Thanks to our `}<strong parentName="p">{`ZDP`}</strong>{` or Zenotta Data Protocol, those assets can be your ownable and transactable data assets.`}</p>
    <p>{`To facilitate this, the Zenotta architecture makes use of different types of nodes in its network. You can find out more about the different node types `}<a parentName="p" {...{
        "href": "node-types"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Code Design`}</h2>
    <p>{`We have a specific design philosophy regarding the code written at Zenotta. The ideal is to have code which will be easily reusable, but most importantly easily understandable. We treat our code like a client-server relationship, where the code is the server and you, the human user, are the client. To that end we have adopted the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multitier_architecture#Three-tier_architecture"
      }}>{`Three Tier Architecture`}</a>{` approach popularised by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_J._Donovan"
      }}>{`John J. Donovan`}</a>{`, which in brief consists of the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Presentation Tier`}</strong>{`: To be treated like the user interface. At this level there should be the highest abstractions for any module we write, consisting of a few easy-to-use functions that can be implemented in an API for general consumption.
`}<strong parentName="p"><em parentName="strong">{`Developer Level: Beginner`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Logic Tier`}</strong>{`: This is one level down from the presentation tier, where the code is more complex but is capable of more powerful functions. Logic and business operations can be found at this level, helping to facilitate the higher-level abstractions in the presentation tier. `}<strong parentName="p"><em parentName="strong">{`Developer Level: Intermediate`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Data Tier`}</strong>{`: This is the lowest level of code architecture, and as such is the most complex. Functions and operations at this level will directly interact with system-level operations such as I/O and network connection handling, as well as direct interaction with the blockchain. `}<strong parentName="p"><em parentName="strong">{`Developer Level: Advanced`}</em></strong></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;