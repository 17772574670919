
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Shadowmourne from '../img/909c07898b4f5751941461e6fbe98473/Shadowmourne-the-runeblade.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Transactions en double entrée`}</h1>
    <p>{`Actuellement, les paiements en bitcoins sont considérés comme de la "charité" : Alice paie 1 BTC à Bob pour une voiture de moyenne gamme, mais le registre Bitcoin ne peut montrer de manière fiable qu'Alice a donné 1 BTC à Bob par charité. La blockchain de Zenotta doit être capable de refléter le paiement par jeton ainsi que le bien pour lequel le paiement a été effectué. À cette fin, il faut une transaction `}<em parentName="p">{`double entrée`}</em>{`, ou DDE. `}</p>
    <h2>{`Comment fonctionne une transaction DDE`}</h2>
    <p>{`Bob possède Shadowmourne, une arme à hache impressionnante utilisée dans le jeu World of Warcraft (WoW), qu'il souhaite vendre via la blockchain Zenotta :`}</p>
    <img src={Shadowmourne} alt="shadowmourne-the-runeblade" />
    <small>Shadowmourne &copy; Activision Blizzard, Inc.</small>
    <p>{`Alice est nouvelle dans le jeu, mais elle veut avoir une longueur d'avance sur ses concurrents et décide donc d'acheter l'objet en jeu. Dans le cas où Alice paie 100 ZNT à Bob pour la hache, la séquence suivante se produit :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Alice crée une transaction qui envoie 100 ZNT à Bob, et attend le schéma d'encodage de sa hache WoW.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob crée une transaction qui envoie le schéma de codage de la hache WoW à Alice et attend 100 ZNT en retour.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice et Bob échangent leurs moitiés respectives d'un identifiant appelé DRUID (Double Resolution Unique ID).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice et Bob concaténent chacun la moitié de l'autre partie à la leur afin de créer le DRUID final, et attachent le DRUID à leurs propres transactions respectives en tant qu'attribut (`}<inlineCode parentName="p">{`druid : Option<String>`}</inlineCode>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice envoie sa transaction DRUID au noeud Mempool pour vérification. Puisque Bob est actuellement hors ligne et n'a pas envoyé sa transaction DRUID correspondante, le noeud Mempool ajoute simplement la transaction d'Alice à son pool DRUID en tant que droplet DRUID (`}<inlineCode parentName="p">{`druid_pool : BTreeMap<String, DruidDroplet>`}</inlineCode>{`) et attend que Bob envoie la sienne.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Une fois que Bob a envoyé sa transaction, le noeud Mempool la fait correspondre à celle d'Alice en vérifiant le DRUID correspondant, enlève le droplet de transaction d'Alice du pool de DRUID, et traite chaque transaction séparément avant de les ajouter au bloc suivant.`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`NOTE:`}</strong>{` Même si les deux transactions sont traitées séparément, elles conservent toutes deux la valeur DRUID. Cela permet un audit de la transaction à un stade ultérieur.`}</p>
    <h2>{`Paiements basés sur des reçus`}</h2>
    <p>{`En plus de permettre des échanges vérifiables de jetons contre des actifs de données, la transaction DDE permet également des paiements basés sur des reçus. Un paiement basé sur un reçu est un paiement dans lequel Alice veut donner à Bob 100 ZNT comme cadeau de charité, mais exige que Bob approuve le cadeau avant qu'il ne soit traité dans un bloc.`}</p>
    <p>{`Pour faciliter cette opération, Alice doit simplement laisser le champ "actif attendu" de sa transaction vide (ce qui signifie qu'elle n'attend rien en retour). Bob fait de même de son côté, en demandant 100 ZNT mais en laissant le champ "actif à vendre" vide (signifiant qu'il n'a pas l'intention de fournir quelque chose de valeur en retour). Le processus est le même que précédemment, Alice et Bob soumettant leurs transactions respectives avec le même DRUID et les faisant traiter par le nœud Mempool.`}</p>
    <h2>{`Considérations et améliorations futures`}</h2>
    <p>{`Pour que les paiements basés sur des reçus soient vraiment efficaces, le contenu devra être signé avec la clé privée de chaque utilisateur avant d'être soumis au nœud Mempool. Cela signifie qu'à terme, le champ de données devra contenir une certaine valeur pour les paiements basés sur des reçus, mais celle-ci peut être fixée à une valeur qui ne nécessite pas d'événement de création (comme les données normales).`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;