
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Modèle computationnel`}</h1>
    <p>{`Notre `}<em parentName="p">{`modèle informatique`}</em>{` décrit comment les transactions programmables et les données programmables se comportent au niveau inférieur. Il se compose de plusieurs `}<em parentName="p">{`langages de scripts`}</em>{` qui permettent aux développeurs d'utiliser pleinement les capacités de la blockchain Zenotta.`}</p>
    <p>{`Par rapport au `}<em parentName="p">{`modèle symbolique`}</em>{`, le modèle computationnel ne vérifie pas ou n'exécute pas strictement les `}<em parentName="p">{`contrats intelligents`}</em>{` qui sont plus complexes et peuvent être constitués de plusieurs transactions et protocoles. En revanche, l'exécution des scripts de transaction est autonome et atomique, ce qui signifie que les scripts sont toujours exécutés dans leur intégralité et ne dépendent pas de facteurs externes.`}</p>
    <h2>{`Transactions`}</h2>
    <p>{`La blockchain Zenotta suit le modèle UTxO. De manière similaire au grand livre distribué de Bitcoin, la propriété des données et des jetons est déduite des sorties de transactions `}<em parentName="p">{`non dépensées`}</em>{`, et ces enregistrements sont permanents et immuables.`}</p>
    <p>{`En créant, signant et envoyant des transactions, les utilisateurs peuvent transférer la propriété des jetons et des données. Le langage de script Zenotta peut être utilisé pour définir les conditions dans lesquelles une transaction est dépensable.`}</p>
    <p>{`Notre langage de script est basé sur le `}{`[Bitcoin Script]`}{` (`}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Script"
      }}>{`https://en.bitcoin.it/wiki/Script`}</a>{`) et utilise une approche de programmation similaire au `}{`[Forth]`}{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Forth_(programming_language)"
      }}>{`https://en.wikipedia.org/wiki/Forth_(programming_language)`}</a>{`), basée sur la pile. Son code octet est exécuté par les mineurs pendant la vérification des transactions sur une machine virtuelle à pile. Chaque nœud du réseau Zenotta exécute cette machine virtuelle.`}</p>
    <p>{`Nous fournissons des fonctionnalités supplémentaires en plus du Bitcoin Script. Par exemple, grâce à notre blockchain basée sur UTxO, les utilisateurs peuvent utiliser un générateur de nombres aléatoires sécurisé dans leurs scripts. Cela ouvre la voie à des Smart Contracts avancés et à des cas d'utilisation tels que des loteries ou des jeux équitables.`}</p>
    <p>{`Le langage n'est volontairement pas complet au sens de Turing. Cette approche facilite la vérification formelle des scripts pour s'assurer que les transactions ne deviennent pas inutilisables et qu'il n'y a pas d'instructions sournoises qui rendraient un contrat injuste.`}</p>
    <p>{`Le langage de script de transaction est utilisé comme l'une des cibles du compilateur pour notre langage de plus haut niveau, `}<em parentName="p">{`ZLang`}</em>{`, qui compose des scénarios plus complexes à partir de scripts plus simples.`}</p>
    <p>{`Vous trouverez plus de détails dans un RFC qui sera publié prochainement sur zenotta.io.`}</p>
    <h2>{`Données intelligentes`}</h2>
    <p>{`En option, les utilisateurs peuvent utiliser un autre langage de script -- `}{`[`}<em parentName="p">{`PowerShell`}</em>{`]`}{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PowerShell"
      }}>{`https://en.wikipedia.org/wiki/PowerShell`}</a>{`) -- pour gérer les données intelligentes. PowerShell est largement utilisé dans l'industrie pour automatiser diverses tâches d'administration des systèmes et il est bien adapté à la gestion automatique des fichiers de données intelligentes dans le cadre des contrats intelligents. Les scripts PowerShell sont stockés dans le cadre des `}<em parentName="p">{`smart data`}</em>{`, ce qui les rend inextricablement liés au fichier, et si nécessaire, ils peuvent fournir des API supplémentaires aux développeurs qui construisent sur la plateforme Zenotta.`}</p>
    <p><em parentName="p">{`PowerShell`}</em>{` est également l'une des cibles de notre langage de haut niveau `}<em parentName="p">{`ZLang`}</em>{`, ce qui signifie que dans la plupart des cas, vous n'aurez pas à écrire ces scripts à la main -- les scripts seront automatiquement générés et vérifiés par notre compilateur.`}</p>
    <p>{`Notre équipe travaille à l'adaptation de `}<em parentName="p">{`PowerShell`}</em>{` pour travailler dans des environnements plus restrictifs, par exemple, la blockchain a une capacité de stockage limitée, nous avons donc écrit un document de proposition qui décrit comment les scripts PowerShell peuvent être sérialisés vers le bas dans une représentation binaire de l'arbre syntaxique abstrait. Vous pouvez trouver ce document `}<a parentName="p" {...{
        "href": "https://hackmd.io/Aas4HLKFRKG5PPyJd-M3Dw"
      }}>{`ici`}</a>{`.`}</p>
    <p>{`Les considérations de sécurité sont également très importantes, tant pour Zenotta que pour PowerShell. Les scripts sont exécutés dans un environnement virtualisé et ont une vue limitée du système hôte.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;