import { Route } from 'react-router-dom';
import { NavRoutes } from 'utils/interfaces';
import { ContentType } from './enums';
import JsxViewer from 'components/layout/JsxViewer/JsxViewer';
import MdxViewer from 'components/layout/MdxViewer/MdxViewer';
import PdfViewer from 'components/layout/PdfViewer/PdfViewer';
import IpynbViewer from 'components/layout/IpynbViewer/IpynbViewer';
import NotFound from 'components/pages/NotFound/NotFound';
import { i18n } from 'i18n';

export function loadLanguage(lang: string) {
  if (lang !== null) {
    i18n.changeLanguage(lang);
  }
}

export const generateNavRoutes = (routes: NavRoutes[], basePath: string, lang: string) => {
  let generatedRoutes: any = [];
  generateRoutes(routes, basePath, generatedRoutes, lang);
  generatedRoutes.push(
    <Route key={'notFound'} path={'*'} exact>
      <NotFound />
    </Route>
  );
  return generatedRoutes;
};

const generateRoutes = (routes: NavRoutes[], basePath: string, generatedRoutes: any[], lang: string) => {
  routes.forEach((route: NavRoutes) => {
    if (route.sub) {
      generateRoutes(route.sub, basePath + route.path, generatedRoutes, lang);
    } else if (!route.disabled && route.path !== '') {
      generatedRoutes.push(
        <Route key={route.path} path={`${basePath}${route.path}`} exact>
          {route.contentType && setContentType(route.contentType, route.Component, lang, route.link, route.toc)}
        </Route>
      );
    }
  });
  return generatedRoutes;
};

const setContentType = (contentType: ContentType, content: any, lang: string, link?: string, toc?: boolean) => {
  let result: any;
  let contentChecked = Object.keys(content).length > 1 && contentType != ContentType.Ipynb ? content[lang] : content


  switch (contentType) {
    case ContentType.Jsx:
      result = <JsxViewer content={contentChecked} toc={toc ? true : false} />;
      break;

    case ContentType.Mdx:
      result = <MdxViewer content={contentChecked} toc={toc ? true : false} />;
      break;

    case ContentType.Pdf:
      result = <PdfViewer file={contentChecked} filePath={contentChecked} />;
      break;

    case ContentType.Ipynb:
      result = <IpynbViewer content={contentChecked} link={link ? link : ''} />;
      break;

    default:
      result = <p>Unable to load content</p>;
      break;
  }
  return result;
};