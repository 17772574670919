import styles from './Footer.module.scss'; // Personnalised styles for this component
import { useTranslation } from 'react-i18next';
import Link from 'components/layout/Link/Link';

// icons
import Zt from 'static/img/zenotta_logo_white.svg';
import Ln from 'static/img/linkedin.svg';
import Fb from 'static/img/facebook.svg';
import Yt from 'static/img/youtube.svg';
import Tw from 'static/img/twitter.svg';
import Di from 'static/img/discord.svg';
import Te from 'static/img/telegram.svg';

const Footer = () => {
  let { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.col}>
          <span className={styles.title}>{t('Research')}</span>
          <Link to="/research">{t('Home')}</Link>
          <Link to="/research/philosophy/peer-to-peer-electronic-trade">{t('Peer-to-peer electronic trade')}</Link>
          <Link to="/research/economics/balances">{t('Balances')}</Link>
          {/* <Link to="#">{t('Law')}</Link> */}
          <Link to="/research/computer-science/random-reduction-fault-tolerance">{t('Random reduction of miners')}</Link>
        </div>
        <div className={styles.col}>
          <span className={styles.title}>{t('Developer Documentation')}</span>
          <Link to="/developer-documentation">{t('Home')}</Link>
          <Link to="/developer-documentation/getting-started">{t('Getting Started')}</Link>
          <Link to="/developer-documentation/tutorials/send-and-receive-payments">{t('Tutorials')}</Link>
          <Link to="/developer-documentation/computational/znp/home">{t('Zenotta Network Protocol')}</Link>
        </div>
        <div className={styles.col}>
          <span className={styles.title}>{t('Help')}</span>
          <Link to="https://www.zenotta.xyz/en/home.html#contact" out={true}>{t('Contact us')}</Link>
          <Link to="https://www.zenotta.xyz/en/faqs.html" out={true}>{t('Support')}</Link>
          <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
          <Link to="/imprint">{t("Imprint")}</Link>
          <a href="mailto:dev@zenotta.com?subject=Zenotta io - Feedback">{t("Report a bug")}</a>
        </div>
        <div className={`${styles.col} ${styles.social}`}>
          <span className={styles.title}>{t('Social')}</span>
          <a className={styles.icon} href="https://t.me/zenotta_ag" target={'_blank'} rel='noreferrer'>
            <img src={Te} alt="Telegram" />
          </a>
          <a className={styles.icon} href="https://discord.gg/tW3Ff6DnQB" target={'_blank'} rel='noreferrer'>
            <img src={Di} alt="Discord" />
          </a>
          <a className={styles.icon} href="https://www.linkedin.com/company/zenotta/" target={'_blank'} rel='noreferrer'>
            <img src={Ln} alt="LinkedIn" />
          </a>
          <a className={styles.icon} href="https://twitter.com/zenotta_ag" target={'_blank'} rel='noreferrer'>
            <img src={Tw} alt="Twiter" />
          </a>
          <a className={styles.icon} href="https://www.youtube.com/channel/UCKmU1l9mRrnGC0IQFlixUDw" target={'_blank'} rel='noreferrer'>
            <img src={Yt} alt="Youtube" />
          </a>
          <a className={styles.icon} href="https://www.facebook.com/zenotta.xyz" target={'_blank'} rel='noreferrer'>
            <img src={Fb} alt="FaceBook" />
          </a>
          <a className={styles.link} href="https://linktr.ee/zenotta" target={'_blank'} rel='noreferrer'>{t("More")}</a>
        </div>
      </div>
      <div className={styles.bottom}>
        <img src={Zt} alt="Zenotta logo" />
        <span>{'Copyright © 2022 Zenotta AG'}</span>
      </div>
    </div>
  );
};

export default Footer;
