import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export type Language = 
  | "en"
  | "de"
  | "fr"
  | "it";

i18n
  .use(Backend) 
  .use(initReactI18next) 
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".", // we use content as keys
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      transEmptyNodeValue: "",
      useSuspense: false,
    },
    backend: {
      loadPath: "/i18n/{{lng}}/{{ns}}.json",
    },
  });

const langs: any = {
    'en': "English",
    'de': "Deutsch",
    "fr": "Français",
    "it": "Italiano"
}

export { i18n, langs };