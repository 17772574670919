
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import ShurikenAudit from './img/d06cd07ea832dcd43423bf1b9c6b2311/shuriken-audit.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Shuriken Auditing`}</h1>
    <p>{`The process of asset creation in the ZNP system works in much the same way as a coinbase transaction: users construct an asset creation transaction, spawning a value from nothing, and submit it to the `}<a parentName="p" {...{
        "href": "Mempool-Node"
      }}>{`Mempool`}</a>{` ring for inclusion into the blockchain.`}</p>
    <p>{`The problem with this process is that it is "free", costing neither material resource such as tokens nor computing power to perform. This, in turn, leads to potential problems for the network in general, and in particular, DDoS attacks. In order to solve this, the ZNP introduces a requirement for users hoping to create new receipt or data asset transactions.`}</p>
    <h2>{`Auditing, a Requirement to Create New Assets`}</h2>
    <p>{`The requirement to create new assets is for the creator to audit the last `}<em parentName="p">{`n`}</em>{` blocks in the blockchain for correctness, and in particular we want to audit whether a given entry in a `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` is actually part of the seed for that Unicorn. In this way we can check the validity of the chain as a whole and provide a way to mitigate DDoS in asset creation in a single step.`}</p>
    <h2>{`Process Flow`}</h2>
    <p>{`As an example of the process flow, we will imagine that Alice wants to create 5 receipts. The way she does so is through the following process:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{` ring sets up a presence table once per block round, which can be structured as an array or vector of boolean values.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For each entry in the presence table, the Storage RAFT must pick one UNiCORN seed hash and one full UNiCORN from the blockchain. Some of these entries will need to be chosen randomly from different parts of the blockchain (e.g. UNiCORN entry 1 from block 5) and the rest will need to be an entry from the same block (e.g. UNiCORN entry 3 from block 3). The former will be `}<inlineCode parentName="p">{`false`}</inlineCode>{` (not present in the UNiCORN) and the latter will be `}<inlineCode parentName="p">{`true`}</inlineCode>{` (is present) and for each case this boolean value will be inserted into the presence table.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The presence table, shared amongst the Storage RAFT, is now ready. When Alice wants to create her new receipts, she sends the creation transaction to a random Storage node and receives in return an entry in the presence table, along with the associated data required to verify whether it is present or not (this will include a difficulty for a light PoW).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice must verify whether the entry should be `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`, and then performs her PoW on this boolean value and the hash of her creation transaction. She then sends the final result back to the Storage node.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If Alice's verification is in agreement with the Storage nodes, a confirmation value is incremented for that presence table entry by the Storage RAFT and Alice's transaction is sent to the Mempool node.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If Alice's verification is `}<strong parentName="p">{`not`}</strong>{` in agreement, the Storage node (A) will send the entry on to Bob who is also wanting to create a receipt and the disagreement will be marked in the RAFT.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob will send his verification on to the next available Storage node (B). If he is also in disagreement, the process in step 6 repeats but the difficulty that the Storage node provides increases by a factor of 10.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If 3 disagreements are discovered, the creation transaction for all 3 Users are immediately sent to the Mempool ring and a Storage node will manually process the entry's validity.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If it is found that there really is an inconsistency, some kind of halting process will need to be enacted on the Mempool ring.`}</p>
      </li>
    </ol>
    <h2>{`Diagram`}</h2>
    <p>{`The diagram below shows a typical shuriken audit process:`}</p>
    <img src={ShurikenAudit} alt="shuriken-audit" />
    <p>{`The table of values at the top of the diagram is the agreed upon presence table for the block round, where the number indicates index and the values "T" and "F" indicate `}<inlineCode parentName="p">{`true`}</inlineCode>{` and `}<inlineCode parentName="p">{`false`}</inlineCode>{` respectively. The highlighted path shows a scenario in which the Storage nodes send a User node a believed true entry, which the User then determines to actually be false.`}</p>
    <p>{`The Storage nodes then need to repeat the process of sending on to successive User nodes until the third receipt. In this case all 3 were assessed as false, and so the fourth Storage node needs to manually perform a check on this specific entry to determine the predicate.`}</p>
    <p>{`The faded scenarios shows cases where User nodes agree with the belief of the Storage nodes. The resulting shape of the structure is shuriken or star-like.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;