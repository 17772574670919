
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Shadowmourne from '../img/909c07898b4f5751941461e6fbe98473/Shadowmourne-the-runeblade.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Transazioni in partita doppia`}</h1>
    <p>{`Attualmente, i pagamenti in Bitcoin sono considerati "beneficenza": Alice paga a Bob 1 BTC per un'auto di fascia media, ma il registro Bitcoin non può dimostrare in modo affidabile che Alice ha dato a Bob 1 BTC per beneficenza. La blockchain di Zenotta deve essere in grado di riflettere il pagamento del token e il bene per il quale è stato effettuato il pagamento. A questo scopo, è necessaria una transazione a doppia entrata (DDE). `}</p>
    <h2>{`Come funziona una transazione DDE`}</h2>
    <p>{`Bob possiede la Shadowmourne, un'impressionante ascia utilizzata nel gioco World of Warcraft (WoW), che vuole vendere tramite la blockchain Zenotta:`}</p>
    <img src={Shadowmourne} alt="shadowmourne-the-runeblade" />
    <small>Shadowmourne &copy; Activision Blizzard, Inc.</small>
    <p>{`Alice è nuova nel gioco, ma vuole avere un vantaggio sulla concorrenza e decide di acquistare l'oggetto di gioco. Se Alice paga a Bob 100 ZNT per l'ascia, si verifica la seguente sequenza:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Alice crea una transazione che invia 100 ZNT a Bob e si aspetta lo schema di codifica della sua ascia WoW.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob crea una transazione che invia lo schema di codifica dell'ascia WoW ad Alice e si aspetta 100 ZNT in cambio`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice e Bob si scambiano le rispettive metà di un identificatore chiamato DRUID (Double Resolution Unique ID).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice e Bob concatenano la metà dell'altra parte alla propria per creare il DRUID finale e lo allegano alle rispettive transazioni come attributo (`}<inlineCode parentName="p">{`druid: Option<String>`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice invia la sua transazione DRUID al nodo Mempool per la verifica. Poiché Bob è attualmente offline e non ha inviato la sua transazione DRUID corrispondente, il nodo Mempool aggiunge semplicemente la transazione di Alice al suo pool DRUID come droplet DRUID (`}<inlineCode parentName="p">{`druid_pool: BTreeMap<Stringa, DruidDroplet>`}</inlineCode>{`) e attende che Bob invii la sua transazione.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Una volta che Bob ha inviato la sua transazione, il nodo Mempool la confronta con quella di Alice controllando il DRUID corrispondente, rimuove il droplet della transazione di Alice dal pool DRUID ed elabora ogni transazione separatamente prima di aggiungerla al blocco successivo.`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`NOTA:`}</strong>{` Anche se le due transazioni vengono elaborate separatamente, entrambe mantengono il valore DRUID. Ciò consente di verificare la transazione in una fase successiva.`}</p>
    <h2>{`Pagamenti basati su ricevute`}</h2>
    <p>{`Oltre a consentire scambi verificabili di token per asset di dati, la transazione DDE consente anche pagamenti basati su ricevute. Un pagamento basato su ricevuta è quello in cui Alice vuole dare a Bob 100 ZNT come dono di beneficenza, ma richiede che Bob approvi il dono prima che venga elaborato in un blocco.`}</p>
    <p>{`Per facilitare questa operazione, Alice deve semplicemente lasciare vuoto il campo "expected asset" della sua transazione (a significare che non si aspetta nulla in cambio). Bob fa lo stesso da parte sua, richiedendo 100 ZNT ma lasciando vuoto il campo dell'asset da vendere (a significare che non intende fornire nulla di valore in cambio). Il processo è lo stesso di prima: sia Alice che Bob inviano le rispettive transazioni con lo stesso DRUID e le fanno elaborare dal nodo Mempool.`}</p>
    <h2>{`Considerazioni e miglioramenti futuri`}</h2>
    <p>{`Affinché i pagamenti basati sulle ricevute siano veramente efficaci, il contenuto dovrà essere firmato con la chiave privata di ogni utente prima di essere inviato al nodo Mempool. Ciò significa che alla fine dovrà esserci un valore nel campo dati per i pagamenti basati sulle ricevute, ma questo può essere fissato a un valore che non richieda un evento di creazione (come fanno le normali risorse di dati).`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;