import * as ace from 'ace-builds/src-noconflict/ace';

ace.define("ace/theme/zenotta-light", [], function (require, exports, module) {

    exports.isDark = false;
    exports.cssClass = "ace-zenotta-light";     /*eslint no-multi-str: 0*/
    exports.cssText = ".ace-zenotta-light .ace_gutter {\
background: #f0f3f6;\
color: rgb(136,163,180)\
}\
.ace-zenotta-light .ace_print-margin {\
width: 1px;\
background: #e8e8e8\
}\
.ace-zenotta-light {\
background-color: #f0f3f6;\
color: #205372\
}\
.ace-zenotta-light .ace_cursor {\
color: #545960\
}\
.ace-zenotta-light .ace_marker-layer .ace_selection {\
background: #E0E6E9\
}\
.ace-zenotta-light.ace_multiselect .ace_selection.ace_start {\
box-shadow: 0 0 3px 0px #f0f3f6;\
border-radius: 2px\
}\
.ace-zenotta-light .ace_marker-layer .ace_step {\
background: rgb(198, 219, 174)\
}\
.ace-zenotta-light .ace_marker-layer .ace_bracket {\
margin: -1px 0 0 -1px;\
border: 1px solid rgba(255, 0, 0, 0.0)\
}\
.ace-zenotta-light .ace_marker-layer .ace_active-line {\
background: rgba(255, 255, 255, 0.14)\
}\
.ace-zenotta-light .ace_gutter-active-line {\
background-color: rgba(255, 255, 255, 0.14)\
}\
.ace-zenotta-light .ace_marker-layer .ace_selected-word {\
border: 1px solid #E0E6E9\
}\
.ace-zenotta-light .ace_fold {\
background-color: #fc807d;\
border-color: #205372\
}\
.ace-zenotta-light .ace_keyword,\
.ace-zenotta-light .ace_storage {\
color: #CC7833\
}\
.ace-zenotta-light .ace_constant {\
color: #6D9CBE\
}\
.ace-zenotta-light .ace_constant.ace_language,\
.ace-zenotta-light .ace_support.ace_type {\
color: #6E9CBE\
}\
.ace-zenotta-light .ace_constant.ace_numeric,\
.ace-zenotta-light .ace_support.ace_constant {\
color: #6D9CBE\
}\
.ace-zenotta-light .ace_constant.ace_character.ace_escape {\
color: #519F50\
}\
.ace-zenotta-light .ace_support.ace_function {\
color: #DA4939\
}\
.ace-zenotta-light .ace_invalid {\
color: #FFFFFF;\
background-color: #990000\
}\
.ace-zenotta-light .ace_string {\
font-style: italic;\
color: #278f89\
}\
.ace-zenotta-light .ace_comment {\
font-style: italic;\
color: #BC9458\
}\
.ace-zenotta-light .ace_entity.ace_name.ace_function,\
.ace-zenotta-light .ace_variable {\
color: #fc807d\
}\
.ace-zenotta-light .ace_variable.ace_language {\
font-style: italic;\
color: #a31515\
}\
.ace-zenotta-light .ace_entity.ace_name.ace_tag,\
.ace-zenotta-light .ace_entity.ace_other.ace_attribute-name,\
.ace-zenotta-light .ace_meta.ace_tag {\
color: #c51713\
}\
.ace-zenotta-light .ace_entity.ace_name {\
color: #737a82\
}";

    var dom = require("../lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
}); (function () {
    ace.require(["ace/theme/zenotta-light"], function (m) {
        if (typeof module == "object" && typeof exports == "object" && module) {
            module.exports = m;
        }
    });
})();
