import { combineReducers } from "redux";
import navItemsReducer from "./navItems";
import basePathReducer from "./basePath";
import tocItemsReducer from "./tocItems";
import activeIdReducer from "./activeId";
import themeReducer from "./theme";
import languageReducer from "./language";

const allReducers = combineReducers({
    navItems: navItemsReducer,
    basePath: basePathReducer,
    tocItems: tocItemsReducer,
    activeId: activeIdReducer,
    theme: themeReducer,
    language: languageReducer
});

export default allReducers;