
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Berechnungsmodell`}</h1>
    <p>{`Unser `}<em parentName="p">{`Rechenmodell`}</em>{` beschreibt, wie sich programmierbare Transaktionen und programmierbare Daten auf der unteren Ebene verhalten. Es besteht aus mehreren `}<em parentName="p">{`Skriptsprachen`}</em>{`, die es Entwicklern ermöglichen, die Fähigkeiten der Zenotta-Blockchain vollständig zu nutzen.`}</p>
    <p>{`Im Vergleich zum `}<em parentName="p">{`symbolischen Modell`}</em>{` ist das Berechnungsmodell nicht streng auf die Verifizierung oder Ausführung von `}<em parentName="p">{`Smart Contracts`}</em>{` ausgerichtet, die komplexer sind und aus mehreren Transaktionen und Protokollen bestehen können. Im Gegensatz dazu ist die Ausführung von Transaktionsskripten in sich geschlossen und atomar, was bedeutet, dass Skripte immer vollständig ausgeführt werden und nicht von externen Faktoren abhängen.`}</p>
    <h2>{`Transaktionen`}</h2>
    <p>{`Die Zenotta-Blockchain folgt dem UTxO-Modell. Ähnlich wie beim verteilten Ledger von Bitcoin wird das Eigentum an Daten und Token aus `}<em parentName="p">{`unverbrauchten`}</em>{` Transaktionsausgaben abgeleitet, und diese Aufzeichnungen sind dauerhaft und unveränderlich.`}</p>
    <p>{`Durch das Erstellen, Signieren und Senden von Transaktionen können Benutzer das Eigentum an Token und Daten übertragen. Die Zenotta-Skriptsprache kann verwendet werden, um Bedingungen festzulegen, unter denen eine Transaktion ausgegeben werden kann.`}</p>
    <p>{`Unsere Skriptsprache basiert auf `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Script"
      }}>{`Bitcoin Script`}</a>{` und verwendet einen ähnlichen `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Forth_(programming_language)"
      }}>{`Forth`}</a>{`-ähnlichen, stapelbasierten Programmieransatz. Der Byte-Code wird von den Minern während der Transaktionsprüfung auf einer virtuellen Stack-Maschine ausgeführt. Jeder Knoten im Zenotta-Netzwerk führt diese VM aus.`}</p>
    <p>{`Wir bieten zusätzliche Funktionen zum Bitcoin-Skript. Zum Beispiel können Benutzer einen sicheren Zufallszahlengenerator in ihre Skripte einbauen, was einzigartig für unsere UTxO-basierte Blockchain ist. Dies ebnet den Weg für fortgeschrittene Smart Contracts und Anwendungsfälle wie faire Lotterien oder Spiele.`}</p>
    <p>{`Die Sprache ist absichtlich nicht Turing-komplett. Dieser Ansatz macht es einfacher, Skripte formal zu verifizieren, um sicherzustellen, dass Transaktionen nicht unauszahlbar werden und dass es keine hinterhältigen Anweisungen gibt, die einen Vertrag unfair machen würden.`}</p>
    <p>{`Die Transaktionsskriptsprache wird als eines der Compilerziele für unsere höhere Sprache `}<em parentName="p">{`ZLang`}</em>{` verwendet, die komplexere Szenarien aus einfacheren Skripten komponiert.`}</p>
    <p>{`Weitere Details finden Sie in einem RFC, das in Kürze auf zenotta.io veröffentlicht wird.`}</p>
    <p>{`Übersetzt mit `}<a parentName="p" {...{
        "href": "http://www.DeepL.com/Translator"
      }}>{`www.DeepL.com/Translator`}</a>{` (kostenlose Version)`}</p>
    <h2>{`Intelligente Daten`}</h2>
    <p>{`Optional können Benutzer eine andere Skriptsprache - `}{`[`}<em parentName="p">{`PowerShell`}</em>{`]`}{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PowerShell"
      }}>{`https://en.wikipedia.org/wiki/PowerShell`}</a>{`) - für den Umgang mit intelligenten Daten verwenden. PowerShell ist in der Industrie weit verbreitet, um verschiedene Aufgaben in der Systemadministration zu automatisieren und eignet sich gut für die automatische Verwaltung von Smart Data-Dateien als Teil von Smart Contracts. PowerShell-Skripte werden als Teil von `}<em parentName="p">{`Smart Data`}</em>{` gespeichert, wodurch sie untrennbar mit der Datei verbunden sind. Bei Bedarf können sie zusätzliche APIs für Entwickler bereitstellen, die auf der Zenotta-Plattform aufbauen.`}</p>
    <p><em parentName="p">{`PowerShell`}</em>{` ist auch eines der Ziele für unsere Hochsprache `}<em parentName="p">{`ZLang`}</em>{`, was bedeutet, dass Sie diese Skripte in den meisten Fällen nicht von Hand schreiben müssen - die Skripte werden automatisch generiert und von unserem Compiler überprüft.`}</p>
    <p>{`Unser Team arbeitet daran, `}<em parentName="p">{`PowerShell`}</em>{` so anzupassen, dass sie auch in restriktiveren Umgebungen funktioniert, z. B. hat Blockchain eine begrenzte Speicherkapazität, daher haben wir ein Vorschlagsdokument geschrieben, das beschreibt, wie PowerShell-Skripte in eine binäre abstrakte Syntaxbaumdarstellung serialisiert werden können. Sie können das Dokument `}<a parentName="p" {...{
        "href": "https://hackmd.io/Aas4HLKFRKG5PPyJd-M3Dw"
      }}>{`hier`}</a>{` finden.`}</p>
    <p>{`Auch Sicherheitsaspekte sind sowohl für Zenotta als auch für PowerShell sehr wichtig. Skripte werden in einer virtualisierten Umgebung ausgeführt und erhalten einen begrenzten Einblick in das Hostsystem.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;