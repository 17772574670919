import styles from './SendAndReceivePayments.module.scss';
import { CodeBlock } from 'components/layout/CodeBlock/CodeBlock';
import Note from 'components/layout/Note/Note';
import Link from 'components/layout/Link/Link';
import { useTranslation } from 'react-i18next';

import QrCodeExample from 'static/img/qr_code.png';
import { PromotionalNotification } from 'components/layout/PromotionalNotification/PromotionalNotification';


export const SendAndReceivePayments = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 id="send-and-receive-payments">{t("Send and Receive Payments")}</h1>

            <p>
                This tutorial will guide you through setting up a Zenotta instance, receiving payments to
                an address you generate, and send tokens to an address that another user provides. This guide
                assumes you have already gone through the <Link to="/developer-documentation/getting-started">Getting Started</Link> guide,
                or are comfortable with the basics of the Zenotta blockchain and the provided modules.
            </p>
            {/* <Note type="info">
                <b>{t("Register for the Zenotta Mining Network & TestNet!")}</b> 
                <p className={styles.infoP}>{t('Our TestNet and mining network are open for testing! We are looking for early adopters to help us test our system.')} {'Get started '} 
                <Link to={'/signup'} out={false}><b>{t("here")}</b></Link> {'!'}</p>
            </Note> */}

            <p>
                {t("This tutorial will guide you through setting up a Zenotta instance, receiving payments to an address you generate, and send tokens to an address that another user provides. ")}
                {t("This guide assumes you have already gone through the ")}
                <Link to="/developer-documentation/getting-started">{t("Getting Started")}</Link>
                {t(" guide, or are comfortable with the basics of the Zenotta blockchain and the provided modules.")}
            </p>

            <p>
                {t("We will start by setting up a ")}
                <b>ZenottaInstance</b>
                {t(", with a test net IP and the default input arguments.")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `/** Payee.ts */`,
                    ``,
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    '',
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initNew({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `});`
                ]} />

            <p>
                {t("With the instance set up we can now call the ")}
                <code>getNewAddress</code>
                {t(" method to generate a new address. ")}
                {t("This address can then be passed on to a UI in order to receive payments. ")}
            </p >

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `/** PaymentComponent.tsx */`,
                    ``,
                    `import { znpClient } from 'Payee.ts';`,
                    `import QRCode from 'react-qr-code';`,
                    ``,
                    `const keypair = client.getNewKeypair();`,
                    ``,
                    `return <QRCode value={keypair.address} />;`
                ]} />

            <img src={QrCodeExample} className={styles.qrExample} alt="QR Code Example" />

            <small>
                {t("A")} <code>QRCode</code> {t(" component result for the address ")}<b>1f9065932...39353dd</b>
            </small>

            <p>
                {t("Here we pass the resulting address to a QR code generator to display in a more user friendly format. ")}
                {t("This QR code can be scanned by the payer in order to make a payment. ")}
                {t("In the code snippet below we switch views to the payer who has ingested the address associated with the QR code and wishes to make a payment.")}
            </p >

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `/** Payer.ts **/`,
                    ``,
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    '',
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initNew({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `});`,
                    ``,
                    `const addressToPay = '1f9065932...39353dd';`,
                    `const myKeypairs = getKeypairs(); // we assume a method to get keypairs from storage`,
                    ``,
                    `//==== Now we can make a payment ====//`,
                    ``,
                    `const paymentResult = await client.makeTokenPayment({`,
                    `   addressToPay,  // Address to pay`,
                    `   10,            // Payment amount`,
                    `   myKeypairs,    // Keypairs to pay from`,
                    `   myKeypairs[0], // Excess/change address`,
                    `);`
                ]} />

            <p>{t("And that's about it! This tutorial has shown you how to:")}</p>

            <ul>
                <li>{t("Create a Zenotta blockchain connection instance")}</li>
                <li>{t("Generate a new address for receiving payments")}</li>
                <li>{t("Make a payment to a provided address")}</li>
            </ul>

            <p>
                {t("In the ")}<Link to={'/developer-documentation/tutorials/receipt-based-payments'}>{t("next tutorial")}</Link> {t("we'll be looking at how to use the ")}<b>ZenottaInstance</b> {t("to create payments that require the payee to confirm the payment before it is processed and tokens are transferred.")}
    </p>

    <Note type="info">
        {t("Spot a mistake? Need help, or just want to provide feedback? Get hold of us ")}
        {t(" at ")} <a href="mailto:dev@zenotta.com?subject=Zenotta.io - Tutorial Feedback">dev@zenotta.com</a>.
    </Note>
        </div >
    );
}
