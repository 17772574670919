import { useEffect, useState } from 'react';
import styles from './MdxViewer.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { MDXProvider } from '@mdx-js/react';
import { H1, H2, H3 } from './Headers.js';
import { A } from './Link.js';
import { getHeadingsData } from 'utils/hooks.js';
import { useIntersectionObserver } from 'utils/hooks';
import BottomNav, { searchRoutes } from 'components/layout/BottomNav/BottomNav';
import { useDispatch, useSelector } from 'react-redux';
import { setTocItems } from 'store/actions';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const MdxViewer = (props: { content: string, toc: boolean }) => {
  const dispatch = useDispatch();
  let { path } = useRouteMatch();

  const navItems = useSelector((state: any) => {
    return state.navItems;
  });

  const [neighbourPages, setNeighbourPages]: any = useState();
  useIntersectionObserver();

  useEffect(() => {
    if (props.toc) dispatch(setTocItems(getHeadingsData()));
    else dispatch(setTocItems([]));
  }, [props.toc, dispatch]);

  // Bottom nav
  useEffect(() => {
    let current = path.split('/').pop();
    if (current && navItems) setNeighbourPages(searchRoutes(navItems, current));
  }, [navItems, path]);

  return (
    <div className={styles.content}>
      <MDXProvider components={{ h1: H1, h2: H2, h3: H3, a: A }}>
        <props.content />
      </MDXProvider>
      <BottomNav {...neighbourPages} />
    </div>
  );
};

export default MdxViewer;
