export enum Theme { // Already set in App.tsx, must relocate and remove duplicate
    Dark = "dark",
    Light = "light"
}

export enum ContentType {
    Mdx = "mdx",
    Jsx = "jsx",
    Pdf = "pdf",
    Ipynb = "ipynb",
    None = "none"
}
