import 'styles/transition.css';
import styles from './Research.module.scss';
import { PageProps } from 'utils/interfaces';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateNavRoutes } from 'utils/functions';
import { hot } from 'react-hot-loader/root';
import { researchRoutes } from './routes';

// Img
import dualDoubleEntry from 'static/img/dual_double_entry.svg';
import { useSelector } from 'react-redux';
/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const Research = ({ basePath }: PageProps) => {
  const { t } = useTranslation();
  const localLang = useSelector((state: any) => { return state.language });

  return (
    <div className={styles.container}>
      <Switch>
        <Route key={basePath} exact path={`${basePath}`}>
          <h1>{t('Research')}</h1>
          <p>
            {t('Blockchain research requires an interdisciplinary approach in order to balance the necessary computer science, economics and law and deliver a well-integrated, stable solution. Currently, Zenotta’s work in this field contains a considerable amount of unpublished research and development. What appears here are some of the fundamental concept blocks we are comfortable to disseminate. Content here will be frequently updated and grow in volume. One critical note is that “computer science” does not natively provide a framework with which to usefully study blockchains. Our research collectively establishes a memory management model for “oracle science” to formalize the study of blockchains. This is the fundamental objective of all research presented here.')}
          </p>
          <p>
            {t('This section of the website dedicated to research is divided into Philosophy, Economics, and Computer Science. In each section the reader can find the current papers available to the public that describe various aspects of the Zenotta Digital System and the science and technology around it, along with any relevant papers published by our partners.')}
          </p>
          <h2>{t('A first attempt to formalize oracle science research')}</h2>
          <p>
            {t('If mathematics defines what some mathematical object is, then computer science is the study of how to produce that object in an efficient way. In essence, computer science is the perfect abstraction of process engineering. Oracle science, then, is the study of decision making at machine level. It therefore follows that the engineering of blockchain network protocols and the associated research are a subset of oracle science, whereby we attempt to understand not just what consensus is within decision making, but how to produce electronic consensus for value in an efficient way.')}
          </p>
          <p>
            {t('Thus when asked, “who owns asset XYZ?” or similar questions, the blockchain as an oracle can provide us with a machine-generated answer. The big question when researching such a complex and difficult goal is “What model do we use to study the associated problems, challenges and solutions surrounding consensus oracles?” The model should also be useful when asking “What does it mean for a consensus oracle to be efficient?”')}
          </p>
          <p>
            {t( "Models abstract away details and subtleties in exchange for understanding and elucidating general principles. There are several models one can build for understanding blockchain technologies. Two common models developed for research in computer science are:")}
          </p>
          <ul>
            <li>
              {t("Grammar models, where we try to understand the rules for a machine that produces output strings and what kinds of computation are possible give the rules of said grammar. A good example of a grammar is a type-0 grammar that represents Turing completeness.")}
            </li>
            <li>
              {t("A calculus model describing a method of reasoning and the associated benefits of that calculus to solving a problem set. A good example of a calculus is the Lambda calculus inspired by Alonzo Church.")}
            </li>
          </ul>
          <p>
            {t("We present here a memory management model. It is worth understanding why this model is more appropriate than a calculus or grammar as a first model for blockchain technologies. While the calculus and grammar models of oracles are straightforward enough (and very different from automata like Turing or Church Machines), it does not develop an intuition for why we need machine-executable oracles and what we can do with them. Memory management, in a traditional sense, shows us how to make programs performant or safe. It does this by making us aware of the resources consumed during computation and how they are allocated. Memory management, in a blockchain sense, highlights faults such as the Byzantine general’s problem, for which good oracle memory management is the solution.")}
          </p>
          <h2>{t("In summary:")}</h2>
          <p>
            {t("In the study of computer science, a program’s grammar and calculus are non-trivial but once defined their memory management optimization becomes comparatively trivial, whereas the study of oracles we find the reverse. The entire design challenge around constructing a performant oracle lies in its memory management. Its grammar is always an O(1) response to a query which can be a function or a single bit. The calculus is similarly trivial.")}
          </p>
          <p>
            {t("The design inverse occurs because computers must demonstrate only fidelity, whereas oracles must demonstrate veracity. Therefore, the core research framework for oracle science is the memory management model for that oracle and is presented below. This model gives context and understanding to the individual aspects of research available on this site.")}
          </p>

          <img src={dualDoubleEntry} alt="Dual double entry" />
          <p>Figure 1: {t("The memory management model resulting from our collective research.")}</p>
        </Route>
        {generateNavRoutes(researchRoutes, basePath, localLang)}
      </Switch>
    </div>
  );
};

export default hot(Research);
