
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Unicorn from '../img/743546cf1b1fd9d143e7899b8a6388de/unicorn_construction.png';
import Pipelining from '../img/433e26e95b0c450bcdbd02fc36f2b509/pipelining.png';
import Block from '../img/2a338d39d11d6068544a8ac89b11040f/block_add_pipelining.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Processo di estrazione dei blocchi`}</h1>
    <p>{`Il processo di estrazione di un blocco in ZNP è piuttosto complesso. Inizia con la seguente procedura generale:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Gli utenti inviano le loro transazioni all'anello `}{`[Mempool]`}{` (mempool-node), il quale inserisce tutte le transazioni valide in un pool in attesa di essere incluse in un blocco.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Una volta avviato il processo di blocco, le transazioni dal pool di attesa vengono aggiunte al blocco successivo e quindi inviate a tutti i `}<a parentName="p" {...{
            "href": "miner-node"
          }}>{`Miner`}</a>{` che partecipano a questo round.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I minatori estraggono il blocco e inviano le loro Proof-of-Work (PoW) all'anello Mempool.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`L'anello Mempool seleziona quindi il vincitore finale, che riceve un token di ricompensa, e il blocco e le informazioni di vincita associate vengono inviate all'anello `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{`.`}</p>
      </li>
    </ol>
    <p>{`I dettagli del processo di estrazione, come illustrato in questo documento, riguardano le comunicazioni tra l'anello Mempool e i Minatori nel processo di estrazione diretta del blocco.`}</p>
    <h2>{`Costruzione di UNiCORN`}</h2>
    <p>{`L'intero processo di mining richiede l'uso di un singolo `}{`[UNiCORN]`}{` (unicorni), che viene generato da un valore seme. Questo valore seme è composto dalle transazioni che entrano nel blocco da estrarre, dalle richieste di tutti i minatori che vogliono estrarre il blocco e dall'elenco dei minatori che hanno avuto successo 2 blocchi fa (maggiori informazioni su quest'ultima parte in seguito).`}</p>
    <p>{`Questo UNiCORN viene utilizzato per una serie di processi, in particolare:`}</p>
    <ol>
      <li parentName="ol">{`Selezione del sottoinsieme di minatori`}</li>
      <li parentName="ol">{`Selezione del vincitore del minatore`}</li>
    </ol>
    <p>{`La costruzione è quindi la seguente, con la riduzione dei valori di input ai loro hash per risparmiare sui costi di memorizzazione:`}</p>
    <img src={Unicorn} alt="unicorn_construction" />
    <h2>{`Processo per un singolo blocco`}</h2>
    <p>{`Una volta costruito un UNiCORN, l'output può essere inserito in un generatore di numeri pseudocasuali (PRNG) come seme per selezionare un sottoinsieme dei minatori che hanno fatto domanda. Questo sottoinsieme è l'unico gruppo che può iniziare a estrarre il blocco nel modo previsto. Una volta che un minatore ha trovato un PoW valido, può inviarlo all'anello Mempool, che memorizzerà tutti i PoW di successo dei minatori partecipanti in un elenco in sospeso.`}</p>
    <p>{`Una volta che il round è considerato chiuso, il vincitore del round viene selezionato ancora una volta tramite l'UNiCORN precalcolato. Dopodiché, il blocco viene inviato all'anello Storage per l'archiviazione permanente.`}</p>
    <h2>{`Pipeline parallela per tutti i blocchi`}</h2>
    <p>{`In pratica, sebbene il processo sopra descritto funzioni, è inefficiente e richiede che il blocco successivo attenda inutilmente che il blocco precedente finisca prima di iniziare. Si può invece creare un processo parallelo in cui due blocchi vengono estratti simultaneamente, come nel diagramma seguente:`}</p>
    <img src={Pipelining} alt="pipelining" />
    <p>{`Affinché questo processo funzioni, è importante assicurarsi che il campo `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` dell'intestazione del blocco sia corretto, poiché il blocco precedente non sarà ancora completo. Di conseguenza, il valore `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` non punterà più al blocco immediatamente precedente nella catena, ma sarà composto dai valori dei due blocchi precedenti al blocco precedente. In parole povere, il `}<inlineCode parentName="p">{`previous_hash`}</inlineCode>{` per il blocco `}<inlineCode parentName="p">{`t`}</inlineCode>{` sarà composto dai valori dei blocchi `}<inlineCode parentName="p">{`t - 2`}</inlineCode>{` e `}<inlineCode parentName="p">{`t - 3`}</inlineCode>{`, anziché da `}<inlineCode parentName="p">{`t - 1`}</inlineCode>{`.`}</p>
    <p>{`Il diagramma seguente descrive questo processo per il blocco `}<inlineCode parentName="p">{`n + 2`}</inlineCode>{`, che ha un hash precedente composto dall'hash precedente del blocco `}<inlineCode parentName="p">{`n - 1`}</inlineCode>{` e dall'hash dell'intero blocco in `}<inlineCode parentName="p">{`n`}</inlineCode>{`:`}</p>
    <img src={Block} alt="block_add_pipelining" />
    <p>{`Infine, dopo aver trovato e selezionato un vincitore, l'anello Mempool invia il blocco e i valori di input UNiCORN all'anello Storage per la memorizzazione.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;