
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nodo di archiviazione`}</h1>
    <p>{`Il nodo di archiviazione ha un'unica, semplice responsabilità: archiviare l'intera storia della blockchain. Sebbene si tratti di un compito semplice, comporta l'archiviazione di una serie di componenti del sistema Zenotta, che verranno illustrati più avanti.`}</p>
    <h2>{`Struttura del nodo di archiviazione`}</h2>
    <p>{`Il nodo Storage è gestito da: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageNode`}</inlineCode>{`:`}</strong>{` struttura di dati`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageInterface`}</inlineCode>{`:`}</strong>{` un tratto che definisce molti dei suoi metodi rivolti all'esterno`}</li>
    </ul>
    <p>{`Esiste un numero limitato di nodi Storage che sono (per il momento) sotto la gestione diretta di Zenotta. Qualsiasi minatore o utente pubblico può richiedere di diventare un nuovo nodo di stoccaggio, ma ciò non è necessario per il corretto funzionamento del sistema.`}</p>
    <p>{`L'anello di nodi Storage opera insieme sotto `}<a parentName="p" {...{
        "href": "https://raft.github.io/"
      }}>{`RAFT consensus`}</a>{`, dove ogni nodo Storage riceve tutti i blocchi estratti da tutti i nodi Mempool e aggiunge il risultato combinato al proprio libro mastro interno. I nodi di stoccaggio devono anche verificare che tutte le transazioni siano uguali tra i blocchi.`}</p>
    <h2>{`Ricevere blocchi`}</h2>
    <p>{`Il nodo di archiviazione si occupa principalmente della ricezione dei blocchi, che avviene tramite il metodo `}<inlineCode parentName="p">{`receive_block`}</inlineCode>{`. Questo metodo prende il blocco ed esegue le seguenti operazioni:`}</p>
    <ul>
      <li parentName="ul">{`confronta l'ID del blocco con tutti gli altri blocchi ricevuti`}</li>
      <li parentName="ul">{`Confronta le transazioni tra tutti i blocchi con lo stesso ID, segnalando come non validi i blocchi non corrispondenti`}</li>
      <li parentName="ul">{`Esegue il consenso RAFT con i nodi Storage peer e, una volta raggiunto, salva tutti i blocchi validi nel database.`}</li>
    </ul>
    <h2>{`Ricezione dei valori UNiCORN`}</h2>
    <p>{`Per convalidare che tutti i blocchi della cronologia siano corretti e non siano stati manomessi, la blockchain di Zenotta fa uso di un valore speciale chiamato `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` in combinazione con l'hash del blocco.`}</p>
    <p>{`Per dimostrare che gli UNiCORN passati erano effettivamente incontestabilmente casuali e non precalcolati, le tabelle dei valori che compongono l'UNiCORN devono essere conservate per la verifica. Anche questo compito spetta ai nodi Storage, con il metodo `}<inlineCode parentName="p">{`receive_unicorn_table`}</inlineCode>{`. Anche in questo caso, il processo di ricezione è semplice:`}</p>
    <ul>
      <li parentName="ul">{`Utilizzare la funzione di valutazione integrata di UNiCORN per dimostrare che gli input sono validi per il valore UNiCORN fornito.`}</li>
      <li parentName="ul">{`Eseguire il consenso RAFT con i nodi Storage peer come parte di una regolare convalida del blocco e, una volta raggiunto, salvare il blocco nel database.`}</li>
    </ul>
    <h2>{`Ricezione dei DRUID`}</h2>
    <p>{`È importante registrare la storia dei `}<a parentName="p" {...{
        "href": "https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df"
      }}>{`DRUID`}</a>{` nel sistema. Questi valori consentono a chiunque di verificare il momento in cui un'attività è stata scambiata con un'altra, che si tratti di dati o di gettoni. `}</p>
    <p>{`Anche in questo caso, la memorizzazione dei DRUID spetta ai nodi Storage. Il processo è gestito dal metodo `}<inlineCode parentName="p">{`receive_druids`}</inlineCode>{` e consiste semplicemente nel salvare i DRUID direttamente su disco. Attualmente non è previsto alcun requisito di validazione per tali salvataggi.`}</p>
    <p>{`Il processo di memorizzazione separata delle cronologie dei DRUID non è attualmente implementato, ma è previsto per il futuro. Al momento, gli utenti possono costruire queste cronologie manualmente cercando le transazioni legate ai DRUID all'interno dello stesso blocco.`}</p>
    <h2>{`Consenso dei nodi Storage`}</h2>
    <p>{`Affinché un nuovo blocco venga aggiunto con successo alla blockchain, deve essere ritenuto valido e quindi replicato in tutti i nodi Storage correnti. Il processo è il seguente:`}</p>
    <ul>
      <li parentName="ul">{`La RAFT viene innanzitutto istituita e si procede all'elezione del leader.`}</li>
      <li parentName="ul">{`Un nuovo blocco vincente viene inviato a ogni nodo Storage dal nodo Mempool collegato (ogni nodo Mempool a un nodo Storage diverso).`}</li>
      <li parentName="ul">{`Tutti questi blocchi vengono inviati a RAFT e vengono consensati in ogni nodo di archiviazione.`}</li>
      <li parentName="ul">{`Su `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{`, dopo ogni giro di blocchi, ogni nodo spinge su raft un `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Quando sia la maggioranza di `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{` che la maggioranza di `}<inlineCode parentName="li">{`block(blocknum)`}</inlineCode>{` sono consensuali, viene creato un nuovo `}<inlineCode parentName="li">{`block_valido`}</inlineCode>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Intestazione del blocco e transazioni comuni`}</li>
          <li parentName="ul">{`Nonce vincente concordato e transazione coinbase associata`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Il `}<inlineCode parentName="li">{`block_valido`}</inlineCode>{` è memorizzato e il `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{` è impostato per `}<inlineCode parentName="li">{`ora + 1 secondo`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`L'evento di memorizzazione viene inviato al nodo Mempool associato`}</li>
      <li parentName="ul">{`Il nodo Mempool riceve `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{` e lo invia al suo RAFT.`}</li>
      <li parentName="ul">{`Quando la maggioranza dei nodi Mempool acconsente a `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{`, inizia il prossimo round di estrazione dei blocchi.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;