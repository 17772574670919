
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Types de nœuds ZNP`}</h1>
    <p>{`Contrairement au Bitcoin, où tous les mineurs de nœuds complets remplissent des fonctions identiques, Zenotta utilise différents types de nœuds dans le ZNP. Les différents types de nœuds permettent au ZNP de faciliter les délais de règlement rapides des paiements, d'assurer la diversité géographique, d'alléger les exigences de stockage de la blockchain et, surtout, de permettre les paiements par jetons.`}</p>
    <h2>{`Les types en un coup d'œil`}</h2>
    <p>{`Le ZNP se compose de 3 types de nœuds principaux :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Mempool-Node"
          }}><strong parentName="a">{`Nœud Mempool`}</strong></a>{`: Le nœud Mempool est un type spécial de nœud chargé de collecter les transactions et de les regrouper en nouveaux blocs. Il partitionne également le réseau minier pour le minage et valide les blocs que ces partitions minent. `}<em parentName="p">{`Il n'y a qu'un nombre limité de nœuds Mempool`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Miner-Node"
          }}><strong parentName="a">{`Nœud mineur`}</strong></a>{`: Le nœud Miner ressemble le plus au mineur de Bitcoin que vous connaissez peut-être. Il est responsable de l'extraction des nouveaux blocs qu'il reçoit des nœuds Mempool et perçoit la récompense associée au bloc pour ses efforts. Ils ne sont en mesure de miner un bloc que s'ils parviennent à entrer dans la partition pour ce tour. `}<em parentName="p">{`Il peut y avoir un nombre illimité de nœuds mineurs`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Storage-Node"
          }}><strong parentName="a">{`Nœud de stockage`}</strong></a>{`: Le nœud de stockage est responsable du stockage de l'historique complet de la blockchain. Une fois que les blocs sont extraits par les mineurs et envoyés aux nœuds Mempool pour validation, ils sont poussés vers les nœuds de stockage pour un stockage à long terme. Tout le monde peut demander un historique complet de la blockchain aux nœuds de stockage, mais ils ne sont pas obligés de conserver un tel historique pour participer au réseau. *Il n'y a qu'un nombre limité de nœuds de stockage.`}</p>
      </li>
    </ul>
    <h2>{`Pourquoi différents types de nœuds ?`}</h2>
    <p>{`Une bonne question, car de nombreuses blockchains semblent fonctionner avec un seul type de nœud. Le système Zenotta doit répondre à quelques préoccupations essentielles, que différents types de nœuds permettent de résoudre :`}</p>
    <h3>{`Règlement rapide des paiements`}</h3>
    <p>{`Actuellement, Bitcoin recommande un temps d'attente minimum de 6 blocs avant d'accepter une transaction de paiement. Cela est dû à l'architecture de Bitcoin, dans laquelle l'existence du propriétaire d'un jeton ne peut être vérifiée que `}<em parentName="p">{`après`}</em>{` que ce propriétaire ait dépensé ce jeton. Une telle structure est ce que nous appelons `}<strong parentName="p">{`past facing`}</strong>{`, car elle a besoin de regarder dans le passé afin de valider une transaction. Une telle orientation vers le passé (en plus des blocs orphelins distribués) signifie que nous devons attendre un certain temps avant de pouvoir dire qu'une transaction a été incluse avec certitude. Cette structure est excellente pour la sécurité, mais terrible pour les vitesses de règlement, et entraîne un certain nombre de correctifs non idéaux à résoudre (comme Lightning).`}</p>
    <p>{`Zenotta, en revanche, a un règlement rapide et une sécurité élevée intégrés directement dans la structure de la blockchain, ce qui nous donne le meilleur des deux mondes. La clé d'une telle structure est l'utilisation des nœuds Mempool, qui sont relativement peu nombreux par rapport aux nœuds Miner. Toutes les transactions doivent passer par les nœuds Mempool, qui peuvent coordonner les dépenses liées aux transactions et vérifier la propriété immédiatement sans avoir besoin de blocs orphelins ou de longs délais d'attente. La question "Qui, dans le réseau, a la plus longue chaîne ?", qui peut avoir plusieurs réponses à tout moment dans Bitcoin, est toujours la même pour Zenotta : les nœuds Mempool.`}</p>
    <h3>{`Diversité géographique`}</h3>
    <p>{`Actuellement, de nombreuses crypto-monnaies voient leur activité minière concentrée dans une seule région géographique. La concentration des pools miniers pour le bitcoin a été pendant une période substantielle jusqu'à 81 % en Chine. Cela présente certains risques pour une crypto-monnaie, comme des changements soudains de législation, qui peuvent provoquer des perturbations, voire un effondrement. `}</p>
    <p>{`Pour Zenotta, ce défi est simple à relever. Chacun des nœuds Mempool, dont le nombre est relativement faible, est placé dans une région géographique différente ; cela permet d'éviter qu'une nation ou un ensemble de lois n'affectent le fonctionnement de Zenotta et de minimiser les risques potentiels.`}</p>
    <h3>{`Des exigences de stockage plus légères`}</h3>
    <p>{`Pour rejoindre les réseaux Bitcoin ou Ethereum en tant que mineur à part entière, il faut télécharger l'intégralité de la blockchain pour pouvoir participer. Cela peut entraîner la consommation de téraoctets de stockage, ce qui constitue une barrière d'entrée élevée pour les nouveaux participants.`}</p>
    <p>{`Pour Zenotta, en revanche, la charge du stockage de la blockchain est confiée aux nœuds de stockage. Tout le monde est autorisé à interroger le nœud de stockage pour obtenir l'historique complet de la blockchain s'il le souhaite, mais rien de tout cela n'est nécessaire pour participer à l'exploitation minière, ce qui signifie que les mineurs potentiels peuvent se mettre au travail aussi rapidement que possible.`}</p>
    <h3>{`Paiements de jetons pour un actif donné`}</h3>
    <p>{`Jusqu'à présent, l'enregistrement de paiements en jetons pour un actif donné n'était possible sur aucune blockchain. La blockchain Zenotta DDE est la première blockchain à permettre l'enregistrement des transferts de jetons pour des actifs en tant que paiements. Malheureusement, l'obligation d'enregistrer le transfert d'un actif pour un autre signifie que l'architecture `}<strong parentName="p">{`past facing`}</strong>{` des autres blockchains ne fonctionnera pas pour Zenotta.`}</p>
    <p>{`Par conséquent, l'introduction des nœuds Mempool permet de compiler deux "demi-transactions" et de les inclure dans le même bloc comme une seule paire de transactions, même si chaque moitié provient d'un utilisateur différent. Ceci n'est possible que parce que les nœuds Mempool existent et facilitent toutes les transactions dans le système, créant ainsi une nouvelle architecture `}<strong parentName="p">{`présente`}</strong>{` pour le réseau Zenotta.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;