import { CodeBlock as GenericCodeBlock } from 'components/layout/CodeBlock/CodeBlock';
import { SearchableList, Section, Item } from 'components/inputs/SearchableList/SearchableList';
import { useTranslation } from 'react-i18next';

const CodeBlock = (props: any) => (
    <GenericCodeBlock selectable={false} defaultLang="text" {...props} />
);

export const ZScriptOpcodes = () => {
  const { t } = useTranslation();
    return (
        <div>
            <h1 id="zscript-opcodes">{t("ZScript Opcodes Reference")}</h1>

            <p>
                {t("This page contains a reference list of all the opcodes that are available within the Zenotta blockchain's ZScript language. You can filter your search through the table of contents or the search bar below:")}
            </p>

            <SearchableList initialSearch=''>
                <Section name='Flow Control'>
                    <Item
                        word='OP_IF'
                        code={0x63}
                        description={t('Checks if the value at the top of the stack is not 0 and executes the following block of opcodes (everything until OP_ENDIF or OP_ELSE is encountered). Skips the following block of opcodes if the condition check is failed. Should have a closing OP_ENDIF. The top stack value is consumed.')}
                    >
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 OP_GREATERTHAN    // puts 1 at the top of the stack`,
                                ``,
                                `OP_IF                 // this code path will be executed because`,
                                `  2                   // the OP_IF condition is satisfied`,
                                `OP_ELSE`,
                                `  3`,
                                `OP_ENDIF`
                            ]} />

                        <p>Conditional blocks can be nested:</p>
                        <CodeBlock
                            content={[
                                `1 2 OP_GREATERTHAN    // puts 1 at the top of the stack`,
                                `OP_IF`,
                                `  0`,
                                `  OP_IF 2 OP_ELSE 3 OP_ENDIF  // outputs 3 because the OP_ELSE branch is executed`,
                                `OP_ELSE`,
                                `  4`,
                                `OP_ENDIF`
                            ]} />
                    </Item>
                    <Item
                        word='OP_NOTIF'
                        code={0x64}
                        description={t('Checks if the value at the top of the stack is 0 and executes the following block of opcodes (everything until OP_ENDIF or OP_ELSE is encountered). Skips the following block of opcodes if the condition check is failed. Should have a closing OP_ENDIF. The top stack value is consumed.')}
                    >
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 OP_GREATERTHAN    // puts 1 at the top of the stack`,
                                ``,
                                `OP_NOTIF`,
                                `  2`,
                                `OP_ELSE               // this code path will be executed because`,
                                `  3                   // the OP_NOTIF condition is not satisfied`,
                                `OP_ENDIF`
                            ]} />
                    </Item>
                    <Item
                        word='OP_ELSE'
                        code={0x67}
                        description={t('Executes an alternative conditional block. Should have a preceding OP_IF or OP_NOTIF. Should have a closing OP_ENDIF.')}
                    >
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 0 OP_GREATERTHAN    // puts 0 at the top of the stack because 0 < 1`,
                                ``,
                                `OP_IF                 // this code path will not be executed because`,
                                `  2                   // the OP_IF condition is not satisfied`,
                                `OP_ELSE`,
                                `  3                   // however, this code path will be executed`,
                                `OP_ENDIF`
                            ]} />
                    </Item>
                    <Item
                        word='OP_ENDIF'
                        code={0x68}
                        description={t('Indicates an end of a conditional block.')}
                    />
                    <Item word="OP_NOP" code={0x61} description={t("Does nothing.")} />
                    <Item word="OP_VERIFY" code={0x69} description={t("Makes the transaction invalid if the value at the top of the stack is 0. The top stack value is consumed.")}></Item>
                    <Item word="OP_RETURN" code={0x6a} description={t("Unconditionally makes the transaction invalid")}>
                        {t("NB for users of Bitcoin Script: using this opcode for storing arbitrary data is not valid in ZScript.")}
                    </Item>
                </Section>

                <Section name='Numeric'>
                    <Item word="OP_ADD" code={0x93} description={t("Consumes the two top stack values, adds them together and puts the result on top of the stack.")}>
                        <p>{t('Usage example:')}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD    // puts 4 at the top of the stack`,
                            ]} />
                    </Item>
                    <Item word="OP_SUB" code={0x94} description={t("Consumes the two top stack values, substracts them and puts the result on top of the stack.")}>
                        <p>{t('Usage example:')}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_SUB    // puts -2 at the top of the stack`,
                                `4 2 OP_SUB    // puts 2 at the top of the stack`,
                            ]} />
                    </Item>
                    <Item word="OP_MUL" code={0x95} description={t("Consumes the two top stack values, multiplies them together and puts the result on top of the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `4 4 OP_SUB    // puts 16 at the top of the stack`,
                            ]} />
                    </Item>
                    <Item word="OP_1ADD" code={0x8b} description={t("Adds 1 to the top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD    // puts 4 at the top of the stack`,
                                `OP_1ADD       // replaces the top stack value with 5`,
                            ]} />
                    </Item>
                    <Item word="OP_1SUB" code={0x8c} description={t("Subtracts 1 from the top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD    // puts 4 at the top of the stack`,
                                `OP_1SUB       // replaces the top stack value with 3`,
                            ]} />
                    </Item>
                    <Item word="OP_2MUL" code={0x8d} description={t("Multiplies the top stack value by 2.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD    // puts 4 at the top of the stack`,
                                `OP_2MUL       // replaces the top stack value with 8`,
                            ]} />
                    </Item>
                    <Item word="OP_NEGATE" code={0x8f} description={t("Flips the sign of the top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD    // puts 4 at the top of the stack`,
                                `OP_NEGATE     // replaces the top stack value with -4`,
                            ]} />
                    </Item>
                    <Item word="OP_ABS" code={0x90} description={t("Makes the top stack value positive.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_SUB    // puts -2 at the top of the stack`,
                                `OP_ABS        // replaces the top stack value with 2`,
                            ]} />
                    </Item>
                    <Item word="OP_NOT" code={0x91} description={t("If the value at the top of the stack is 0, this operation flips it to 1. Otherwise, the output is 0.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 0 OP_GREATERTHAN    // puts 0 at the top of the stack because 0 < 1`,
                                `OP_NOT                // replaces the top stack value with 1`,
                                ``,
                                `1 0 OP_LESSTHAN       // puts 1 at the top of the stack because 1 > 0`,
                                `OP_NOT                // replaces the top stack value with 0`,
                            ]} />
                    </Item>
                    <Item word="OP_0NOTEQUAL" code={0x92} description={t("If the value at the top of the stack is 0, this operation replaces it with 0. Otherwise, it's replaced by 1.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 0 OP_GREATERTHAN    // puts 0 at the top of the stack because 0 < 1`,
                                `OP_0NOTEQUAL          // replaces the top stack value with 0`,
                                ``,
                                `1 0 OP_LESSTHAN       // puts 1 at the top of the stack because 1 > 0`,
                                `OP_0NOTEQUAL          // replaces the top stack value with 1`,
                            ]} />
                    </Item>
                    <Item word="OP_BOOLAND" code={0x9a} description={t("Consumes and compares top 2 stack values. If both are not 0, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_LESSTHAN    // puts 0 at the top of the stack because 4 > 2`,
                                `4 2 OP_LESSTHAN    // puts 1 at the top of the stack because 2 < 4`,
                                `OP_BOOLAND         // puts 0 at the top of the stack because the first comparison returned 0`,
                                ``,
                                `8 2 OP_LESSTHAN    // puts 1 at the top of the stack because 8 > 2`,
                                `16 8 OP_LESSTHAN   // puts 1 at the top of the stack because 16 > 8`,
                                `OP_BOOLAND         // puts 1 at the top of the stack because both comparisons returned 1`,
                            ]} />
                    </Item>
                    <Item word="OP_BOOLOR" code={0x9b} description={t("Consumes and compares top 2 stack values. If either of them are not 0, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_LESSTHAN    // puts 0 at the top of the stack because 4 > 2`,
                                `4 2 OP_LESSTHAN    // puts 1 at the top of the stack because 2 < 4`,
                                `OP_BOOLOR          // puts 1 at the top of the stack because the second comparison returned 1`,
                                ``,
                                `2 8 OP_LESSTHAN    // puts 0 at the top of the stack because 8 > 2`,
                                `8 16 OP_LESSTHAN   // puts 0 at the top of the stack because 16 > 8`,
                                `OP_BOOLOR          // puts 0 at the top of the stack because both comparisons returned 0`,
                            ]} />
                    </Item>
                    <Item word="OP_NUMEQUAL" code={0x9c} description={t("Consumes and compares top 2 stack values. If numbers are equal, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD       // stack: 4`,
                                `4 OP_NUMEQUAL    // stack: 1`,
                                ``,
                                `2 2 OP_SUB       // stack: 0`,
                                `4 OP_NUMEQUAL    // stack: 0`,
                            ]} />
                    </Item>
                    <Item word="OP_NUMEQUALVERIFY" code={0x9d} description={t("Combines OP_NUMEQUAL with OP_VERIFY. See the corresponding opcodes for details.")}>
                        <p>Usage example:</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_SUB             // stack: 0`,
                                `4 OP_NUMEQUALVERIFY    // transaction is invalid because OP_VERIFY failed`,
                            ]} />
                    </Item>
                    <Item word="OP_NUMNOTEQUAL" code={0x9e} description={t("Consumes and compares top 2 stack values. If numbers are not equal, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD          // stack: 4`,
                                `4 OP_NUMNOTEQUAL    // stack: 0`,
                                ``,
                                `2 2 OP_SUB          // stack: 0`,
                                `4 OP_NUMNOTEQUAL    // stack: 1`,
                            ]} />
                    </Item>
                    <Item word="OP_LESSTHAN" code={0x9f} description={t("Consumes and compares top 2 stack values. If val1 < val2, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_LESSTHAN    // puts 0 at the top of the stack because 4 > 2`,
                                `4 2 OP_LESSTHAN    // puts 1 at the top of the stack because 2 < 4`,
                            ]} />
                    </Item>
                    <Item word="OP_GREATERTHAN" code={0xa0} description={t("Consumes and compares top 2 stack values. If val1 > val2, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 4 OP_GREATERTHAN // puts 1 at the top of the stack because 4 > 2`,
                                `4 2 OP_GREATERTHAN // puts 0 at the top of the stack because 2 < 4`,
                            ]} />
                    </Item>
                    <Item word="OP_LESSTHANOREQUAL" code={0xa1} description={t("Consumes and compares top 2 stack values. If val1 <= val2, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_LESSTHAN         // puts 0 at the top of the stack because 2 = 2`,
                                `2 2 OP_LESSTHANOREQUAL  // puts 1 at the top of the stack because 2 = 2`,
                            ]} />
                    </Item>
                    <Item word="OP_GREATERTHANOREQUAL" code={0xa2} description={t("Consumes and compares top 2 stack values. If val1 >= val2, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_GREATERTHAN         // puts 0 at the top of the stack because 2 = 2`,
                                `2 2 OP_GREATERTHANOREQUAL  // puts 1 at the top of the stack because 2 = 2`,
                            ]} />
                    </Item>
                    <Item word="OP_MIN" code={0xa3} description={t("Compares top 2 stack values. Replaces them with the smallest of 2.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 1 OP_MIN    // puts 1 at the top of the stack`,
                                `2 42 OP_MIN   // puts 2 at the top of the stack`,
                            ]} />
                    </Item>
                    <Item word="OP_MAX" code={0xa4} description={t("Compares top 2 stack values. Replaces them with the largest of 2.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 1 OP_MAX    // puts 2 at the top of the stack`,
                                `2 42 OP_MAX   // puts 42 at the top of the stack`,
                            ]} />
                    </Item>
                    <Item word="OP_WITHIN" code={0xa5} description={t("Checks if the top stack value is within the min-max range determined by the following 2 stack values (inclusive of the `min` value).")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `10 1 5 OP_WITHIN    // checks if 5 is within range 1..10, puts 1 onto the stack`,
                                ``,
                                `10 1 1 OP_WITHIN    // checks if 1 is within range 1..10, puts 1 onto the stack`,
                                `                    // because it's inclusive of min value`,
                                ``,
                                `10 1 10 OP_WITHIN   // checks if 10 is within range 1..10, puts 0 onto the stack`,
                                `                    // because it's _not_ inclusive of min value`,
                                ``,
                                `10 1 0 OP_WITHIN    // checks if 0 is within range 1..10, puts 0 onto the stack`,
                            ]} />
                    </Item>
                </Section>

                <Section name='Stack'>
                    <Item word="OP_TOALTSTACK" code={0x6b} description={t("Moves the value from the top of the stack to the top of the alternative stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 3           // stack: 2 2 3  alt stack: empty`,
                                `OP_TOALTSTACK   // stack: 2 2    alt stack: 3`,
                                `OP_ADD          // stack: 4      alt stack: 3`,
                                `OP_FROMALTSTACK // stack: 4 3    alt stack: empty`,
                            ]} />
                    </Item>
                    <Item word="OP_FROMALTSTACK" code={0x6c} description={t("Moves the value from the top of the alternative stack to the top of the main stack.")}>
                        <p>Usage example:</p>
                        <CodeBlock
                            content={[
                                `2 2 3           // stack: 2 2 3  alt stack: empty`,
                                `OP_TOALTSTACK   // stack: 2 2    alt stack: 3`,
                                `OP_ADD          // stack: 4      alt stack: 3`,
                                `OP_FROMALTSTACK // stack: 4 3    alt stack: empty`,
                            ]} />
                    </Item>
                    <Item word="OP_IFDUP" code={0x73} description={t("Duplicates the top stack value if it is not 0.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 OP_IFDUP   // stack: 2 2`,
                                `OP_SUB       // stack: 0`,
                                `OP_IFDUP     // stack: 0`,
                            ]} />
                    </Item>
                    <Item word="OP_DEPTH" code={0x74} description={t("Outputs a number of values on the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 OP_2DUP    // stack: 1 1 1`,
                                `OP_DEPTH     // stack: 1 1 1 3`,
                            ]} />
                    </Item>
                    <Item word="OP_DROP" code={0x75} description={t("Removes the top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 8        // stack: 2 2 8`,
                                `OP_DROP      // stack: 2 2`,
                                `OP_ADD       // stack: 4`,
                            ]} />
                    </Item>
                    <Item word="OP_2DROP" code={0x6d} description={t("Removes 2 values at the top of the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 8 8      // stack: 2 2 8 8`,
                                `OP_2DROP     // stack: 2 2`,
                                `OP_ADD       // stack: 4`,
                            ]} />
                    </Item>
                    <Item word="OP_DUP" code={0x76} description="Duplicates the top stack value.">
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2 OP_ADD   // stack: 4`,
                                `OP_DUP       // stack: 4 4`,
                                `OP_ADD       // stack: 8`,
                            ]} />
                    </Item>
                    <Item word="OP_2DUP" code={0x6e} description={t("Duplicates the top stack value 2 times.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2 2       // stack: 2 2`,
                                `OP_2DUP   // stack: 2 2 2 2`,
                                `OP_ADD    // stack: 2 2 4`,
                                `OP_ADD    // stack: 2 6`,
                                `OP_ADD    // stack: 8`,
                            ]} />
                    </Item>
                    <Item word="OP_3DUP" code={0x6f} description={t("Duplicates the top stack value 3 times.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `2         // stack: 2`,
                                `OP_3DUP   // stack: 2 2 2 2`,
                                `OP_ADD    // stack: 2 2 4`,
                                `OP_ADD    // stack: 2 6`,
                                `OP_ADD    // stack: 8`,
                            ]} />
                    </Item>
                    <Item word="OP_NIP" code={0x77} description={t("Removes second-to-top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2    // stack: 1 2`,
                                `OP_NIP // stack: 2`,
                            ]} />
                    </Item>
                    <Item word="OP_OVER" code={0x78} description={t("Duplicates second-to-top stack value.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2     // stack: 1 2`,
                                `OP_OVER // stack: 1 2 1`,
                            ]} />
                    </Item>
                    <Item word="OP_2OVER" code={0x70} description={t("Duplicates a pair of second-to-top stack values.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4    // stack: 1 2 3 4`,
                                `OP_2OVER   // stack: 1 2 3 4 1 2`,
                            ]} />
                    </Item>
                    <Item word="OP_PICK" code={0x79} description={t("Takes a number N from the top of the stack and duplicates a value from stack at index N. Indexes starts from 0.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4 1   // stack: 1 2 3 4 1`,
                                `OP_PICK     // stack: 1 2 3 4 3`,
                            ]} />
                    </Item>
                    <Item word="OP_ROLL" code={0x7a} description={t("Takes a number N from the top of the stack and moves a value from stack at index N to the top. Indexes starts from 0.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4 1   // stack: 1 2 3 4 1`,
                                `OP_ROLL     // stack: 1 2 4 3`,
                            ]} />
                    </Item>
                    <Item word="OP_ROT" code={0x7b} description={t("Moves the third value from the stack to the top.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4   // stack: 1 2 3 4`,
                                `OP_ROT    // stack: 1 3 4 2`,
                            ]} />
                    </Item>
                    <Item word="OP_2ROT" code={0x71} description={t("Moves the fifth and sixth values from the stack to the top.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4 5 6   // stack: 1 2 3 4 5 6`,
                                `OP_2ROT       // stack: 3 4 5 6 1 2`,
                            ]} />
                    </Item>
                    <Item word="OP_SWAP" code={0x7c} description={t("Swaps the two top values on the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4   // stack: 1 2 3 4`,
                                `OP_SWAP   // stack: 1 2 4 3`,
                            ]} />
                    </Item>
                    <Item word="OP_2SWAP" code={0x72} description={t("Swaps the two top pairs of values on the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4    // stack: 1 2 3 4`,
                                `OP_2SWAP   // stack: 3 4 1 2`,
                            ]} />
                    </Item>
                    <Item word="OP_TUCK" code={0x7d} description={t("Duplicates the value at the top of the stack and puts it before the second-to-top value in the stack.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 2 3 4    // stack: 1 2 3 4`,
                                `OP_TUCK    // stack: 1 2 4 3 4`,
                            ]} />
                    </Item>

                    {/*splice ops*/}
                    {/*
                    <Item word="OP_SIZE" code={0x82} description=""></Item>
                    */}

                    {/*expansion*/}
                    {/*
                    <Item word="OP_CHECKLOCKTIMEVERIFY" code={0xb1} description=""></Item>
                    <Item word="OP_CHECKSEQUENCEVERIFY" code={0xb2} description=""></Item>
                    */}
                </Section>

                {/*
                <Section name='Data'>
                    <Item word="OP_CREATE" code={0xc0} description=""></Item>
                </Section>
                */}

                <Section name='Crypto'>
                    {/*
                    <Item word="OP_KECCAK" code={0xb0} description=""></Item>
                    <Item word="OP_RAND" code={0xb3} description=""></Item>
                    */}
                    <Item word="OP_SHA256" code={0xa8} description={t("Applies SHA3-256 hashing operation to the top stack value.")}></Item>

                    {/* // temporarily commented out
                    <Item word="OP_CODESEPARATOR" code={0xab} description=""></Item>
                    <Item word="OP_CHECKSIG" code={0xac} description="Checks that the signature for a tran"></Item>
                    <Item word="OP_CHECKSIGVERIFY" code={0xad} description=""></Item>
                    */}

                    {/* // temporarily commented out - will depend on types of signatures we'll use.
                        <Item word="OP_CHECKMULTISIG" code={0xae} description=""></Item>
                        <Item word="OP_CHECKMULTISIGVERIFY" code={0xaf} description=""></Item>
                    */}
                </Section>

                <Section name='Bit logic'>
                    <Item word="OP_EQUAL" code={0x87} description={t("Consumes and compares top 2 stack values. If they are exactly equal, puts 1 at the top of the stack, 0 otherwise.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `1 OP_SHA256`,
                                `<295cd1698c6ac5bd804a09e50f19f854>`,
                                `OP_EQUAL    // returns 1`,
                            ]} />
                    </Item>
                    <Item word="OP_EQUALVERIFY" code={0x88} description={t("Combines OP_EQUAL with OP_VERIFY. See the corresponding opcodes for details.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `0 OP_SHA256`,
                                `<295cd1698c6ac5bd804a09e50f19f854>`,
                                `OP_EQUALVERIFY    // transaction will be invalid because hashes don't match`,
                            ]} />
                    </Item>
                </Section>

                <Section name='Push values'>
                    <Item word="OP_PUSHDATA1" code={0x4c} description={t("Pushes arbitrary data onto the stack. The next byte contains the data length.")}>
                        <p>{t("Usage example:")}</p>
                        <CodeBlock
                            content={[
                                `OP_PUSHDATA1 2 3 4     // stack: <<0304>>`,
                                ``,
                                `// you can then apply ops to the single value on top of the stack,`,
                                `// e.g. OP_SHA256.`
                            ]} />
                    </Item>
                    <Item word="OP_PUSHDATA2" code={0x4d} description={t("Pushes arbitrary data onto the stack. The next 2 bytes contain the data length.")} />
                    <Item word="OP_PUSHDATA4" code={0x4e} description={t("Pushes arbitrary data onto the stack. The next 4 bytes contain the data length.")}></Item>
                    <Item word="OP_1NEGATE" code={0x4f} description={t("Push number -1 onto the stack.")}></Item>
                    <Item word="OP_0..OP_16" code={0x51} description={t("Push numeric values onto the stack.")}></Item>
                </Section>
            </SearchableList>
        </div>
    );
}
