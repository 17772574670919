import { useEffect } from 'react';
import styles from './SideNav.module.scss';
import Nav from 'react-bootstrap/Nav';
import arrowIcon from 'static/img/menu-arrow.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { openedNavIco, closedNavIco, showNavIco, hideNavIco } from 'components/inputs/BurgerMenu/BurgerMenu';
import { useSelector } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';

enum DropDownActionType {
  Open,
  Toggle
}

// Sass constants from _variables.scss
const sideNavWidth = parseInt(styles.sideNavWidth); // Recover sideNav width from global css variables
const widthThreshold = parseInt(styles.widthThreshold);
// Used to generate accordion side nav
const baseIncement = 0;
const baseSize = 1;
const spacing = 0.1;

export const closeSideNav = () => {
  document.getElementById('sideNav')!.style.left = '-' + sideNavWidth + 'px';
  document.getElementById('sideContainer')!.style.marginLeft = '0';
  closedNavIco();

  let layer = document.getElementById('layerMask');
  if (layer) {
    layer!.style.pointerEvents = 'none';
    layer!.style.opacity = '0';
    layer!.style.zIndex = '1';
  }
};

export const openSideNav = () => {
  document.getElementById('sideNav')!.style.left = '0';
  openedNavIco();

  if (window.innerWidth < widthThreshold) {
    let layer = document.getElementById('layerMask');
    if (layer) {
      layer!.style.pointerEvents = 'auto';
      layer!.style.opacity = '1';
      layer!.style.zIndex = '110';
    }
  } else {
    document.getElementById('sideContainer')!.style.marginLeft = sideNavWidth + 'px'; // Side container moves when not in mobile mode
  }
};

const SideNav = () => {
  const { t } = useTranslation();
  const [navItems, basePath] = useSelector((state: any) => {
    return [state.navItems, state.basePath];
  });

  const { pathname: path } = useLocation();

  //Handle SideNav state
  useEffect(() => {
    if (navItems.length > 0 && basePath !== '') {
      showNavIco();
      if (window.innerWidth >= widthThreshold) {
        openSideNav();
      } else {
        closeSideNav();
      }
    } else {
      hideNavIco();
      closeSideNav();
    }
  }, [navItems, basePath]);

  // Handles sideNav menu auto open on page load
  useEffect(() => {
    const checkForOpenItems = (path: string) => {
      let items = path.split('/');
      while (items.length > 3) {
        items.splice(-1, 1);
        dropDownAction(items.join('/'), DropDownActionType.Open);
      }
    };    
    checkForOpenItems(path);
  }, [path, navItems]);


  // dropDownAction triggers the open/close of a dropdown menu
  const dropDownAction = (path: string, actionType: DropDownActionType) => {
    let arrowImg = document.getElementById(path + '/arrow');
    let subItems = document.getElementById(path + '/items');
    if (arrowImg && subItems) {
      if (actionType === DropDownActionType.Toggle) {
        arrowImg.className = arrowImg.className === '' ? styles.visible : '';
        subItems.className = subItems.className === styles.collapsed ? '' : styles.collapsed;
      } else if (actionType === DropDownActionType.Open) {
        arrowImg.className = styles.visible;
        subItems.className = '';
      }
    }
  };

  // Generate accordion for sideNav, based on navItems routes (Recursive function)
  const generateAccordion = (routes: any, path: string, increment: number) => {
    let generatedAccordion: any = [];
    let depthFormat = {
      fontSize: `${baseSize - spacing * increment}rem`,
      fontWeight: `${400 - 50 * increment}`,
      paddingLeft: `${5 * (increment + 1)}%`,
    };
    increment++;

    routes.forEach((route: any) => {
      if (route.sub) {
        generatedAccordion.push(
          <li className={styles.mainItem} key={route.path}>
            <Nav.Link
              onClick={() => {
                dropDownAction(path + route.path, DropDownActionType.Toggle);
              }}
              className={styles.navLink}
              style={depthFormat}
            >
              {t(route.name)}
              <span className={styles.dropdownArrow}>
                <img src={arrowIcon} alt="arrow_icon" id={path + route.path + '/arrow'} />
              </span>
            </Nav.Link>
            <ul id={path + route.path + '/items'} className={styles.collapsed}>
              {generateAccordion(route.sub, path + route.path, increment)}
            </ul>
          </li>
        );
      } else {
        generatedAccordion.push(
          <li className={`${styles.mainItem} ${route.disabled ? styles.disabled : ''}`} key={route.path}>
            <Nav.Link onClick={() => { if (window.innerWidth < widthThreshold) closeSideNav()}} as={NavLink} to={`${path}${route.path}`} className={`${styles.navLink} `} style={depthFormat} activeClassName={styles.active} exact disabled={route.disabled}>
              {t(route.name)}
              <span className={styles.badge}>{route.contentType && route.contentType !== 'jsx' && route.contentType !== 'mdx' ? '[' + route.contentType + ']' : ' '}</span>
            </Nav.Link>
          </li>
        );
      }
    });
    return generatedAccordion;
  };

  return (
    <Nav defaultActiveKey={navItems ? navItems[0] : ''} className={`flex-column ${styles.sideNav}`} id="sideNav">
      {/* Set the first route as default active */}
      <section className={styles.accordionContainer}>
        <ul className={styles.mainList}>{navItems && generateAccordion(navItems, basePath, baseIncement)}</ul>
      </section>
    </Nav>
  );
};
export default hot(SideNav);
