import { useState, useEffect } from 'react';
import styles from './PdfViewer.module.scss'; // Personnalised styles for this component
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ModuleReference } from 'typescript';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import pdfSave from 'static/img/pdf-save.svg';
import arrow from 'static/img/pdf-arrow.svg';
import whiteArrow from 'static/img/white-arrow.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  file: ModuleReference;
  filePath: string;
}

enum DisplayType {
  Page = 'Page',
  List = 'List',
}

const pageSize = 'A4';

function PdfViewer(props: Props) {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(DisplayType.List);
  const [numPages, setNumPages] = useState<number | any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number | any>(null);
  const [docSize, setDocSize] = useState<number | undefined>();
  const [scrollY, setScrollY] = useState(window.scrollY);

  useEffect(() => {
    function handleResize() {
      setDocSize(resizeDoc());
    }
    function handleScroll() {
      setScrollY(window.scrollY);
    }
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function resizeDoc() {
    let container = document.getElementById('pdf-container');
    return container?.offsetWidth;
  }

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setDocSize(resizeDoc());
  }

  const isLoading = renderedPageNumber !== pageNumber;

  return (
    <div className={`${styles.container}`} id="pdf-container">
      <div className={styles.pdfActionMenu}>
        <div className={styles.dropdownView}>
          <DropdownButton id="dropdown-view" title={t(display)}>
            <Dropdown.Item key={'item-page'} onClick={() => setDisplay(DisplayType.Page)}>
              {t(DisplayType.Page)}
            </Dropdown.Item>
            <Dropdown.Item key={'item-list'} onClick={() => setDisplay(DisplayType.List)}>
              {t(DisplayType.List)}
            </Dropdown.Item>
          </DropdownButton>
        </div>

        {display === DisplayType.Page && (
          <div className={styles.pageCount}>
          <Dropdown>
              <Dropdown.Toggle id="dropdown-autoclose-true">{pageNumber}</Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdownMenu}>
                {Array.from(Array(numPages).keys()).map((pageNumber) => {
                  return (
                    <Dropdown.Item key={`item-${pageNumber}`} onClick={() => setPageNumber(pageNumber + 1)}>
                      {pageNumber + 1}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <p>{`of ${numPages || '--'}`}</p>
          </div>
        )}

        <div className={styles.dlBtn}>
          <img src={pdfSave} alt={'Download this pdf'} />
          <a href={props.filePath} download>
            {t('Download this PDF')}
          </a>
        </div>
      </div>

      {display === DisplayType.Page && (
        <>
          {pageNumber > 1 && (
            <div
              className={styles.leftOverlay}
              onClick={() => {
                if (pageNumber > 1) setPageNumber((prevPageNumber) => prevPageNumber - 1);
              }}
            >
              <img src={arrow} alt={'leftArrow'} />
            </div>
          )}
          {pageNumber < numPages && (
            <div
              className={styles.rightOverlay}
              onClick={() => {
                if (pageNumber < numPages) setPageNumber((prevPageNumber) => prevPageNumber + 1);
              }}
            >
              <img src={arrow} alt={'rightArrow'} />
            </div>
          )}
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            {isLoading && renderedPageNumber ? <Page size={pageSize} key={renderedPageNumber} className={styles.prevPage} pageNumber={renderedPageNumber} width={docSize} /> : null}
            <Page size={pageSize} key={pageNumber} pageNumber={pageNumber} onRenderSuccess={() => setRenderedPageNumber(pageNumber)} width={docSize} />
          </Document>
        </>
      )}

      {display === DisplayType.List && (
        <>
          {scrollY > 300 && (
            <div className={styles.upBtn} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <img src={whiteArrow} alt={'upArrow'} />
            </div>
          )}
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className={styles.pages}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page className={styles.page} size={pageSize} key={`page_${index + 1}`} pageNumber={index + 1} width={docSize} />
              ))}
            </div>
          </Document>
        </>
      )}
    </div>
  );
}
export default PdfViewer;
