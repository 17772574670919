import styles from './IpynbViewer.module.scss';
import colab from 'static/img/colab.svg';
import Link from 'components/layout/Link/Link';
// import JupyterViewer from 'react-jupyter-notebook'; // BUGGED, MUST CHANGE
/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */

interface props {
  content: string;
  link: string;
}

const IpynbViewer = (props: props) => {
  return (
    <div className={styles.content}>
      {props.link && (
        <div className={styles.open}>
          <img className={styles.colab} src={colab} alt={''} />
          <Link to={props.link} out={true}>{'Open dynamic notebook'}</Link>
        </div>
      )}
      {/* <JupyterViewer rawIpynb={props.content} showLineNumbers={true} mediaAlign={'center'} /> */}
      <p><i>static notebook has failed to load.</i></p>
    </div>
  );
};

export default IpynbViewer;
