import { PageProps } from 'utils/interfaces';
import { hot } from 'react-hot-loader/root';

import { Switch } from 'react-router-dom';
import { generateNavRoutes, loadLanguage } from 'utils/functions';
import { znpRoutes } from './routes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const Znp = ({basePath} : PageProps) => {
  const localLang = useSelector((state: any) => { return state.language });
  const [lang, setLang] = useState(localLang);

  useEffect(() => {
    setLang(localLang); // set lang for routes 
    loadLanguage(localLang); // set lang for i18n
  }, [])

  return (
    <>
      <Switch>
        {generateNavRoutes(znpRoutes, basePath, lang)}
      </Switch>
    </>
  );
};

export default hot(Znp);
