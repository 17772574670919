
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Shadowmourne from '../img/909c07898b4f5751941461e6fbe98473/Shadowmourne-the-runeblade.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Doppelte Transaktionen mit doppelter Erfassung`}</h1>
    <p>{`Derzeit werden Bitcoin-Zahlungen als "Wohltätigkeit" ausgewiesen: Alice zahlt Bob 1 BTC für einen Mittelklassewagen, aber der Bitcoin-Ledger kann nur verlässlich zeigen, dass Alice Bob 1 BTC für wohltätige Zwecke gegeben hat. Die Blockchain von Zenotta muss in der Lage sein, sowohl die Token-Zahlung als auch den Vermögenswert, für den die Zahlung erfolgt, wiederzugeben. Zu diesem Zweck ist eine `}<em parentName="p">{`dual double entry`}</em>{` (DDE) Transaktion erforderlich. `}</p>
    <h2>{`Wie eine DDE-Transaktion funktioniert`}</h2>
    <p>{`Bob besitzt Shadowmourne, eine fantastische Axtwaffe aus dem Spiel World of Warcraft (WoW), die er über die Zenotta-Blockchain verkaufen möchte:`}</p>
    <img src={Shadowmourne} alt="shadowmourne-the-runeblade" />
    <small>Shadowmourne &copy; Activision Blizzard, Inc.</small>
    <p>{`Alice ist neu im Spiel, möchte sich aber einen Vorsprung gegenüber der Konkurrenz verschaffen und beschließt daher, den Gegenstand im Spiel zu kaufen. Für den Fall, dass Alice Bob 100 ZNT für die Axt bezahlt, tritt folgende Sequenz ein:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Alice erstellt eine Transaktion, die 100 ZNT an Bob sendet, und erwartet das Verschlüsselungsschema für seine WoW-Axt`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob creates a transaction that sends the WoW axe's encoding scheme to Alice, and expects 100 ZNT in return`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice und Bob tauschen ihre jeweiligen Hälften eines Identifikators aus, der DRUID (Double Resolution Unique ID) genannt wird.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice und Bob verketten jeweils die Hälfte der anderen Partei mit ihrer eigenen, um die endgültige DRUID zu erstellen, und fügen die DRUID als Attribut an ihre jeweiligen Transaktionen an (`}<inlineCode parentName="p">{`druid: Option<String>`}</inlineCode>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice sendet ihre DRUID-Transaktion zur Überprüfung an den Mempool-Knoten. Da Bob derzeit offline ist und seine entsprechende DRUID-Transaktion noch nicht gesendet hat, fügt der Mempool-Knoten die Transaktion von Alice einfach als DRUID-Droplet zu seinem DRUID-Pool hinzu (`}<inlineCode parentName="p">{`druid_pool: BTreeMap<String, DruidDroplet>`}</inlineCode>{`) und wartet, bis Bob seine`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Sobald Bob seine Transaktion sendet, gleicht der Mempool-Knoten sie mit der von Alice ab, indem er die entsprechende DRUID prüft, Alices Transaktions-Droplet aus dem DRUID-Pool entfernt und jede Transaktion einzeln verarbeitet, bevor er sie dem nächsten Block hinzufügt`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`HINWEIS:`}</strong>{` Auch wenn beide Transaktionen getrennt verarbeitet werden, behalten beide den DRUID-Wert. Dies ermöglicht eine spätere Prüfung der Transaktion.`}</p>
    <h2>{`Zahlungen auf der Grundlage von Belegen`}</h2>
    <p>{`Die DDE-Transaktion ermöglicht nicht nur den prüfbaren Handel mit Token für Datenwerte, sondern auch quittungsbasierte Zahlungen. Bei einer quittungsbasierten Zahlung möchte Alice Bob 100 ZNT für wohltätige Zwecke schenken, verlangt aber, dass Bob die Schenkung genehmigt, bevor sie zu einem Block verarbeitet wird.`}</p>
    <p>{`Um dies zu ermöglichen, muss Alice einfach das Feld für den erwarteten Vermögenswert in ihrer Transaktion leer lassen (was bedeutet, dass sie keine Gegenleistung erwartet). Bob macht das Gleiche auf seiner Seite, indem er 100 ZNT anfordert, aber das Feld für den zu verkaufenden Vermögenswert leer lässt (was bedeutet, dass er nicht beabsichtigt, etwas von Wert als Gegenleistung anzubieten). Der Prozess ist derselbe wie zuvor: Sowohl Alice als auch Bob reichen ihre jeweiligen Transaktionen mit derselben DRUID ein und lassen sie vom Mempool-Knoten verarbeiten.`}</p>
    <h2>{`Zukünftige Überlegungen und Verbesserungen`}</h2>
    <p>{`Damit quittungsbasierte Zahlungen wirklich effektiv sind, muss der Inhalt mit dem privaten Schlüssel jedes Benutzers signiert werden, bevor er an den Mempool-Knoten gesendet wird. Das bedeutet, dass das Datenfeld für quittungsbasierte Zahlungen irgendwann einen Wert enthalten muss, der jedoch auf einen Wert festgelegt werden kann, der kein Erstellungsereignis erfordert (wie bei normalen Datenbeständen).`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;