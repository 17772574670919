import styles from './BottomNav.module.scss'; // Personnalised styles for this component
import { useHistory, useRouteMatch } from 'react-router-dom';
import Button from 'components/inputs/Button/Button';

const extractBasePath = (path: string) => {
  let newPath = path.split('/');
  newPath.length = newPath.length - 1;
  return newPath.join('/');
}

export const searchRoutes = (routes: any[], current: string) => {
  let result = {};
  let index = routes.findIndex((route) => route.path === '/' + current);
  if (index !== -1) {
    let next = routes[index < routes.length ? index + 1 : -1];
    let prev = routes[index > 0 ? index - 1 : -1];

    if (next?.sub) {
      result = { prev };
    } else if (prev?.sub) {
      result = { next };
    }else {
      result = { next, prev };
    }
  }
  return result;
};

/**
 * Component template
 * @returns Base component
 */
const BottomNav = (props: any) => {
  const history = useHistory();
  let { path } = useRouteMatch();
  let basePath = extractBasePath(path);
  return (
    <div className={styles.container}>
      {props.prev && <Button className={styles.prev} content={'< ' + props.prev.name} onClick={() => history.push(basePath + props.prev.path)} />}
      {props.next && <Button className={styles.next} content={props.next.name + ' >'} onClick={() => history.push(basePath + props.next.path)} />}
    </div>
  );
};

export default BottomNav;
