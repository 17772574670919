
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import ShurikenAudit from '../img/d06cd07ea832dcd43423bf1b9c6b2311/shuriken-audit.png';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Revisione dello Shuriken`}</h1>
    <p>{`Il processo di creazione di asset nel sistema ZNP funziona più o meno come una transazione su coinbase: Gli utenti costruiscono una transazione di creazione di asset, generando un valore dal nulla, e lo inviano all'anello `}{`[Mempool]`}{` (Mempool-Node) per l'inclusione nella blockchain.`}</p>
    <p>{`Il problema di questo processo è che è "gratuito", non costa né risorse materiali come i token né potenza di calcolo per essere eseguito. Questo, a sua volta, comporta potenziali problemi per la rete in generale e, in particolare, per gli attacchi DDoS. Per risolvere questo problema, lo ZNP introduce un requisito per gli utenti che desiderano creare nuove transazioni di ricevute o di asset di dati.`}</p>
    <h2>{`Auditing, un requisito per creare nuovi asset`}</h2>
    <p>{`Il requisito per creare nuovi asset è che il creatore verifichi la correttezza degli ultimi `}<em parentName="p">{`n`}</em>{` blocchi della blockchain, e in particolare vogliamo verificare se una determinata voce in un `}<a parentName="p" {...{
        "href": "unicorni"
      }}>{`UNiCORN`}</a>{` sia effettivamente parte del seme di quell'unicorno. In questo modo possiamo controllare la validità della catena nel suo complesso e fornire un modo per mitigare il DDoS nella creazione di asset in un unico passaggio.`}</p>
    <h2>{`Flusso del processo`}</h2>
    <p>{`Come esempio del flusso di processo, immaginiamo che Alice voglia creare 5 ricevute. Il modo in cui lo fa è attraverso il seguente processo:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`L'anello `}<a parentName="p" {...{
            "href": "storage-node"
          }}>{`Storage`}</a>{` imposta una tabella di presenza una volta per ogni round di blocco, che può essere strutturata come un array o un vettore di valori booleani.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Per ogni voce della tabella di presenza, il RAFT di stoccaggio deve scegliere un hash seme UNiCORN e un UNiCORN completo dalla blockchain. Alcune di queste voci dovranno essere scelte casualmente da parti diverse della blockchain (ad esempio, la voce UNiCORN 1 dal blocco 5), mentre le altre dovranno essere una voce dello stesso blocco (ad esempio, la voce UNiCORN 3 dal blocco 3). Il primo sarà `}<inlineCode parentName="p">{`false`}</inlineCode>{` (non presente nell'UNiCORN) e il secondo sarà `}<inlineCode parentName="p">{`true`}</inlineCode>{` (è presente) e per ogni caso questo valore booleano sarà inserito nella tabella delle presenze.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`La tabella delle presenze, condivisa tra gli Storage RAFT, è ora pronta. Quando Alice vuole creare le sue nuove ricevute, invia la transazione di creazione a un nodo Storage casuale e riceve in cambio una voce nella tabella di presenza, insieme ai dati associati necessari per verificare se è presente o meno (questo includerà una difficoltà per un PoW leggero).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Alice deve verificare se la voce deve essere "vera" o "falsa", quindi esegue il suo PoW su questo valore booleano e sull'hash della sua transazione di creazione. Quindi invia il risultato finale al nodo Storage.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Se la verifica di Alice è in accordo con i nodi Storage, il nodo Storage RAFT incrementa un valore di conferma per quella voce della tabella di presenza e la transazione di Alice viene inviata al nodo Mempool.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Se la verifica di Alice è `}<strong parentName="p">{`non`}</strong>{` d'accordo, il nodo di archiviazione (A) invia la voce a Bob che vuole creare una ricevuta e il disaccordo viene contrassegnato nel RAFT.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bob invierà la sua verifica al successivo nodo di conservazione disponibile (B). Se anche lui è in disaccordo, il processo al punto 6 si ripete, ma la difficoltà che il nodo di archiviazione fornisce aumenta di 10 volte.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Se vengono rilevati 3 disaccordi, la transazione di creazione per tutti e 3 gli Utenti viene immediatamente inviata all'anello Mempool e un nodo di archiviazione elaborerà manualmente la validità della voce.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Se si scopre che c'è davvero un'incongruenza, sarà necessario attuare un qualche tipo di processo di arresto sull'anello Mempool.`}</p>
      </li>
    </ol>
    <h2>{`Diagramma`}</h2>
    <p>{`Il diagramma seguente mostra un tipico processo di revisione dello shuriken:`}</p>
    <img src={ShurikenAudit} alt="shuriken-audit" />
    <p>{`La tabella di valori nella parte superiore del diagramma è la tabella di presenza concordata per il round di blocchi, dove il numero indica l'indice e i valori "T" e "F" indicano rispettivamente "vero" e "falso". Il percorso evidenziato mostra uno scenario in cui i nodi di memorizzazione inviano a un nodo utente una voce ritenuta vera, che poi l'utente determina essere in realtà falsa.`}</p>
    <p>{`I nodi di conservazione devono quindi ripetere il processo di invio ai nodi utente successivi fino alla terza ricezione. In questo caso tutti e tre sono stati valutati come falsi e quindi il quarto nodo di archiviazione deve eseguire manualmente un controllo su questa voce specifica per determinare il predicato.`}</p>
    <p>{`Gli scenari sbiaditi mostrano i casi in cui i nodi Utente concordano con le convinzioni dei nodi di Conservazione. La struttura risultante è a forma di shuriken o di stella.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;