export const setNavItems = (navItems: any[]) => {
    return {
        type: 'SET_NAV_ITEMS',
        navItems: navItems
    };
};

export const setBasePath = (basePath: string) => {
    return {
        type: 'SET_BASE_PATH',
        basePath: basePath
    };
};

export const setTocItems = (tocItems: any[]) => {
    return {
        type: 'SET_TOC_ITEMS',
        tocItems: tocItems
    };
};

export const setTheme = (theme: string) => {
    return {
        type: 'SET_THEME',
        theme: theme
    };
};

export const setActiveId = (activeId: string) => {
    return {
        type: 'SET_ACTIVE_ID',
        activeId: activeId
    };
};

export const setLanguage = (language: string) => {
    localStorage.setItem('ZIO_I18N_LANGUAGE', language);
    return {
        type: 'SET_LANGUAGE',
        language: language
    };
};