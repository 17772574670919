
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mempool Node`}</h1>
    <p>{`The Mempool node is one half of the very important balance of power in the Zenotta network. While fully distributed mining, as seen in Bitcoin, is ideal for security it is inadequate for other factors such as speed. As a result, we at Zenotta realised that a good compromise is a ring of decentralised Mempool nodes to facilitate transactions, with public, fully distributed Miner nodes still responsible for mining the blocks containing them and for providing the input into the verifiably fair decision-making process for selecting transactions.`}</p>
    <h2>{`Balance of Power`}</h2>
    <p>{`It is important to consider how a ring of Mempool nodes under the direct control of Zenotta can affect security and trustlessness in the network. By having Mempool nodes create blocks and Miner nodes mine them out, we ensure that there is a balance of control; take either set of nodes out of the system and the whole thing falls apart. There is thus a Roman-style balance between the Senate-like Mempool nodes and the Public-like Miner nodes.`}</p>
    <h2>{`Mempool Node Structure`}</h2>
    <p>{`The Mempool node consists of:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolNode`}</inlineCode></strong>{` the data structure`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolInterface`}</inlineCode></strong>{` a trait for many of the externally-facing methods`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`MempoolRequest`}</inlineCode></strong>{` a set of requests that the Mempool node can make and receive`}</li>
    </ul>
    <p>{`There are a limited number of Mempool nodes, all of which sit with a range of enterprise-level partners and collect token rewards for the processing of transactions and validation of blocks.`}</p>
    <h2>{`Constructing Blocks`}</h2>
    <p>{`In each round, the responsibility of block construction shared by all nodes within the Mempool ring. Each Mempool node has a personal transaction pool (the `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{` attribute in the `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) that they collect from users. Regularly, they will consensus through RAFT transactions from that `}<inlineCode parentName="p">{`tx_pool`}</inlineCode>{`, and will form the `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{` shared by all of them. It is from this consensused pool that new blocks are constructed.`}</p>
    <p>{`When receiving the info from the previous block stored by StorageNodes, and a majority of votes for that event is consensused, a block is generated from already consensused transactions in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`.
The order of the transactions sent and how many of the transactions in the block belong to a particular Mempool node are not important.`}</p>
    <p>{`A block will be generated even if it contains no transactions. If too many transactions are available in `}<inlineCode parentName="p">{`consensused.tx_pool`}</inlineCode>{`, only a subset will be used for this block, and the remaining will go in one of the next blocks. All nodes will have generated an identical block from consensused information and notify Miners in their partition to begin mining.`}</p>
    <h2>{`Mempool Nodes and Miner Nodes`}</h2>
    <p>{`Once a block is generated, while one block is mined & stored, Mining nodes were sending requests to all Mempool nodes to participate in the next round of block mining. This is done because, each round, it is not the whole network that mines (unlike in Bitcoin). Instead, the Zenotta network protocol only allows `}<em parentName="p">{`partitions`}</em>{` or subsets of the network to mine at any given time, and each Mempool node has its own partition of miners. This partition list (stored in the `}<inlineCode parentName="p">{`partition_list`}</inlineCode>{` in `}<inlineCode parentName="p">{`MempoolNode`}</inlineCode>{`) changes every round, allowing Miner nodes a new opportunity to mine every round.`}</p>
    <p>{`Once the partition list for a Mempool node is full, the Miner nodes for that Mempool node are considered selected, and when the new block is ready to be mined, the Mempool node sends it to the Miner nodes who made it into the partition list. Those Miners who find a valid Proof-of-Work for the block within the mining round time are considered the winners, from which one Miner is randomly selected through the `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{`, and sends their block to the Mempool node for validation and storage. For specifics on Miner operation, you can `}<a parentName="p" {...{
        "href": "Miner-Node"
      }}>{`read this wiki page`}</a>{`.`}</p>
    <h2>{`Mempool Nodes and Storage Nodes`}</h2>
    <p>{`Once the Mempool node has received a valid block, they declare the winner for their partition and send the block to their associated Storage node for long-term storage. After this point, all the round-related attributes in the Mempool node are cleared and emptied and the next round can begin.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;