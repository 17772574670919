import * as ace from 'ace-builds/src-noconflict/ace';

ace.define("ace/mode/zscript_highlight_rules", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text_highlight_rules"], function (require, exports, module) {
    var oop = require("../lib/oop");
    var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;

    var ZScriptHighlightRules = function () {
        // regexp must not have capturing parentheses. Use (?:) instead.
        // regexps are ordered -> the first match is used

        this.$rules = {
            start:
                [{
                    token: 'keyword.other.zscript',
                    regex: '\\b(?:op_(1?add|1?sub|mul|min|max|equal|lessthan|lessthanorequal|bool(and|or)|greaterthan|greaterthanorequal|(2|3)?dup|2?drop|toaltstack|fromaltstack|ifdup|depth|nip|over|2?swap|tuck|pick|2?rot|roll|size|equalverify|(hash|sha)256|verify|checksig|nop|keccak|rand|1?negate|not|0notequal|abs|pushdata(1|2|4)|if|notif|else|checklocktimeverify|hash160|endif))\\b',
                    caseInsensitive: true
                },
                {
                    token: 'constant.numeric.zscript',
                    regex: '\\b(?:op_([0-9]|1[0-6]))\\b'
                },
                {
                    token: 'constant.numeric.zscript',
                    regex: '\\b[0-9]+\\b'
                },
                {
                    token: 'constant.numeric.zscript',
                    regex: '\\b0x[A-F0-9]+\\b',
                    caseInsensitive: true
                },
                {
                    token: 'constant.zscript',
                    regex: '\\b[A-F0-9]+h\\b',
                    caseInsensitive: true
                },
                { token: 'string.zscript', regex: /"([^\\"]|\\.)*"/ }]
        };

        this.normalizeRules();
    };

    ZScriptHighlightRules.metaData = {
        fileTypes: ['zscript'],
        name: 'ZScript',
        scopeName: 'source.zscript'
    };

    oop.inherits(ZScriptHighlightRules, TextHighlightRules);

    exports.ZScriptHighlightRules = ZScriptHighlightRules;
});

ace.define("ace/mode/zscript", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text", "ace/mode/zscript_highlight_rules", "ace/mode/folding/coffee"], function (require, exports, module) {
    var oop = require("../lib/oop");
    var TextMode = require("./text").Mode;
    var ZScriptHighlightRules = require("./zscript_highlight_rules").ZScriptHighlightRules;

    var Mode = function () {
        this.HighlightRules = ZScriptHighlightRules;
        // this.foldingRules = new FoldMode();
        this.$behaviour = this.$defaultBehaviour;
    };
    oop.inherits(Mode, TextMode);

    (function () {
        this.$id = "ace/mode/zscript";
    }).call(Mode.prototype);

    exports.Mode = Mode;
});
