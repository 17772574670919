import styles from './Note.module.scss';

interface INoteProps {
    children: any;
    type: 'info' | 'warning' | 'error';
}

const Note = (props: INoteProps) => {
    return (
        <div className={`${styles.container} ${props.type}`}>
            {props.children}
        </div>
    );
};

export default Note;