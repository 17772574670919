import { devDocsRoutes} from 'components/pages/DeveloperDocs/routes';
import { researchRoutes } from 'components/pages/Research/routes';
import { znpRoutes } from 'components/pages/Znp/routes';
import Homepage from 'components/pages/Homepage/Homepage';
import Research from 'components/pages/Research/Research';
import DeveloperDocs from 'components/pages/DeveloperDocs/DeveloperDocs';
import Znp from 'components/pages/Znp/Znp';
import UsefulLinks from 'components/pages/UsefulLinks/UsefulLinks';
import Signup from 'components/pages/Signup/Signup';
import PrivacyPolicy from 'components/pages/PrivacyPolicy/PrivacyPolicy';
import Imprint from 'components/pages/Imprint/Imprint';
import TestNetTerms from 'components/pages/TestNetTerms/TestNetTerms';
// import CoreContributors from 'components/pages/CoreContributors/CoreContributors';


/**
 * Main route items
 * @prop path       : Route path
 * @prop name       : Page name
 * @prop Component  : Component to display when routed to path
 * @prop navRoutes? : Define if page has side nav items
 * @prop disabled?  : Define if route should be visible as link in top nav
 */
export interface MainRoutes {
  path: string;
  name: string;
  Component: any;
  navRoutes?: any;
  hidden?: boolean;
}
/**
 * These routes will be automatically generated in App.tsx.
 * Routes items are also generated in the top nav as links, if "hidden" property is set to true, the item is visible in the top nav.
 */
export const routes: MainRoutes[] = [
  { path: '/', name: 'Home', Component: Homepage, hidden: true },
  { path: '/developer-documentation/computational/znp', name: 'ZNP Wiki', Component: Znp, navRoutes: znpRoutes, hidden: true },
  { path: '/research', name: 'Research', Component: Research, navRoutes: researchRoutes},
  {
    path: '/developer-documentation',
    name: 'Developer Documentation',
    Component: DeveloperDocs,
    navRoutes: devDocsRoutes,
  },
  // {
  //   path: '/contributors',
  //   name: 'Core contributors',
  //   Component: CoreContributors,
  // },
  {
    path: '/useful-links',
    name: 'Useful links',
    Component: UsefulLinks,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    Component: PrivacyPolicy,
    hidden: true,
  },
  {
    path: '/testnet-terms',
    name: 'TestNet terms',
    Component: TestNetTerms,
    hidden: true,
  },
  // {
  //   path: '/signup',
  //   name: 'Start here',
  //   Component: Signup,
  // },
  {
    path: '/imprint',
    name: 'Imprint',
    Component: Imprint,
    hidden: true,
  },
];