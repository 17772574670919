import { useState } from 'react';
import styles from './LangSelector.module.scss';
import { langs } from 'i18n';
import arrowIcon from 'static/img/triangle.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from 'store/actions';
import OutsideAlerter from './OutsideAlerter';
// Flags
import frFlag from 'static/img/fr-flag.svg';
import ukFlag from 'static/img/uk-flag.svg';
import deFlag from 'static/img/de-flag.svg';
import itFlag from 'static/img/it-flag.svg';


function LangSelector() {
  const dispatch = useDispatch();

  const localLangSet = useSelector((state: any) => { return state.language });
  const [dropdownClass, setLanguageDropdown] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(langs[localLangSet]);

  const flags: any = {
    en: ukFlag,
    fr: frFlag,
    de: deFlag,
    it: itFlag,
  };

  const changeLanguage = (language: string) => {
    // Layout
    setCurrentLanguage(langs[language]);
    setLanguageDropdown('');
    // Store
    dispatch(setLanguage(language));
    window.location.reload(); // Realod page to apply translation across components
  };

  const toggleLanguageDropdown = () => {
    let newClass = dropdownClass === '' ? styles.visible : '';
    setLanguageDropdown(newClass);
  };

  return (
      <div className={styles.langContainer} onClick={() => toggleLanguageDropdown()}>
        <OutsideAlerter action={setLanguageDropdown}>
        <span className={styles.flagMain}>
          <img src={flags[localLangSet]} />
        </span>
        {currentLanguage}
        <span className={styles.dropdownArrow}>
          <img className={dropdownClass} src={arrowIcon} />
        </span>
        <ul className={`${styles.langSelection} ${dropdownClass}`}>
          {Object.keys(langs).map((entry: string) => {
            if (langs[entry] != currentLanguage)
              return (
                <li key={entry} onClick={() => changeLanguage(entry)}>
                  <span className={styles.flagMain}>
                    <img src={flags[entry]} alt={`${entry} flag`} />
                  </span>
                  {langs[entry]}
                </li>
              );
          })}
        </ul>
        </OutsideAlerter>
      </div>
  );
}

export default LangSelector;
