import styles from "./editor.module.scss"; // Personnalised styles for this component
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import AceEditor, { IMarker } from "react-ace";
import throttle from "lodash.throttle";
import "ace-builds/webpack-resolver";

import type * as Zscript from "../../../../zscript";
import "./mode-zscript.js";
import "./theme-zenotta-light.js";

type ZscriptVmModule = typeof Zscript;

enum compilerVersion { STABLE, NIGHTLY };

const Editor: React.FC = () => {
    // Current compiler version.
    const [compVer, setCompVer] = useState(compilerVersion.STABLE);
    // Reference to the ZScript wasm module.
    const [wasmMod, setWasmMod] = useState<ZscriptVmModule | null>(null);
    // Opaque pointer to a VM.
    const [vm, setVm] = useState<number>(0);

    // Reference to an aceEditor instance.
    const editorRef = useRef<AceEditor>(null);
    // Set of editor markers (used for highlighting the current executed instruction).
    const [markers, setMarkers] = useState<IMarker[]>([]);
    // Current stack state.
    const [stack, setStack] = useState<string[]>([]);
    // Current error state.
    const [displayErrors, setDisplayErrors] = useState<string | undefined>();

    useEffect(() => {
        async function loadWasm() {
            const mod: ZscriptVmModule = await import('../../../../zscript/index.js');
            setWasmMod(mod);
        }
        loadWasm();
    }, []);

    let menuHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const item = e.currentTarget as HTMLAnchorElement;
        if (item.innerText.startsWith('Nightly')) {
            setCompVer(compilerVersion.NIGHTLY);
        } else {
            setCompVer(compilerVersion.STABLE);
        }
    }, []);

    useEffect(() => {
        
        console.log('STACK', stack);

    });

    let stepBtnHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const script = editorRef.current?.editor.getValue();

        console.log('SCRIPT', script)

        if (wasmMod != null && script) {
            let execVm = vm;

            if (execVm === 0) {
                execVm = wasmMod.vm_create(script);
                setVm(execVm);
            }

            const isHalted = wasmMod.vm_next_step(execVm);
            const range = wasmMod.vm_current_range(execVm);

            setStack(wasmMod.vm_get_stack(execVm).reverse());
            setDisplayErrors(wasmMod.vm_get_error(execVm));

            setMarkers([{
                startRow: range.line,
                startCol: range.col_start,
                endRow: range.line,
                endCol: range.col_end,
                type: "text",
                className: styles['step-highlight']
            }]);

            if (isHalted) {
                wasmMod.vm_destroy(execVm);
                setVm(0);
            }
        }
    }, [vm, wasmMod]);

    const changeHandler = useCallback((script: string, event?: any) => {
        if (!wasmMod)
            return null;
        if (vm !== 0) {
            wasmMod.vm_destroy(vm);
        }
        const execVm = wasmMod.vm_create(script);
        wasmMod.vm_run(execVm);
        setStack(wasmMod.vm_get_stack(execVm).reverse());

        const errors = wasmMod.vm_get_error(execVm);
        setDisplayErrors(errors);

        wasmMod.vm_destroy(execVm);

        // Reset the step-by-step executor state.
        setVm(0);

        if (errors) {
            const range = wasmMod.vm_current_range(execVm);
            setMarkers([{
                startRow: range.line,
                startCol: range.col_start,
                endRow: range.line,
                endCol: range.col_end,
                type: "text",
                className: styles['error-highlight']
            }]);
        } else {
            setMarkers([]);
        }
    }, [wasmMod, vm]);

    const throttledChangeHandler = useMemo(() => throttle(changeHandler, 200), [changeHandler]);

    return (
        <div>
            <div className={styles['editor-top-panel']}>
                <ButtonGroup>
                    <Button variant="secondary" onClick={stepBtnHandler} className={styles['editor-button']}>Step</Button>
                </ButtonGroup>
                <Dropdown className={styles['editor-dropdown-menu-container']}>
                    <Dropdown.Toggle
                        id="dropdown-button-dark"

                    >
                        {compVer === compilerVersion.NIGHTLY && "Nightly"}
                        {compVer === compilerVersion.STABLE && "Stable"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles['editor-dropdown-menu']}>
                        <Dropdown.Item
                            active={compVer === compilerVersion.STABLE}
                            onClick={menuHandler}
                            className={styles['editor-dropdown-menu-item']}
                        >
                            <strong>Stable</strong>
                            <p>
                                Uses a stable set of opcodes.<br />
                                You can expect that all programs using
                                the stable opcodes will be future-proof.
                            </p>
                        </Dropdown.Item>
                        <Dropdown.Item
                            active={compVer === compilerVersion.NIGHTLY}
                            onClick={menuHandler}
                            className={styles['editor-dropdown-menu-item']}
                        >
                            <strong>Nightly</strong>
                            <p>
                                Supports experimental opcodes which are
                                likely to change in the future.<br />
                                Use this version if you’d like to use full
                                capabilities of ZScript.
                            </p>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={styles['editor-container']}>
                <AceEditor
                    ref={editorRef}
                    markers={markers}
                    onChange={throttledChangeHandler}
                    mode={"zscript"}
                    height={"100%"}
                    theme={"zenotta-light"}
                    fontSize={'13pt'}
                />
                <div className={styles['stack-state']}>
                    <div className={styles['stack-errors']}>
                        {displayErrors}
                    </div>
                    <div className={styles['stack-container']}>
                        {stack.map((entry, idx) => (<div key={idx} className={styles['stack-item-normal']}>{entry}</div>))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editor;
