
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Modello computazionale`}</h1>
    <p>{`Il nostro `}<em parentName="p">{`modello computazionale`}</em>{` descrive il comportamento delle transazioni programmabili e dei dati programmabili a livello inferiore. È costituito da diversi `}<em parentName="p">{`linguaggi di scrittura`}</em>{` che consentono agli sviluppatori di utilizzare appieno le funzionalità della blockchain di Zenotta.`}</p>
    <p>{`Rispetto al `}<em parentName="p">{`modello simbolico`}</em>{`, il modello computazionale non verifica o esegue in modo rigoroso gli `}<em parentName="p">{`Smart Contracts`}</em>{`, che sono più complessi e possono essere composti da più transazioni e protocolli. Al contrario, l'esecuzione degli script delle transazioni è autonoma e atomica, il che significa che gli script vengono sempre eseguiti nella loro interezza e non dipendono da fattori esterni.`}</p>
    <h2>{`Transazioni`}</h2>
    <p>{`La blockchain di Zenotta segue il modello UTxO. Analogamente al libro mastro distribuito di Bitcoin, la proprietà dei dati e dei token è dedotta dagli output delle transazioni `}<em parentName="p">{`non spese`}</em>{` e questi record sono permanenti e immutabili.`}</p>
    <p>{`Creando, firmando e inviando transazioni, gli utenti possono trasferire la proprietà di token e dati. Il linguaggio di scripting di Zenotta può essere utilizzato per stabilire le condizioni in cui una transazione è spendibile.`}</p>
    <p>{`Il nostro linguaggio di scripting è basato su `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Script"
      }}>{`Bitcoin Script`}</a>{` e utilizza un approccio di programmazione simile a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Forth_(linguaggio_di_programmazione)"
      }}>{`Forth`}</a>{`, basato sullo stack. Il suo codice byte viene eseguito dai minatori durante la verifica delle transazioni su una macchina virtuale a stack. Ogni nodo della rete Zenotta esegue questa macchina virtuale.`}</p>
    <p>{`Forniamo funzionalità aggiuntive rispetto al Bitcoin Script. Ad esempio, grazie alla nostra blockchain basata su UTxO, gli utenti possono utilizzare un generatore di numeri casuali sicuro nei loro script. Questo apre la strada a contratti intelligenti avanzati e a casi d'uso come lotterie o giochi equi.`}</p>
    <p>{`Il linguaggio non è volutamente Turing-completo. Questo approccio rende più facile la verifica formale degli script per assicurarsi che le transazioni non diventino inutilizzabili e che non ci siano istruzioni subdole che rendano un contratto ingiusto.`}</p>
    <p>{`Il linguaggio di scripting delle transazioni viene utilizzato come uno dei target del compilatore per il nostro linguaggio di livello superiore, `}<em parentName="p">{`ZLang`}</em>{`, che compone scenari più complessi a partire da script più semplici.`}</p>
    <p>{`Maggiori dettagli sono contenuti in una RFC che sarà pubblicata a breve su zenotta.io.`}</p>
    <h2>{`Dati intelligenti`}</h2>
    <p>{`Opzionalmente, gli utenti possono utilizzare un altro linguaggio di scripting -- `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PowerShell"
      }}><em parentName="a">{`PowerShell`}</em></a>{` -- per gestire gli smart data. PowerShell è ampiamente utilizzato nel settore per automatizzare varie attività di amministrazione dei sistemi ed è adatto a gestire automaticamente i file di smart data come parte degli Smart Contracts. Gli script PowerShell vengono memorizzati come parte degli `}<em parentName="p">{`smart data`}</em>{`, rendendoli inestricabilmente legati al file e, quando necessario, possono fornire API aggiuntive per gli sviluppatori che costruiscono sulla piattaforma Zenotta.`}</p>
    <p><em parentName="p">{`PowerShell`}</em>{` è anche uno dei target del nostro linguaggio di alto livello `}<em parentName="p">{`ZLang`}</em>{`, il che significa che nella maggior parte dei casi non sarà necessario scrivere questi script a mano: gli script saranno generati e verificati automaticamente dal nostro compilatore.`}</p>
    <p>{`Il nostro team lavora per adattare `}<em parentName="p">{`PowerShell`}</em>{` per lavorare in ambienti più restrittivi, ad esempio la blockchain ha una capacità di archiviazione limitata, quindi abbiamo scritto un documento di proposta che descrive come gli script PowerShell possono essere serializzati in una rappresentazione ad albero della sintassi astratta binaria. Potete trovare il documento `}<a parentName="p" {...{
        "href": "https://hackmd.io/Aas4HLKFRKG5PPyJd-M3Dw"
      }}>{`qui`}</a>{`.`}</p>
    <p>{`Anche le considerazioni sulla sicurezza sono molto importanti sia per Zenotta che per PowerShell. Gli script vengono eseguiti in un ambiente virtualizzato e hanno una visione limitata del sistema host.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;