function getAnchor(text) {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/[ ]/g, '-');
  }

  const H1 = ({ children }) => {
    const anchor = getAnchor(children);
    return (
      <h1 id={anchor}>
        {children}
      </h1>
    );
  };

  const H2 = ({ children }) => {
    const anchor = getAnchor(children);
    return (
      <h2 id={anchor}>
        {children}
      </h2>
    );
  };

  const H3 = ({ children }) => {
    const anchor = getAnchor(children);
    return (
      <h3 id={anchor}>
        {children}
      </h3>
    );
  };
  export { H1, H2, H3 };