import { Theme } from "utils/enums";

const localThemeSet = localStorage.getItem('ZIO_THEME') || Theme.Dark; // Recover user prefered them from localStorage

const themeReducer = (state = localThemeSet, action: { type: string, theme: string }) => {
  switch (action.type) {
    case 'SET_THEME':
      return action.theme;
    default:
      return state;
  }
}

export default themeReducer;