import { useEffect } from 'react';
import styles from './JsxViewer.module.scss';
import { getHeadingsData } from 'utils/hooks.js';
import { useIntersectionObserver } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { setTocItems } from 'store/actions';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const JsxViewer = (props: { content: string, toc: boolean }) => {
  const dispatch = useDispatch();

  useIntersectionObserver();

  useEffect(() => {
    if (props.toc) dispatch(setTocItems(getHeadingsData()));
    else dispatch(setTocItems([]));
  }, [props.toc, dispatch]);

  return (
    <div className={styles.content}>
      <props.content />
    </div>
  );
};

export default JsxViewer;
