const localLanguage = localStorage.getItem('ZIO_I18N_LANGUAGE') || 'en'; // Recover user prefered them from localStorage

const languageReducer = (state = localLanguage, action: { type: string, language: string }) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return action.language;
    default:
      return state;
  }
}

export default languageReducer;