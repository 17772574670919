
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`UNiCORNs`}</h1>
    <p>{`UN-COntestable Random Numbers (UNiCORNs) sind Zahlen, die von einer Gruppe erzeugt werden und die die Eigenschaft haben, dass kein Mitglied der Gruppe die UNiCORN so beeinflussen kann, dass sie etwas anderes als eine Zufallszahl erzeugt. Dies macht UNiCORNs zu idealen Zahlen, um kryptografische Protokolle zu starten und eine faire (zufällige) Auswahl von Elementen aus einer Aufzählungsliste zu beweisen.`}</p>
    <p>{`Im Zenotta-Blockchain-System werden UNiCORNs für 3 grundlegende Prozesse verwendet:`}</p>
    <ul>
      <li parentName="ul">{`Auswahl der Transaktionen, die in einen Block verpackt werden sollen`}</li>
      <li parentName="ul">{`Auswahl der Miner für eine Block-Round-Partition`}</li>
      <li parentName="ul">{`Auswahl des gewinnenden Miners aus den teilnehmenden Minern, die innerhalb einer Blockzeit erfolgreiche Proof-of-Works einreichen`}</li>
    </ul>
    <p>{`Das Originalpapier `}{`[UNiCORN]`}{` (`}<a parentName="p" {...{
        "href": "https://eprint.iacr.org/2015/366.pdf"
      }}>{`https://eprint.iacr.org/2015/366.pdf`}</a>{`) beschreibt den Aufbau und die Anwendbarkeit des Systems.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;