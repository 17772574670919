
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MempoolMiner from '../img/e234a5fa86923a5e87e4b9ee2742f01d/compute-miner.jpeg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Schürfer-Knoten`}</h1>
    <p>{`Der Miner-Knoten repräsentiert den durchschnittlichen Miner im Zenotta-System. Die Miner als Ganzes sind dafür verantwortlich, dass Transaktionsblöcke gemined und der Blockchain-Historie hinzugefügt werden. Für ihre Bemühungen erhalten die erfolgreichen Miner eine Token-Belohnung, ähnlich wie bei Bitcoin. Im Gegensatz zu Bitcoin verwendet Zenotta jedoch eine Mining-Methode, die weniger Energie verbraucht, aber dennoch die nützlichen Aspekte von `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Proof_of_work"
      }}>{`Proof-of-Work (PoW)`}</a>{` beibehält. Diese Methode wird in `}<a parentName="p" {...{
        "href": "#partitioning"
      }}>{`Aufteilung`}</a>{` näher erläutert.`}</p>
    <h2>{`Gleichgewicht der Macht`}</h2>
    <p>{`Zwischen den senatsähnlichen Mempool-Knoten und den öffentlichkeitsähnlichen Miner-Knoten besteht ein Gleichgewicht im römischen Stil, das das Zenotta-System vertrauenslos hält. Während die Mempool-Knoten Transaktionen nach ihrer Validierung zu Blöcken bündeln, obliegt es den Miner-Knoten, dafür zu sorgen, dass diese Blöcke abgebaut und zu den Storage-Knoten hinzugefügt werden. Wenn die Miner mehrheitlich beschließen, einen Block abzulehnen, den ein Mempool-Knoten erstellt hat, kann der Block nicht zum Ledger hinzugefügt werden. Um noch weiter zu gehen, könnten die Miner beschließen, einen bestimmten böswilligen Mempool-Knoten ganz abzulehnen, wodurch dieser nutzlos wird, und im Extremfall das System ganz aufgeben.`}</p>
    <p>{`Die Art und Weise, wie die Fairness in Bezug auf das Block-Mining und die Beteiligung der Miner gewährleistet wird, wird im nächsten Abschnitt erörtert.`}</p>
    <h2>{`Partitionierung`}</h2>
    <p>{`Die Grundlage des Mempool-Miner-Gleichgewichts ist das Partitionierungssystem. Anstatt dass alle Miner den Block in jeder Runde schürfen (was enorme Mengen an Energie verschwendet), werden in jeder Runde nur einige wenige Miner zur Teilnahme ausgewählt. Miner, die nicht ausgewählt wurden, können daher ihre Energie sparen und versuchen, in der nächsten Runde erneut teilzunehmen. In Bezug auf das Mining ist die Struktur des Netzwerks in jeder Runde in diesem Diagramm dargestellt, wobei nur Miner-Knoten (mit einem "M" gekennzeichnet) innerhalb des orangefarbenen Bereichs in der Lage sind, für diesen bestimmten Mempool-Knoten zu schürfen:`}</p>
    <img src={MempoolMiner} alt="compute-miner" />
    <p>{`In jeder Runde können Miner eine Anfrage an einen Mempool-Knoten stellen, mit dem sie verbunden sind, um am Mining des kommenden Blocks teilzunehmen. Jeder Mempool-Knoten kann nur eine begrenzte Anzahl von Minern in seiner Partition aufnehmen, und sobald diese Partitionsgrenze erreicht ist, kann der Block zum Mining gesendet werden.`}</p>
    <p>{`Mempool-Knoten gehen folgendermaßen vor, wenn sie die Partitionsanfrage eines Miners erhalten:`}</p>
    <ul>
      <li parentName="ul">{`Miner A sendet Mempool A eine Anfrage zum Beitritt zu einer Partition. Diese Anfrage enthält einen kleinen Proof-of-Work, um sicherzustellen, dass der Mempool-Knoten nicht von einem oder mehreren böswilligen Minern ausgenutzt wird.`}</li>
      <li parentName="ul">{`Mempool A verwendet dann das Ergebnis des `}<a parentName="li" {...{
          "href": "unicorns"
        }}>{`UNiCORN`}</a>{`-Algorithmus, um die Miner aus der Partition auszuwählen, die dem Mining-Prozess beitreten sollen.`}</li>
      <li parentName="ul">{`Mempool A stellt den Block allen ausgewählten Minern in der Partition zur Verfügung, einschließlich Miner A, und die Miner können nun mit dem Mining beginnen.`}</li>
      <li parentName="ul">{`Die Miner haben eine deterministische Zeitspanne, um den Block zu schürfen, innerhalb derer sie ihr gewinnendes Proof-of-Work für den Block selbst einreichen können.`}</li>
      <li parentName="ul">{`Am Ende der Rundenzeit verwenden die Mempool-Knoten wiederum den UNiCORN, um den siegreichen Miner aus der Menge der gültigen PoW-Einreichungen auszuwählen, und der Block wird zusammen mit ihrer Coinbase an die Storage-Knoten gesendet.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;