
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Tipi di nodi ZNP`}</h1>
    <p>{`A differenza di Bitcoin, dove tutti i minatori di nodi completi svolgono funzioni identiche, Zenotta utilizza diversi tipi di nodi nello ZNP. I diversi tipi di nodo consentono allo ZNP di facilitare tempi rapidi di regolamento dei pagamenti, di garantire la diversità geografica, di alleggerire i requisiti di archiviazione della blockchain e, soprattutto, di consentire i pagamenti token-for-asset.`}</p>
    <h2>{`I tipi in sintesi`}</h2>
    <p>{`Lo ZNP è composto da 3 tipi di nodi principali:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Mempool-Node"
          }}><strong parentName="a">{`Nodo Mempool`}</strong></a>{`: Il nodo Mempool è un tipo speciale di nodo responsabile della raccolta delle transazioni e del loro raggruppamento in nuovi blocchi. Inoltre, suddivide la rete mineraria per l'estrazione e convalida i blocchi estratti da queste partizioni. `}<em parentName="p">{`Esiste solo un numero limitato di nodi Mempool`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Miner-Node"
          }}><strong parentName="a">{`Nodo Minatore`}</strong></a>{`: Il nodo Miner è molto simile al minatore Bitcoin che conoscete. È responsabile del mining dei nuovi blocchi che riceve dai nodi Mempool e raccoglie la ricompensa del blocco associato per i suoi sforzi. Sono in grado di estrarre un blocco solo se riescono a entrare nella partizione per quel round. `}<em parentName="p">{`I nodi Miner possono essere in numero illimitato`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "Storage-Node"
          }}><strong parentName="a">{`Nodo di stoccaggio`}</strong></a>{`: Il nodo Storage è responsabile dell'archiviazione dell'intera storia della blockchain. Una volta che i blocchi sono stati estratti dai minatori e inviati ai nodi Mempool per la convalida, vengono trasferiti ai nodi Storage per l'archiviazione a lungo termine. Chiunque può richiedere la cronologia completa della blockchain ai nodi Storage, ma non è tenuto a conservarla per partecipare alla rete. `}<em parentName="p">{`Esiste solo un numero limitato di nodi Storage`}</em>{`.`}</p>
      </li>
    </ul>
    <h2>{`Perché diversi tipi di nodi?`}</h2>
    <p>{`Una bella domanda, visto che molte blockchain sembrano funzionare con un solo tipo di nodo. Ci sono alcuni problemi chiave che il sistema Zenotta deve affrontare e che i diversi tipi di nodo aiutano a risolvere:`}</p>
    <h3>{`Liquidazione rapida dei pagamenti`}</h3>
    <p>{`Attualmente, Bitcoin raccomanda un tempo di attesa minimo di 6 blocchi prima di accettare una transazione di pagamento. Ciò è dovuto all'architettura di Bitcoin, in cui il proprietario di un token può essere verificato solo `}<em parentName="p">{`dopo`}</em>{` che il proprietario ha speso quel token. Una struttura di questo tipo è quella che chiamiamo `}<strong parentName="p">{`past facing`}</strong>{`, perché ha bisogno di guardare al passato per convalidare una transazione. Questo tipo di fronteggiamento del passato (oltre ai blocchi orfani distribuiti) significa che dobbiamo aspettare un certo periodo di tempo prima di poter affermare con certezza che una transazione è stata inclusa. Questa struttura è ottima per la sicurezza, ma pessima per la velocità di regolamento, e dà luogo a una serie di patch non ideali da risolvere (come Lightning).`}</p>
    <p>{`Zenotta, al contrario, ha un regolamento rapido e un'elevata sicurezza integrati direttamente nella struttura della blockchain, offrendo il meglio di entrambi i mondi. La chiave di questa struttura è l'uso dei nodi Mempool, che sono relativamente pochi rispetto ai nodi Miner. Tutte le transazioni devono passare attraverso i nodi Mempool, che possono coordinare la spesa per le transazioni e verificare la proprietà immediatamente, senza bisogno di blocchi orfani o lunghi tempi di attesa. La domanda "chi nella rete ha la catena più lunga?", che può avere molteplici risposte in qualsiasi momento in Bitcoin, è sempre la stessa per Zenotta: i nodi Mempool.`}</p>
    <h3>{`Diversità geografica`}</h3>
    <p>{`Attualmente, molte criptovalute si concentrano in un'unica regione geografica. La concentrazione dei pool di mining per il Bitcoin è stata per un periodo di tempo considerevole fino all'81% in Cina. Questo comporta alcuni rischi per una criptovaluta, come ad esempio improvvisi cambiamenti nella legislazione, che possono causare interruzioni o addirittura crolli. `}</p>
    <p>{`Per Zenotta, questa sfida è semplice da superare. Ciascuno dei relativamente pochi nodi di Mempool è collocato in una regione geografica diversa; ciò impedisce a una nazione o a un corpo legislativo di influenzare il funzionamento di Zenotta e riduce al minimo il rischio potenziale.`}</p>
    <h3>{`Requisiti di archiviazione più leggeri`}</h3>
    <p>{`L'adesione alle reti Bitcoin o Ethereum come miner completo richiede il download dell'intera blockchain per poter partecipare. Ciò può comportare il consumo di terabyte di memoria, una barriera d'ingresso elevata per i nuovi partecipanti.`}</p>
    <p>{`Per Zenotta, invece, l'onere dell'archiviazione della blockchain è affidato ai nodi Storage. Chiunque può interrogare il nodo Storage per ottenere l'intera cronologia della blockchain, se lo desidera, ma non è necessario per partecipare al mining, il che significa che i potenziali minatori possono iniziare a lavorare il più rapidamente possibile.`}</p>
    <h3>{`Pagamenti di token per asset`}</h3>
    <p>{`Finora, la registrazione dei pagamenti di token per un determinato asset non è stata possibile su nessuna blockchain. La Zenotta DDE Blockchain è la prima blockchain a consentire il trasferimento di token per asset da registrare come pagamenti. Purtroppo, il requisito di registrare il trasferimento di un asset per un altro significa che l'architettura `}<strong parentName="p">{`past facing`}</strong>{` di altre blockchain non funzionerà per Zenotta.`}</p>
    <p>{`Di conseguenza, l'introduzione dei nodi Mempool consente di compilare due "mezze transazioni" e di includerle nello stesso blocco come una singola coppia di transazioni, anche se ciascuna metà proviene da un utente diverso. Questo è possibile solo perché i nodi Mempool esistono e facilitano tutte le transazioni nel sistema, creando un'architettura inedita `}<strong parentName="p">{`present-facing`}</strong>{` per la rete Zenotta.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;