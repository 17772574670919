import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'styles/theme/theme.scss';
import styles from './App.module.scss';
import { TopNav as Nav } from 'components/layout/Nav/Nav';
import Footer from 'components/layout/Footer/Footer';
import Toc from 'components/layout/Toc/Toc';
import LayerMask from 'components/layout/LayerMask/LayerMask';
import SideNav from 'components/layout/SideNav/SideNav';
import NotFound from 'components/pages/NotFound/NotFound';
import {routes} from 'routes';
import PageHandler from 'components/layout/PageHandler/PageHandler';
import { hot } from 'react-hot-loader/root';
import { loadLanguage } from 'utils/functions';
import { useEffect, useState } from 'react';
/**
 * App is the main component of the application. It manages the top level route matchers and layout
 * @returns App content
 */
const App = () => {
  const localLang = useSelector((state: any) => { return state.language });
  // LocalLangs not used directly for layout reason. 
  // Since a page reload is necessary for route components to  update, we wait for reload before changing other content translation from i18n
  const [lang, setLang] = useState(localLang);
  
  const theme = useSelector((state: any) => {
    return state.theme;
  });
  
  useEffect(() => {
    setLang(localLang); // set lang for routes 
    loadLanguage(localLang); // set lang for i18n
  }, [])

  return (
    <div className={`${styles.app} ${theme}`}>
      
      <Nav lang={lang}/>
      <div className={styles.mainContainer} id="mainContainer">
        <SideNav />
        <LayerMask />
        <div className={styles.sideContainer} id="sideContainer">
          <div className={styles.contentContainer} id="contentContainer">
            <div className={styles.content}>
              <Switch>
                {routes.map(({ path, Component, navRoutes }) => {
                  return(
                  <Route key={path} exact={path === '/' ? true : false} path={path}>
                    <PageHandler Component={Component} navRoutes={navRoutes ? navRoutes : []} />
                  </Route>
                )})}
                <Route path={'*'} exact>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </div>
          <Toc />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default hot(App);
