import styles from './Homepage.module.scss';
import { hot } from 'react-hot-loader/root';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import hero from 'static/img/homepage/hero-01.svg';
import researchImg from 'static/img/homepage/research.svg';
import devdocsImg from 'static/img/homepage/devdocs.svg';
import corecontributorsImg from 'static/img/homepage/corecontributors.svg';
import usefullinksImg from 'static/img/homepage/usefullinks.svg';
import Button from 'components/inputs/Button/Button';
import Link from 'components/layout/Link/Link';
import { PromotionalNotification } from 'components/layout/PromotionalNotification/PromotionalNotification';

/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const Homepage = () => {
  const history = useHistory();
  const {t} = useTranslation();

      return (
      <>
        <img src={hero} alt={'hero'} className={styles.hero} />
        <h1>{t("Welcome to Zenottaio")}</h1>
        <p>{t("This website is a hub for all technical resources related to Zenotta's ecosystem.")}</p>
        <p>{t("Access research documentation, interactive notebooks, developer guides, forums, and discussions through the different sections of this hub. This website is an initial release for these resources which will be updated in terms of content periodically, along with additional documentation as it becomes available for dissemination.")}</p>
        <hr className={styles.separator} />
        <div className={styles.section}>
          <img src={researchImg} alt={''} className={styles.leftImg} />
          <div className={styles.subSection}>
            <h2>{t("Research")}</h2>
            <p>
              {t("Research contributing to Zenotta's")} <Link to={'/research/philosophy/peer-to-peer-electronic-trade'}>{t('Philosophy')}</Link>, <Link to={'/research/economics/balances'}>{t(' Economics')}</Link>{t(", and")}
              <Link to={'/research/computer-science/random-reduction-fault-tolerance'}>{t(' Computer science')}</Link> {t("are accessible through the Research section. Learn the science behind the tech, and read the")} <Link to={'/research/philosophy/peer-to-peer-electronic-trade'}>{t('whitepaper')}</Link> {t("describing the Zenotta Digital System.")}
            </p>
            <Button content={t('Research')} onClick={() => history.push('/research')} />
          </div>
        </div>
        <hr className={styles.separator} />
        <div className={styles.section}>
          <div className={styles.subSection}>
            <h2>{t("Documentation")}</h2>
            <p>
              {t("Looking to build on our platform? Learn more about the ")} <Link to={'/developer-documentation/computational/znp/home'}>{t('Zenotta Network Protocol')}</Link>{t(", the development environment, and how to integrate with our blockchain here.")}
            </p>
            <Button content={t('Documentation')} onClick={() => history.push('/developer-documentation')} />
          </div>
          <img src={devdocsImg} alt={''} className={styles.rightImg} />
        </div>
        <hr className={styles.separator} />
        <div className={styles.section}>
          <img className={styles.leftImg} src={corecontributorsImg} alt={''} />
          <div className={styles.subSection}>
            <h2>{t("Core contributors")}</h2>
            <p>
            {t("To join our development community working on our core protocols and technology you will need our RFC frameworks and repo links which will be provided here soon.")}
          </p >
  <Button content={t('Contributors')} onClick={() => history.push('/contributors')} disabled={true} />
        </div >
      </div >
      <hr className={styles.separator} />
      <div className={styles.section}>
        <div className={styles.subSection}>
          <h2>{t("Useful links")}</h2>
          <p>
            {t("Senior team members within Zenotta development add links that inspire us and pay homage to members of the ecosystem and beyond in the furthering of network science, cryptography, blockchain and just good thinking in general.")}
          </p>
          <Button content={t('Useful links')} onClick={() => history.push('/useful-links')} />
        </div>
        <img src={usefullinksImg} alt={''} className={styles.rightImg} />
      </div>
    </>
  );
};

export default hot(Homepage);
