import { CodeBlock } from 'components/layout/CodeBlock/CodeBlock';
import Note from 'components/layout/Note/Note';
import styles from './ReceiptBasedPayments.module.scss';
import Link from 'components/layout/Link/Link';
import { PromotionalNotification } from 'components/layout/PromotionalNotification/PromotionalNotification';
import { useTranslation } from 'react-i18next';

export const ReceiptBasedPayments = () => {
  const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1>{t("Receipt-Based Payments")}</h1>

            {/* <Note type="info">
                <b>{t("Register for the Zenotta Mining Network & TestNet!")}</b> 
                <p className={styles.infoP}>{t('Our TestNet and mining network are open for testing! We are looking for early adopters to help us test our system.')} {'Get started '} 
                <Link to={'/signup'} out={false}><b>{t("here")}</b></Link> {'!'}</p>
            </Note> */}

            <p>
                {t("In addition to tokens, the Zenotta blockchain also supports ")}<code>Receipt</code>{t(" assets, which act as fungible tokens and have no limit on their supply. ")}
                {t("This means you can create as many Receipts as you like, and they can be used in a variety of different ways within the system. ")}
                {t("The simplest use case is just to require a payee to confirm a payment before tokens are sent.")}
            </p>

            <p>
                {t("Such a system means that payment fraud, money laundering, and many other forms of financial crimes can be mitigated (and in some cases completely prevented) by making the use of this Receipt asset in a payment mandatory.")}
            </p>

            <p>
                {t("We call this type of payment a \"Receipt-Based Payment\" (or RBP), and it is a useful means of not only providing a native, blockchain-based proof-of-payment for the payer, but also providing a way for the payee to verify the payment. ")}
                {t("All of this happens on the Zenotta blockchain in a single step, atomically, meaning that the only way Bob can receive ")}
                {t("tokens from Alice is if he supplies her with a ")}
                <code>{t("Receipt")}</code>
                {t(" proof-of-payment ")}
                 <b>{t("at the point of trade")}</b>.
            </p>

            <p>
                {t("If you'd like to know more about the mechanics of how such an atomic trade is possible on a UTxO blockchain,  without a Smart Contract, you can read more about Zenotta's DRUID protocol on our Medium tech blog ")}
                <Link to="https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df" out={true}>{t("here")}</Link>
                {t(" before continuing.")}
            </p>

            <h2>{t("Setting up an RBP")}</h2>

            <p>
                {t("We will start this tutorial the same way we have started the others, and instantiate a connection to the Zenotta blockchain:")}
            </p>

            <CodeBlock
                selectable={false} 
                defaultLang='TypeScript'
                content={[
                    `/** Payer.ts */`,
                    ``,
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    '',
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initNew({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `});`
                ]} />
            
            <p>
                {t("If we are the payer of tokens for a transaction, the process is quite straightforward. ")}
                {t("We want a receipt to acknowledge our payment, so we need to make a specific call for an RBP:")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `/** Payer.ts */`,
                    ``,
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    ``,
                    `//... Initialisation code`,
                    ``,
                    `const keypairs = getAllKeypairs(); // we assume a method to get local keypairs`,
                    `const paymentResult = await client.makeRbPayment(`,
                    `    "d0e72...85b46", // Payment address`,
                    `    10,              // Payment amount`,
                    `    allKeypairs,     // All key-pairs`,
                    `    allKeypairs[0],  // Receive address (which is also the excess/change address)`,
                    `);`,
                    ``,
                    `const { druid, encryptedTx } = paymentResult.content.makeRbPaymentResponse;`,
                    ``,
                    `// Save the encrypted transaction along `,
                    `// with it's corresponding DRUID value `,
                    `saveEncryptedTx(druid, encryptedTx);`,
                ]} />
            
            <p>
                {t("The call in ")}<code>makeRbPayment</code>{t(" sets up the expectations for the trade (in this case 10 tokens for a single ")}<code>Receipt</code> {t(") and submits this to the intercom server that was initialised in the configuration. ")}
                {t("The payee will need to now complete their half of the process, which will involve supplying the required  receipt and then sending their expectations to the intercom server. ")}
                {t("Only if the expectations of both parties match will the transactions be submitted to the blockchain.")}
            </p>

            <p>
                {t("The payer has done the work required to make the transaction happen. Let's see what the payee's code looks like below.")}
            </p>

            <h2>{t("Fetching Pending RBPs")}</h2>

            <p>
                {t("The payee first needs to fetch all the pending RBPs that may be waiting for their approval or rejection. ")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `/** Payee.ts */`,
                    ``,
                    `import { ZenottaInstance } from '@zenotta/zenotta-js';`,
                    ``,
                    `//... Initialisation code`,
                    ``,
                    `const allKeypairs = getAllKeypairs();`,
                    ``,
                    `// allEncryptedTxs can also be an empty array, if we're just fetching pending transactions`,
                    `const allEncryptedTxs = getAllEncryptedTxs();`,
                    ``,
                    `const pendingRbTransactionsResult = await client.fetchPendingRbTransactions(`,
                    `   allKeypairs,`,
                    `   allEncryptedTxs,`,
                    `);`,
                    ``,
                    `const pendingRbTransactions: IFetchPendingRbResponse = pendingRbTransactionsResult.content.fetchPendingRbTransactionsResponse;`
                ]} />

            <p>
                {t("The above code will supply the payee's address and encrypted transaction information to the intercom server. ")}
                {t("Note that even though keypairs are supplied to the `fetchPendingRbTransactions` function, only the addresses are actually sent to the server. ")}
                {t("The intercom server will then respond with all pending transactions requiring approval to submit to the blockchain, with the response looking something like this:")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `{`,
                    `    "18c32...a8478": {`,
                    `        "timestamp": 1646992748926,`,
                    `        "value": {`,
                    `            "DRUID0xf60aa3e17": {`,
                    `                "senderAsset": "Token",`,
                    `                "senderAmount": 10,`,
                    `                "senderAddress": "18c32...a8478",`,
                    `                "receiverAsset": "Receipt",`,
                    `                "receiverAmount": 1,`,
                    `                "receiverAddress": "1a8a7...eb901",`,
                    `                "fromAddr": "32a18...07816",`,
                    `                "status": "pending"`,
                    `            }`,
                    `        }`,
                    `    }`,
                    `}`,
                ]} /> 

            <p>
                {t("From this data structure we are able to obtain specific details about the receipt-based payment, such as the unique identifier ")}<code>DRUID0xf60aa3e17</code>
                {t(", the status of the transaction status, the timestamp of the transaction, as well as the address that made the RBP request: ")}<code>18c32...a8478</code>
                {t(". We are also able to see that in this specific request, the sender expects 1 ")}
                <code>{t("Receipt")}</code>
                {t(" asset in exchange for 10 ")}
                <code>{t("Token")}</code>
                {t(" assets.")}
            </p>   

            <p>
                {t("The payee can then respond to the pending transaction accordingly:")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `// Accept a receipt-based payment using its unique DRUID identifier`,
                    `await client.acceptRbTx('DRUID0xf60aa3e17', pendingRbTransactions, allKeypairs);`,
                    ``,
                    `//--------------------------------- OR ----------------------------------//`,
                    ``,
                    `// Reject a receipt-based payment using its unique DRUID identifier`,
                    `await client.rejectRbTx('DRUID0xf60aa3e17', pendingRbTransactions, allKeypairs);`,
                ]} />
            
            <p>
                {t("As you can see, the payee accepts or rejects on the basis of the DRUID value, which is used to uniquely identify this particular transaction. ")}
                {t("If the payee accepts, the transactions of both the payer and payee, linked by this DRUID value, will be submitted to the blockchain together. ")}
                {t("The Zenotta blockchain will then perform a verification on both transactions, ensure that expectations of both parties are met, and then submit both transactions in the same block.")}
            </p>

            <p>
                {t("At this point you've successfully completed the tutorial on receipt-based payments.")}
            </p>

            <Note type="info">
                {t("Spot a mistake? Need help, or just want to provide feedback? Get hold of us at ")}
                <a href="mailto:dev@zenotta.com?subject=Zenotta.io - Tutorial Feedback">dev@zenotta.com</a>.
            </Note>
        </div>
    );
}
