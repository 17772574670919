
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Transaktionen`}</h1>
    <p>{`Die Transaktionen im Zenotta-System sind ähnlich strukturiert wie die in Bitcoin. Sie bestehen in erster Linie aus einem Verweis auf einen früheren ausgabefähigen Betrag auf der Blockchain (bei Zenotta können dies Token oder Datenwerte sein) und einem Verweis auf eine Adresse, an die der ausgabefähige Betrag zu senden ist. Um dies zu erreichen, muss der Spender mit Hilfe von Public-Key-Signaturverfahren nachweisen, dass er der ursprüngliche Eigentümer des auszahlbaren Betrags ist.`}</p>
    <h2>{`Aufbau`}</h2>
    <p>{`In der Codebasis ist eine Transaktion wie folgt aufgebaut:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct Transaction {
    pub inputs: Vec<TxIn>,
    pub outputs: Vec<TxOut>,
    pub version: usize,
    pub druid: Option<String>,
    pub druid_participants: Option<usize>,
    pub expect_value: Option<Asset>,
    pub expect_value_amount: Option<TokenAmount>,
}
`}</code></pre>
    <p>{`Die Attribute, die für die Überprüfung der Eigentumsverhältnisse von Bedeutung sind, sind "Eingänge" und "Ausgänge", die den Datenstrukturen "TxIn" bzw. "TxOut" entsprechen.`}</p>
    <h2>{`Eingaben`}</h2>
    <p>{`Die Eingaben für eine Transaktion werden in der Datenstruktur "TxIn" kodiert, die wie folgt aussieht:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxIn {
    pub previous_out: Option<OutPoint>,
    pub script_signature: Script,
}
`}</code></pre>
    <p>{`wobei der in `}<inlineCode parentName="p">{`previous_out`}</inlineCode>{` referenzierte `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` wie folgt aussieht:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct OutPoint {
    pub t_hash: String,
    pub n: i32,
}
`}</code></pre>
    <p>{`Hier hat der `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` einen `}<inlineCode parentName="p">{`t_hash`}</inlineCode>{`, der dem Hash der vorherigen ausgabefähigen Transaktion in der Blockchain entspricht. Im Attribut "script_signature" finden wir ein Transaktionsausführungsskript, das festlegt, wie der ausgabefähige Betrag tatsächlich ausgegeben werden soll. Alle `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{`-Einträge enthalten mindestens eine Signatur, die mit dem öffentlichen Schlüssel der bereits in der Blockchain vorhandenen ausgabefähigen Transaktion übereinstimmt. Der öffentliche Schlüssel für diese spezielle Transaktion findet sich in der referenzierten Ausgabe dieser Transaktion.`}</p>
    <h2>{`Ausgaben`}</h2>
    <p>{`Die Ausgaben einer Transaktion werden in der Datenstruktur "TxOut" kodiert, die wie folgt aussieht:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxOut {
    pub value: Option<Asset>,
    pub amount: TokenAmount,
    pub locktime: u64,
    pub drs_block_hash: Option<String>,
    pub drs_tx_hash: Option<String>,
    pub script_public_key: Option<String>,
}
`}</code></pre>
    <p>{`Hier konzentrieren wir uns auf den `}<inlineCode parentName="p">{`script_public_key`}</inlineCode>{`, den öffentlichen Schlüssel, den der Eigentümer der Transaktion verwenden (und mit dem er unterschreiben muss), um den Betrag in Zukunft auszugeben.`}</p>
    <h2>{`Signieren`}</h2>
    <p>{`Im Zenotta-System muss der Benutzer den `}<strong parentName="p">{`Hash des `}<inlineCode parentName="strong">{`OutPoint`}</inlineCode></strong>{`, der sich auf diesen `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` bezieht, signieren, um den `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` der aktuellen Transaktion mit dem `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` der vorherigen zu verbinden. Auf diese Weise wird es unmöglich, einen `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`-Betrag auszugeben, ohne`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`(A)`}</strong>{` Der `}<inlineCode parentName="li">{`OutPoint`}</inlineCode>{`, der auf einen bestimmten `}<inlineCode parentName="li">{`TxOut`}</inlineCode>{` verweist`}</li>
      <li parentName="ul"><strong parentName="li">{`(B)`}</strong>{` Der Benutzer hat den privaten Schlüssel, der dem öffentlichen Schlüssel des "TxOut" entspricht`}</li>
      <li parentName="ul"><strong parentName="li">{`(C)`}</strong>{` Die Möglichkeit, mit dem privaten Schlüssel zu unterschreiben, um die Token weiterzugeben`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;