import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNavItems, setBasePath, setTocItems } from 'store/actions';
import { useRouteMatch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

const PageHandler = ({ Component, navRoutes}: any) => {
  const dispatch = useDispatch();
  let { path: basePath, isExact } = useRouteMatch();

  useEffect(() => {
    dispatch(setNavItems(navRoutes));
    dispatch(setBasePath(navRoutes.length > 1 ? basePath : ''));
    if (navRoutes.length < 1 || (navRoutes[0].toc !== true && isExact)) {
      dispatch(setTocItems([]));
    }
  }, [navRoutes, basePath, isExact, dispatch]);


  return <Component navRoutes={navRoutes} basePath={basePath} />;
};
export default hot(PageHandler);
