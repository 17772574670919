import styles from './Nav.module.scss';
import Logo from 'static/img/zenotta_logo_white.svg';
import { MainRoutes } from 'routes';
import { NavLink, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import ThemeBtn from 'components/inputs/ThemeBtn/ThemeBtn';
import LangSelector from 'components/inputs/LangSelector/LangSelector';
import { BurgerMenu } from 'components/inputs/BurgerMenu/BurgerMenu';
import { useSelector } from 'react-redux';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';


export const TopNav = (props: any) => {
  const { t } = useTranslation();
  let location = useLocation();

  let eventKeyCount = 0; // Counter for Nav.Link eventKey
  return (
    <div className={styles.topNav} id="topNav">
      <Navbar collapseOnSelect className={`${styles.nav} fixed-top`} variant="dark" expand="lg">
        <BurgerMenu />
        <Navbar.Brand as={NavLink} to={'/'} className={styles.brand} >
          <img src={Logo} alt="Zenotta_Logo" />
        </Navbar.Brand>
        <Navbar.Toggle id="navbar-toggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="ms-auto">
            {routes.map((route: MainRoutes) => {
              let result;
              if (!route.hidden) {
                result = (
                  <Nav.Link key={route.path} as={NavLink} to={route.path} eventKey={eventKeyCount++} exact >
                    {t(route.name)}
                  </Nav.Link>
                );
              }
              return result;
            })}
          </Nav>
          <Nav.Link>
            <ThemeBtn />
          </Nav.Link>
          {<Nav.Link>
            <LangSelector />
          </Nav.Link>}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};