
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`UNiCORNs`}</h1>
    <p>{`UN-COntestable Random Numbers (UNiCORNs) sont des nombres générés par un groupe avec la propriété qu'aucun membre du groupe ne peut influencer l'UNiCORN pour produire autre chose qu'un nombre aléatoire. Cela fait des UNiCORN des nombres idéaux pour amorcer des protocoles cryptographiques et prouver la sélection équitable (aléatoire) d'éléments dans une liste énumérée.`}</p>
    <p>{`Dans le système de blockchain Zenotta, les UNiCORN sont utilisés pour 3 processus fondamentaux :`}</p>
    <ul>
      <li parentName="ul">{`Sélection des transactions à emballer dans un bloc`}</li>
      <li parentName="ul">{`Sélection des mineurs pour la partition d'un bloc`}</li>
      <li parentName="ul">{`Sélection du mineur gagnant parmi les mineurs participants qui soumettent des preuves de travail réussies dans un temps de bloc.`}</li>
    </ul>
    <p>{`L'article original `}{`[UNiCORN]`}{` (`}<a parentName="p" {...{
        "href": "https://eprint.iacr.org/2015/366.pdf"
      }}>{`https://eprint.iacr.org/2015/366.pdf`}</a>{`) détaille sa construction et son applicabilité.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;