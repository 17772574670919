/* eslint import/no-webpack-loader-syntax: off */
import ComputationalOverview from '!babel-loader!@mdx-js/loader!./content/ComputationalModel.mdx';
import ComputationalOverviewDe from '!babel-loader!@mdx-js/loader!./content/ComputationalModelDe.mdx';
import ComputationalOverviewIt from '!babel-loader!@mdx-js/loader!./content/ComputationalModelIt.mdx';
import ComputationalOverviewFr from '!babel-loader!@mdx-js/loader!./content/ComputationalModelFr.mdx';
import { GettingStarted } from './content/GettingStarted/GettingStarted';
import { ReceiptBasedPayments } from './content/ReceiptBasedPayments/ReceiptBasedPayments';
import { SendAndReceivePayments } from './content/SendAndReceivePayments/SendAndReceivePayments';
import { ZScriptEmulator } from 'components/inputs/ZScriptEmulator/ZScriptEmulator';
import { ZScriptOpcodes } from './content/ZScriptOpcodes';
import { NavRoutes } from 'utils/interfaces';
import { ContentType } from 'utils/enums';

/**
 * These routes will be automatically generated in Documentation.tsx.
 * The item is also generated as an menu item in SideNav.tsx.
 * @prop path      : Route path
 * @prop name      : Page name (if nav is set to true, it is used as display name)
 * @prop Component : Component to display when routed to path
 * @prop sub       : Sub-items
 */
export const devDocsRoutes: NavRoutes[] = [
  { path: '', name: 'Home' },
  { path: '/getting-started', name: 'Getting Started', Component: GettingStarted, contentType: ContentType.Jsx, toc: true },
  {
    path: '/tutorials',
    name: 'Tutorials',
    sub: [
      { path: '/send-and-receive-payments', name: 'Send and Receive Payments', Component: SendAndReceivePayments, contentType: ContentType.Jsx, toc: true },
      { path: '/receipt-based-payments', name: 'Receipt-Based Payments', Component: ReceiptBasedPayments, contentType: ContentType.Jsx, toc: true },
      // { path: '/game-items-on-the-blockchain', name: 'Game Items on the Blockchain', disabled: true },
      // { path: '/carbon-credits-on-the-blockchain', name: 'Carbon Credits on the Blockchain', disabled: true },
    ],
  },
  {
    path: '/computational',
    name: 'Deep Dive Documentation',
    sub: [
      { path: '/computational-overview', name: 'Computational Overview', Component: {'en':ComputationalOverview, 'fr':ComputationalOverviewFr, 'de':ComputationalOverviewDe, 'it':ComputationalOverviewIt}, contentType: ContentType.Mdx, toc: true },
      { path: '/znp/home', name: 'Zenotta Network Protocol' },
      // { path: '/memory-model-zenotta-value-computer ', name: 'Memory model of the Zenotta Value Computer ', disabled: true },
      // { path: '/sdp-as-pointer-system', name: 'Smart Data Protocol as pointer system', disabled: true },
      // { path: '/network-protocol-consensus-mechanisme', name: 'Network protocol as consensus mechanism', disabled: true },
    ],
  },
  {
    path: '/references',
    name: 'References',
    sub: [
      // { path: '/zscript-emulator', name: 'Zscript emulator', disabled: true },
      { path: '/zscript-ops-code-reference', name: 'ZScript Opcodes Reference', Component: ZScriptOpcodes, contentType: ContentType.Jsx, toc: true },
      // { path: '/powershell', name: 'Powershell', disabled: true },
      // { path: '/evc', name: 'EVC', disabled: true },
    ],
  },
  {
    path: '/tools',
    name: 'Tools',
    sub: [
      { path: '/zscript-emulator', name: 'ZScript Emulator', Component: ZScriptEmulator, contentType: ContentType.Jsx},
      // { path: '/test-net', name: 'Test Net', disabled: true },
    ],
  }
];