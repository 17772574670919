
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Transazioni`}</h1>
    <p>{`Le transazioni nel sistema Zenotta sono strutturate in modo simile a quelle di Bitcoin. Esse consistono principalmente in un riferimento a un precedente importo spendibile sulla blockchain (per Zenotta può trattarsi di token o asset di dati) e in un riferimento a un indirizzo a cui inviare l'importo spendibile. A tal fine, chi spende deve verificare di essere il proprietario originale dell'importo spendibile attraverso schemi di firma a chiave pubblica.`}</p>
    <h2>{`Struttura`}</h2>
    <p>{`Nel codice base, una transazione è strutturata come segue:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct Transaction {
    pub inputs: Vec<TxIn>,
    pub outputs: Vec<TxOut>,
    pub version: usize,
    pub druid: Option<String>,
    pub druid_participants: Option<usize>,
    pub expect_value: Option<Asset>,
    pub expect_value_amount: Option<TokenAmount>,
}
`}</code></pre>
    <p>{`Gli attributi che ci interessano per la verifica della proprietà sono `}<inlineCode parentName="p">{`inputs`}</inlineCode>{` e `}<inlineCode parentName="p">{`outputs`}</inlineCode>{`, corrispondenti rispettivamente alle strutture dati `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` e `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`.`}</p>
    <h2>{`Ingressi`}</h2>
    <p>{`Gli input di una transazione sono codificati nella struttura dati `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{`, che si presenta come segue:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxIn {
    pub previous_out: Option<OutPoint>,
    pub script_signature: Script,
}
`}</code></pre>
    <p>{`dove l'`}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` referenziato in `}<inlineCode parentName="p">{`previous_out`}</inlineCode>{` ha l'aspetto seguente:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct OutPoint {
    pub t_hash: String,
    pub n: i32,
}
`}</code></pre>
    <p>{`Qui l'attributo `}<inlineCode parentName="p">{`OutPoint`}</inlineCode>{` ha un `}<inlineCode parentName="p">{`t_hash`}</inlineCode>{` corrispondente all'hash della precedente transazione spendibile nella blockchain. Nell'attributo `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{` troviamo uno script di esecuzione della transazione che definirà come l'importo spendibile dovrà essere effettivamente speso. In tutte le voci di `}<inlineCode parentName="p">{`script_signature`}</inlineCode>{` c'è almeno una firma che corrisponde alla chiave pubblica della transazione spendibile già presente nella blockchain. La chiave pubblica di questa specifica transazione si trova nell'output di riferimento della transazione stessa.`}</p>
    <h2>{`Uscite`}</h2>
    <p>{`Gli output di una transazione sono codificati nella struttura dati `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`, che si presenta come segue:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`pub struct TxOut {
    pub value: Option<Asset>,
    pub amount: TokenAmount,
    pub locktime: u64,
    pub drs_block_hash: Option<String>,
    pub drs_tx_hash: Option<String>,
    pub script_public_key: Option<String>,
}
`}</code></pre>
    <p>{`Qui ci concentreremo sulla `}<inlineCode parentName="p">{`script_public_key`}</inlineCode>{`, che è la chiave pubblica che il proprietario della transazione deve usare (e firmare) per poter spendere l'importo in futuro.`}</p>
    <h2>{`Firma`}</h2>
    <p>{`Nel sistema Zenotta, per legare il `}<inlineCode parentName="p">{`TxIn`}</inlineCode>{` della transazione corrente al `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` della precedente, l'utente deve firmare l'`}<strong parentName="p">{`hash del `}<inlineCode parentName="strong">{`OutPoint`}</inlineCode></strong>{` che fa riferimento a quel `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{`. In questo modo diventa impossibile spendere un importo di `}<inlineCode parentName="p">{`TxOut`}</inlineCode>{` senza che`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`(A)`}</strong>{` l'`}<inlineCode parentName="li">{`OutPoint`}</inlineCode>{` faccia riferimento a una specifica `}<inlineCode parentName="li">{`TxOut`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`(B)`}</strong>{` l'utente abbia la chiave privata corrispondente alla chiave pubblica del \`TxOut`}</li>
      <li parentName="ul"><strong parentName="li">{`(C)`}</strong>{` Essere in grado di firmare con la chiave privata per spendere i token`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;