import styles from './GettingStarted.module.scss';
import TypeScriptLogo from 'static/img/ts_logo.svg';
import RustLogo from 'static/img/rust_logo.svg';
import PythonLogo from 'static/img/python_logo.svg';
import { CodeBlock } from 'components/layout/CodeBlock/CodeBlock';
import Note from 'components/layout/Note/Note';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { PromotionalNotification } from 'components/layout/PromotionalNotification/PromotionalNotification';
import { useTranslation } from 'react-i18next';
import Link from 'components/layout/Link/Link';

export const GettingStarted = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1 id="getting-started">{t("Getting Started")}</h1>

            {/* <Note type="info">
                <b>{t("Register for the Zenotta Mining Network & TestNet!")}</b> 
                <p className={styles.infoP}>{t('Our TestNet and mining network are open for testing! We are looking for early adopters to help us test our system.')} {'Get started '} 
                <Link to={'/signup'} out={false}><b>{t("here")}</b></Link> {'!'}</p>
            </Note> */}

            <p>
                {t("Integration with Zenotta's blockchain is currently under development. ")}
                {t("In the future, we will be introducing a fully-featured Smart Contracting language built on our blockchain framework, but until then we have provided a set of libraries to enable easy access to the blockchain and its API endpoints.")}
            </p>

            <p>
                {t("These libraries are available for the following languages, and can be installed by following the links provided:")}
            </p>

            <ul className={styles.options}>
                <li>
                    <a href="https://www.npmjs.com/package/@zenotta/zenotta-js" target='_blank' rel='noreferrer'>
                        <img src={TypeScriptLogo} alt="TypeScript" />
                        <p>TypeScript</p>
                    </a>
                </li>
                <li>
                    <a className={styles.disabled} href="https://www.npmjs.com/package/@zenotta/zenotta-js" target='_blank' rel='noreferrer'>
                        <img src={RustLogo} alt="Rust" />
                        <p>Rust</p>
                    </a>
                </li>
                <li>
                    <a className={styles.disabled} href="https://www.npmjs.com/package/@zenotta/zenotta-js" target='_blank' rel='noreferrer'>
                        <img src={PythonLogo} alt="Python" />
                        <p>Python</p>
                    </a>
                </li>
            </ul>

            <p>
                {t("Links that are greyed out are still in development and are currently not available publicly. ")}
                {/* {t("If you're a developer who would like to contribute to the development of these libraries, you're welcome to join us") + " "}<Nav.Link as={NavLink} to="/signup">{t("here")}</Nav.Link>. */}
            </p >

            <h2 id="hello-world">Hello World</h2>
            <p>
                {t("To get started, we can install the Zenotta module that integrates with the chain")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang="TypeScript"
                content={[
                    `npm install @zenotta/zenotta-js`
                ]} />

            <p>
                {t("Once installed we can test the module with a simple hello world example, which will spin up an instance that can connect to the Zenotta blockchain.")}
            </p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    '',
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initNew({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `});`
                ]} />

            <p>
                {t("The Zenotta instance can be initialised in a number of different ways, each of which has its own required parameters:")}
            </p>

            <h3 id='initNew'>initNew</h3>

            <p>{t("The simplest setup, and the one used above, which requires the following arguments:")}</p>

            <ul className={styles.generalList}>
                <li>
                    <code>computeHost</code> - {t("The hostname of the Mempool node service. ")}
        {t("This is used to send transactions to the blockchain, fetch address balances, and perform other calculation-based activities.")}
    </li>
    <li>
        <code>intercomHost</code> - {t("The hostname of the Intercom node service. ")}
        {t("This is used to facilitate Dual Double Entry (DDE) transactions. ")}
        {t("This is an optional parameter and is only required if you are using transactions that require corresponding receipts or data assets.")}
    </li>
    <li>
        <code>passPhrase</code> - {t("The passphrase used to encrypt keypairs generated by the module. ")}
        {t("This value can be anything you want, but of course it must be a reasonably secure value since it controls the security of your keys.")}
    </li>
            </ul >

            <h3 id='initFromMasterKey'>initFromMasterKey</h3>

            <p>{t("If starting the instance with a pre-existing master key, you can supply it to the instance like so:")}</p>

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `import { ZenottaInstance } from 'zenotta-js';`,
                    '',
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initFromMasterKey({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `    },`,
                    `    masterKey: 'MY_MASTER_KEY'`,
                    `});`
                ]} />

            <h3 id='initFromSeed'>initFromSeed</h3>

            <p>
                {t("Finally, you can also start an instance from a pre-existing seed phrase. ")}
                {t("The seed phrase will be expected as a string typed argument:")}
            </p >

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `import { ZenottaInstance, generateSeedPhrase } from 'zenotta-js';`,
                    '',
                    `const seedPhrase = generateSeedPhrase();`,
                    `const client = new ZenottaInstance();`,
                    `const initResult = client.initFromSeed({`,
                    `    computeHost: 'https://compute.zenotta.io',`,
                    `    intercomHost: 'https://intercom.zenotta.io',`,
                    `    passPhrase: 'MY_PASSPHRASE',`,
                    `    },`,
                    `    seedPhrase'`,
                    `});`
                ]} />

            <p>
                {t("The Zenotta module is lightly opinionated, and doesn't prescribe the storage or general handling of your private material, such as master keys or seed phrases. ")}
        {t("This frees you as the developer to decide how you want to store this information in a way that suits your application's use case.")}
    </p>

    <p>
        {t("Once initialised, and your secret information is stored safely, the Zenotta instance can be used for all the utilities required to get running on the Zenotta blockchain. ")}
        {t("You can, for example, generate a new keypair and access its address:")}
    </p>

    <CodeBlock
        selectable={false}
        defaultLang='TypeScript'
        content={[
            `const keypair = client.getNewKeypair();`,
            `console.log(keypair.address); // eg. '5c64683ffc...a7daa68b'`,
        ]} />

    <p>
                {t("If you've received payments to this address you can also fetch the address's balance directly from the blockchain's UTXO table.")}
            </p >

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `const balance = await client.fetchBalance([address]);`,
                ]} />

            <p>
                {t("Responses from the blockchain API are generally detailed and thorough. ")}
                {t("The above call would return a response that gives information about the call itself, as well as extra metadata about what the balance was calculated from.")}
            </p >

            <CodeBlock
                selectable={false}
                defaultLang='TypeScript'
                content={[
                    `"total": {`,
                    `    "tokens": 85000,`,
                    `    "receipts": 0`,
                    `}`,
                    `"address_list": {`,
                    `    "d0e72...85b46": [`,
                    `        {`,
                    `            "out_point": {`,
                    `                "t_hash": "ga070...4df62",`,
                    `                "n": 0`,
                    `            },`,
                    `            "value": {`,
                    `                "Token": 60000`,
                    `            }`,
                    `        },`,
                    `        {`,
                    `            "out_point": {`,
                    `                "t_hash": "g11e3...c916c",`,
                    `                "n": 0`,
                    `            },`,
                    `            "value": {`,
                    `                "Token": 25000`,
                    `            }`,
                    `        },`,
                    `    ],`,
                    `}`,
                ]} />

            <p>
                {t("And that's it, you've successfully set up a TypeScript instance that connects to the Zenotta blockchain! ")}
        {t("If you're ready you can now move on to the next section, where we'll look at how to ")}
        <Nav.Link as={NavLink} to="/developer-documentation/tutorials/send-and-receive-payments">{t("send and receive payments")}</Nav.Link>.
    </p>

    <Note type="info">
        {t("Spot a mistake? Need help, or just want to provide feedback? Get hold of us at ")}
        <a href="mailto:dev@zenotta.com?subject=Zenotta.io - Feedback">dev@zenotta.com</a>.
    </Note>
        </div >
    );
}
