
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Speicher-Knoten`}</h1>
    <p>{`Der Speicherknoten hat eine einzige, einfache Aufgabe: die Speicherung des gesamten Blockchain-Verlaufs. Obwohl dies eine einfache Aufgabe ist, umfasst sie die Speicherung einer Reihe von Komponenten im Zenotta-System, die im Folgenden näher erläutert werden.`}</p>
    <h2>{`Struktur des Speicherknotens`}</h2>
    <p>{`Der Knoten Storage wird von: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageNode`}</inlineCode>{`:`}</strong>{` Datenstruktur`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageInterface`}</inlineCode>{`:`}</strong>{` eine Eigenschaft, die viele der nach außen gerichteten Methoden definiert`}</li>
    </ul>
    <p>{`Es gibt eine begrenzte Anzahl von Storage Nodes, die (im Moment) unter direkter Verwaltung von Zenotta stehen. Jeder öffentliche Schürfer oder Nutzer kann beantragen, ein neuer Speicherknoten zu werden, wenn er dies wünscht, aber dies ist nicht erforderlich, damit das System korrekt funktioniert.`}</p>
    <p>{`Der Ring von Speicherknoten arbeitet unter `}<a parentName="p" {...{
        "href": "https://raft.github.io/"
      }}>{`RAFT consensus`}</a>{`zusammen, wobei jeder Speicherknoten alle abgebauten Blöcke von allen Mempool-Knoten erhält und das kombinierte Ergebnis zu seinem internen Ledger hinzufügt. Die Speicherknoten müssen auch prüfen, ob alle Transaktionen zwischen den Blöcken gleich sind.`}</p>
    <h2>{`Empfang von Blöcken`}</h2>
    <p>{`Der Speicherknoten ist in erster Linie mit dem Empfang von Blöcken befasst, und zwar mit der Methode `}<inlineCode parentName="p">{`receive_block`}</inlineCode>{`. Diese Methode nimmt den Block entgegen und führt folgende Schritte aus:`}</p>
    <ul>
      <li parentName="ul">{`Vergleicht seine Block-ID mit allen anderen Blöcken, die er erhalten hat`}</li>
      <li parentName="ul">{`gleicht Transaktionen zwischen allen Blöcken mit der gleichen ID ab und kennzeichnet alle nicht übereinstimmenden Blöcke als ungültig`}</li>
      <li parentName="ul">{`Führt einen RAFT-Konsens mit gleichrangigen Speicherknoten durch und speichert, sobald dieser erreicht ist, alle gültigen Blöcke in der Datenbank`}</li>
    </ul>
    <h2>{`Empfangen von UNiCORN-Werten`}</h2>
    <p>{`Um sicherzustellen, dass alle Blöcke in der Historie korrekt sind und nicht manipuliert wurden, verwendet die Zenotta-Blockchain einen speziellen Wert namens `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` in Kombination mit einem Block-Hash.`}</p>
    <p>{`Um zu beweisen, dass vergangene UNiCORNs tatsächlich unanfechtbar zufällig und nicht vorberechnet waren, müssen die Tabellen mit den Werten, aus denen sich der UNiCORN zusammensetzt, für die Prüfung aufbewahrt werden. Diese Aufgabe fällt ebenfalls den Speicherknoten zu, und zwar mit der Methode `}<inlineCode parentName="p">{`receive_unicorn_table`}</inlineCode>{`. Auch hier ist das Verfahren für den Empfang einfach:`}</p>
    <ul>
      <li parentName="ul">{`Verwendung der in UNiCORN integrierten Bewertungsfunktion, um zu beweisen, dass die Eingaben für den angegebenen UNiCORN-Wert gültig sind`}</li>
      <li parentName="ul">{`Durchführung eines RAFT-Konsenses mit gleichrangigen Speicherknoten als Teil einer regelmäßigen Blockvalidierung und, sobald dieser erreicht ist, Speicherung des Blocks in der Datenbank`}</li>
    </ul>
    <h2>{`Empfang von DRUIDs`}</h2>
    <p>{`Es ist wichtig, den Verlauf der `}{`[DRUIDs]`}{` (`}<a parentName="p" {...{
        "href": "https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df"
      }}>{`https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df`}</a>{`) im System aufzuzeichnen. Anhand dieser Werte kann jeder überprüfen, zu welchem Zeitpunkt ein Vermögenswert gegen einen anderen getauscht wurde, unabhängig davon, ob es sich um Daten oder Token handelt. `}</p>
    <p>{`Auch hier obliegt die Speicherung der DRUIDs den Storage-Knoten. Der Prozess wird von der Methode "Receive_druids" abgewickelt und besteht einfach darin, die DRUIDs direkt auf der Festplatte zu speichern. Derzeit ist für solche Speicherungen keine Validierungsanforderung vorgesehen.`}</p>
    <p>{`Das Verfahren zur separaten Speicherung von DRUID-Historien ist derzeit nicht implementiert, aber für die Zukunft geplant. Zurzeit können Benutzer diese Historien manuell erstellen, indem sie nach DRUID-bezogenen Transaktionen innerhalb desselben Blocks suchen.`}</p>
    <h2>{`Speicherknoten-Konsens`}</h2>
    <p>{`Damit ein neuer Block erfolgreich zur Blockchain hinzugefügt werden kann, muss er für gültig befunden und dann auf alle aktuellen Storage Nodes repliziert werden. Der Prozess läuft folgendermaßen ab:`}</p>
    <ul>
      <li parentName="ul">{`RAFT wird zuerst eingerichtet und die Wahl des Anführers wird durchgeführt.`}</li>
      <li parentName="ul">{`Ein neuer Siegerblock wird von seinem angeschlossenen Mempool-Knoten an jeden Speicherknoten gesendet (jeder Mempool-Knoten an einen anderen Speicherknoten)`}</li>
      <li parentName="ul">{`Alle diese Blöcke werden an RAFT weitergeleitet und kommen in jedem Speicherknoten als konsensfähig heraus.`}</li>
      <li parentName="ul">{`Bei `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{`, nach jeder Blockrunde, schiebt jeder Knoten einen `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{` ins Floß`}</li>
      <li parentName="ul">{`Wenn sowohl die Mehrheit der `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{` als auch die Mehrheit der zustimmenden `}<inlineCode parentName="li">{`block(blocknum)`}</inlineCode>{` konsensiert sind, ist ein neuer `}<inlineCode parentName="li">{`valid_block`}</inlineCode>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Blockkopf und gemeinsame Transaktionen`}</li>
          <li parentName="ul">{`Konsensierte Gewinner-Nonce und zugehörige Coinbase-Transaktion`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`valid_block`}</inlineCode>{` wird gespeichert und `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{` auf `}<inlineCode parentName="li">{`now + 1 second`}</inlineCode>{` gesetzt`}</li>
      <li parentName="ul">{`Das Speicherereignis wird an den zugehörigen Mempool-Knoten gesendet`}</li>
      <li parentName="ul">{`Mempool-Knoten empfängt `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{` und schiebt ihn zu seinem RAFT`}</li>
      <li parentName="ul">{`Wenn die Mehrheit der Mempool-Knoten `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{` zustimmt, beginnt die nächste Blockschürfrunde`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;