import styles from './ThemeBtn.module.scss'; // Personnalised styles for this component
import { Form } from 'react-bootstrap';
import Sun from 'static/img/sun.svg';
import Night from 'static/img/night.svg';
import { Theme } from 'utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'store/actions';
/**
 * Theme switch button
 * @returns Base component
 */
const ThemeBtn = () => {
  const dispatch = useDispatch();

  const currentTheme = useSelector((state: any) => {
    return state.theme;
  });

  const toggleTheme = () => {
    let theme = currentTheme === Theme.Dark ? Theme.Light : Theme.Dark;
      localStorage.setItem('ZIO_THEME', theme);
      dispatch(setTheme(theme));
  }

  return (
    <div className={styles.content} onClick={() => toggleTheme()}>
      <img className={currentTheme === Theme.Dark ? styles.visible : styles.hidden} src={Night} alt="Night" />
      <Form.Check
        className={styles.themeBtn}
        id={'themeSwitch'}
        type={'switch'}
        // defaultChecked={props.theme === Theme.Light ? true : false}
        checked={currentTheme === Theme.Light ? true : false}
        onChange={() => toggleTheme()}
      />

      <img className={currentTheme === Theme.Light ? styles.visible : styles.hidden} src={Sun} alt="Day" />
    </div>
  );
};

export default ThemeBtn;
