import styles from './Imprint.module.scss';
import Link from 'components/layout/Link/Link';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { COMPANY_ADR, COMPANY_POSTAL, COMPANY_COUNTRY, COMPANY_NAME } from 'utils/constants';
/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const Imprint = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.section}>
      <h1>{t('Imprint')}</h1>
      <div className={styles.group}>
        <div className={styles.block}>
          <p>{t("Operator of the website:")}</p>
          <h4>{COMPANY_NAME}</h4>
          <span>{COMPANY_ADR}</span>
          <span>{COMPANY_POSTAL}</span>
          <span>{COMPANY_COUNTRY}</span>
          <br />
          <span>
            {t("Email")}:
            <a href={'mailto:mail@zenotta.xyz'}>{' mail@zenotta.xyz'}</a>
          </span>
        </div>
        <div className={styles.block}>
          <p>{t("Responsible for the content:")}</p>
          <span>Roelou Barry</span>
          <span>Dr. Alexander Hobbs</span>
          <span>Byron Howens</span>
          <span>Andrew Kessler</span>
        </div>
        <div className={styles.block}>
          <p>{t("Commercial register")}: Kanton Zug</p>
          <p>VAT No.: CHE-361.839.714</p>
          <p>{t("Managing director")}: Roelou Barry</p>
        </div>
      </div>

      <hr className={styles.separator} />

      <div>
        <h4>{t("Liability notice / disclaimer")}</h4>
        <p>
          {t("Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content. We also assume no liability for unsolicited photos, manuscripts, catalogs and data carriers, etc. For suggestions, criticism, errors, bugs, wishes and praise, we look forward to your email:")}
          <a href={'mailto:dev@zenotta.com'}>{' dev@zenotta.com'}</a>

        </p>
        <h4>{t("Copyright")}</h4>
        <p>
          {t("Copyright by ZENOTTA AG. All rights reserved. All texts, images, graphics, sound, video and animation files as well as other arrangements are subject to copyright and other laws for the protection of intellectual property. They may not be copied, changed or used on other websites for commercial purposes or for distribution. Registered trademarks and brand names belong to their respective owners.")}
        </p>
        <h4>{t("Guarantee")}</h4>
        <p>
          {t("The data on this website are provided for informational purposes only and do not claim to be complete. ZENOTTA AG also does not guarantee the accuracy of this data. Accordingly, the data published on this website are not legally binding, and is subject to errors and changes.")}
        </p>
        <Link to='/privacy-policy'>{t("Our Privacy Policy")}</Link>
      </div>
    </div>
  );
};

export default hot(Imprint);

