import { Switch, Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import styles from './DeveloperDocs.module.scss';
import { devDocsRoutes } from './routes';
import { generateNavRoutes } from 'utils/functions';
import { PageProps } from 'utils/interfaces';
import { hot } from 'react-hot-loader/root';
import { loadLanguage } from 'utils/functions';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Icons
import CowboyHat from 'static/img/cowboy-hat.svg';
import HardHat from 'static/img/hard-hat.svg';
import GraduationCap from 'static/img/graduation-cap.svg';


/**
 * Documentation contains all nested routes allowing for side nav navigation
 * @returns Documentation section
 */
const DeveloperDocs = ({basePath} : PageProps) => {
  const { t } = useTranslation();

  const localLang = useSelector((state: any) => { return state.language });
    // LocalLangs not used directly for layout reason. 
  // Since a page reload is necessary for route components to  update, we wait for reload before changing other content translation from i18n
  const [lang, setLang] = useState(localLang);

  useEffect(() => {
    setLang(localLang); // set lang for routes 
    loadLanguage(localLang); // set lang for i18n
  }, [])

  return (
    <>
      <Switch>
        <Route key={basePath} exact path={`${basePath}`}>
          <h1>{t('Developer Documentation')}</h1>
          <p>
            {t("Here you can find everything you need to get started in the Zenotta development environment. You can decide how you want to get started by selecting from the possible options below.")}
          </p>

          <ul className={styles.options}>
            <li>
              <div className={styles.linkIcon}>
                <Nav.Link as={NavLink} to={'/developer-documentation/getting-started/'}>
                  <img alt='' src={GraduationCap} className={styles.optionIcon} />
                </Nav.Link>
              </div>
              <div className={styles.linkContainer}>
                <Nav.Link as={NavLink} to={'/developer-documentation/getting-started/'}>
                  <b>{t("Getting Started")}</b>
                </Nav.Link>
                {t("Just looking to get your feet wet? This is the place to start")}
              </div>
            </li>
            <li>
              <div className={styles.linkIcon}>
                <Nav.Link as={NavLink} to={'/developer-documentation/tutorials/send-and-receive-payments'}>
                  <img alt='' src={CowboyHat} className={styles.optionIcon} />
                </Nav.Link>
              </div>
              <div className={styles.linkContainer}>
                <Nav.Link as={NavLink} to={'/developer-documentation/tutorials/send-and-receive-payments'}>
                  <b>{t('Tutorials')}</b>
                </Nav.Link>
                {t("You want to build something, and you want to do it now")}
              </div>
            </li>
            <li>
              <div className={styles.linkIcon}>
                <Nav.Link as={NavLink} to={'/developer-documentation/deep-dive-documentation'}>
                  <img alt='' src={HardHat} className={styles.optionIcon} />
                </Nav.Link>
              </div>
              <div className={styles.linkContainer}>
                <Nav.Link as={NavLink} to={'/developer-documentation/computational/znp/home'}>
                  <b>{t('Deep Dive Documentation')}</b>
                </Nav.Link>
                {t("Dive into the inner workings and technical details, and learn how the Zenotta Network Protocol (ZNP) works under the hood")}
              </div>
            </li>
          </ul>
        </Route>
        {generateNavRoutes(devDocsRoutes, basePath, lang)}
      </Switch>
    </>
  );
};

export default hot(DeveloperDocs);