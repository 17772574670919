
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Storage Node`}</h1>
    <p>{`The Storage node has a single, simple responsibility: storing the full blockchain history. Although this is a simple task, it involves the storage of a number of components in the Zenotta system which will be further discussed below.`}</p>
    <h2>{`Storage Node Structure`}</h2>
    <p>{`The Storage node is handled by: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageNode`}</inlineCode>{`:`}</strong>{` data structure`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`StorageInterface`}</inlineCode>{`:`}</strong>{` a trait that defines many of its externally-facing methods`}</li>
    </ul>
    <p>{`There are a limited number of Storage nodes that are (for the moment) under direct management of Zenotta. Any public miner or user may request to be a new Storage node should they so choose, but this is not required for the system to operate correctly.`}</p>
    <p>{`The ring of Storage nodes operates under `}<a parentName="p" {...{
        "href": "https://raft.github.io/"
      }}>{`RAFT consensus`}</a>{` together, where each Storage node receives all the mined blocks from all Mempool nodes and adds the combined result to their internal ledger. The Storage nodes also need to assess that all the transactions are the same between blocks.`}</p>
    <h2>{`Receiving Blocks`}</h2>
    <p>{`Primarily, the Storage node is concerned with receiving blocks, which it does through the `}<inlineCode parentName="p">{`receive_block`}</inlineCode>{` method. This method takes the block and performs the following:`}</p>
    <ul>
      <li parentName="ul">{`Compares its block ID against all other blocks it has received`}</li>
      <li parentName="ul">{`Matches transactions between all blocks with the same ID, flagging any non-matching blocks as invalid`}</li>
      <li parentName="ul">{`Performs RAFT consensus with peer Storage nodes and, once reached, saves all valid blocks to the database`}</li>
    </ul>
    <h2>{`Receiving UNiCORN Values`}</h2>
    <p>{`In order to validate that all blocks in the history are correct and haven't been tampered with, Zenotta's blockchain makes use of a special value called a `}<a parentName="p" {...{
        "href": "unicorns"
      }}>{`UNiCORN`}</a>{` in combination with a block hash.`}</p>
    <p>{`In order to prove that past UNiCORNs were indeed uncontestably random and not pre-computed, tables of values which make up the UNiCORN need to be preserved for audit. This task falls to the Storage nodes as well, under the method `}<inlineCode parentName="p">{`receive_unicorn_table`}</inlineCode>{`. Again, the process for receipt is straightforward:`}</p>
    <ul>
      <li parentName="ul">{`Use the UNiCORN's built-in evaluation function to prove that the inputs are valid for the provided UNiCORN value`}</li>
      <li parentName="ul">{`Perform RAFT consensus with peer Storage nodes as part of a regular block validation and, once reached, save the block to the database`}</li>
    </ul>
    <h2>{`Receiving DRUIDs`}</h2>
    <p>{`The history of `}<a parentName="p" {...{
        "href": "https://medium.com/the-zendesk/druids-how-to-easily-trade-on-blockchains-without-smart-contracts-ac1af79a34df"
      }}>{`DRUIDs`}</a>{` in the system is important to record. These values enable anybody to audit the point at which one asset was traded for another, whether that asset is data or tokens. `}</p>
    <p>{`Again, the storage of DRUIDs falls to the Storage nodes. The process is handled by the `}<inlineCode parentName="p">{`receive_druids`}</inlineCode>{` method and consists of simply saving the DRUIDs directly to disk. There is currently no validation requirement planned for such saves.`}</p>
    <p>{`The process of storing DRUID histories separately is currently not implemented but is planned for the future. As of now, users can construct these histories manually by searching for DRUID-related transactions within the same block.`}</p>
    <h2>{`Storage Node Consensus`}</h2>
    <p>{`In order for a new block to successfully be added to the blockchain, it needs to be found valid and then replicated across all current Storage nodes. The process is as follows:`}</p>
    <ul>
      <li parentName="ul">{`RAFT is first set up and leader election is held`}</li>
      <li parentName="ul">{`A new winning block is sent to each Storage node by its connected Mempool node (each Mempool node to a different Storage node)`}</li>
      <li parentName="ul">{`All these blocks are pushed to RAFT and will come out as consensused in each Storage node`}</li>
      <li parentName="ul">{`On `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{`, after each block round, each node pushes to raft a `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode></li>
      <li parentName="ul">{`When both a majority of `}<inlineCode parentName="li">{`TimeoutBlock(blockNum)`}</inlineCode>{` and a majority of agreeing `}<inlineCode parentName="li">{`block(blocknum)`}</inlineCode>{` are consensused, a new `}<inlineCode parentName="li">{`valid_block`}</inlineCode>{` is:`}
        <ul parentName="li">
          <li parentName="ul">{`Block header and common transactions`}</li>
          <li parentName="ul">{`Consensused winning nonce and associated coinbase transaction`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`valid_block`}</inlineCode>{` is stored and `}<inlineCode parentName="li">{`BlockTimeout`}</inlineCode>{` set for `}<inlineCode parentName="li">{`now + 1 second`}</inlineCode></li>
      <li parentName="ul">{`Storage event is sent to associated Mempool node`}</li>
      <li parentName="ul">{`Mempool node receives `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{`, pushes it to its RAFT`}</li>
      <li parentName="ul">{`When a majority of Mempool nodes consensus `}<inlineCode parentName="li">{`BlockStored`}</inlineCode>{`, the next block mining round begins`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;