import styles from './BitMlOverview.module.scss'; // Personnalised styles for this component
import DocIcon from 'static/img/doc.svg';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Component template
 * @returns Base component
 */
const BitMlOverview = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
          <h1>{t('BitMl Overview')}</h1>
          <p>
            {t("BitML is a domain-specific language for developing and executing Smart Contracts on top of UTXO-based blockchains, such as Bitcoin. BitML features a computationally sound compiler which safely translates Smart Contract specifications into sets of transactions. ")}
            {t("This guarantees the secure execution of contracts: namely, the behaviour of a compiled contract on the target blockchain is always coherent with the high-level logic of the contract.")}
          </p>

          <p>
            {t("An extension of BitML for the Zenotta blockchain is currently being designed by Zenotta in collaboration with the BitML creators Massimo Bartoletti (University of Cagliari) and Roberto")}
            {t("Zunino (University of Trento).")}
          </p>
          <ul className={styles.options}>
            <li>
              <div className={styles.linkIcon}>
                <Nav.Link as={NavLink} to={'/research/computer-science/bitml/bitcoin-smart-contracts'}>
                  <img alt={'doc icon'} src={DocIcon} className={styles.optionIcon} />
                </Nav.Link>
              </div>
              <div className={styles.linkContainer}>
                <Nav.Link as={NavLink} to={'/research/computer-science/bitml/bitcoin-smart-contracts'}>
                  <b>{t('A Calculus for Bitcoin Smart Contracts')}</b>
                </Nav.Link>
                {t("If you wish to access the original paper, please click ")}<a href='https://eprint.iacr.org/2018/122' target='_blank' rel='noreferrer'>{t("here")}</a>.
              </div>
            </li>
            <li>
              <div className={styles.linkIcon}>
                <Nav.Link as={NavLink} to={'/research/computer-science/bitml/bitcoin-covenants-unchained'}>
                  <img alt={'doc icon'} src={DocIcon} className={styles.optionIcon} />
                </Nav.Link>
              </div>
              <div className={styles.linkContainer}>
                <Nav.Link as={NavLink} to={'/research/computer-science/bitml/bitcoin-covenants-unchained'}>
                  <b>{t('Bitcoin covenants unchained')}</b>
                </Nav.Link>
                {t("If you wish to access the original paper, please click ")}<a href='https://arxiv.org/abs/2006.03918' target='_blank' rel='noreferrer'>{t("here")}</a>.
              </div>
            </li>
          </ul>
    </div>
  );
};

export default BitMlOverview;
