
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Il protocollo di rete Zenotta`}</h1>
    <p>{`Lo Zenotta Network Protocol (ZNP) è responsabile di tutto il codice relativo alla rete nel sistema Zenotta. È il repo responsabile della comunicazione tra i nodi della rete mineraria e dell'integrazione con la struttura dati della blockchain presente nel repo `}<inlineCode parentName="p">{`NAOM`}</inlineCode>{`. Qui di seguito sono riportati i contenuti della documentazione che illustra l'architettura del progetto nel suo complesso:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "node-types"
        }}>{`Tipi di nodo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mempool-node"
        }}>{`Nodo mempool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "miner-node"
        }}>{`Nodo minatore`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "storage-node"
        }}>{`Nodo di stoccaggio`}</a>
        {/* - [Il portafoglio](wallet) */}
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "unicorns"
        }}>{`UNiCORN`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "transactions"
        }}>{`Transazioni`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "block-mining"
        }}>{`Processo di estrazione dei blocchi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "dual-double-entry"
        }}>{`Transazioni a doppia entrata`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "shuriken-auditing"
        }}>{`Audit Shuriken`}</a></li>
    </ul>
    <h1>{`Come iniziare`}</h1>
    <p>{`Per iniziare, è importante capire come lo ZNP si inserisce nel sistema Zenotta. Il sistema Zenotta è costituito principalmente da 3 componenti principali:`}</p>
    <ul>
      <li parentName="ul">{`Il Protocollo di rete Zenotta (ZNP)`}</li>
      <li parentName="ul">{`La Blockchain a doppia entrata (DDE)`}</li>
      <li parentName="ul">{`Il Protocollo Dati Zenotta (ZDP)`}</li>
    </ul>
    <p>{`Per ora considereremo solo i primi due elementi di questo elenco. Lo `}<strong parentName="p">{`ZNP`}</strong>{` invia e riceve messaggi che vengono trasmessi tra diversi tipi di nodi e trasmette anche la memorizzazione alla `}<strong parentName="p">{`DDE Blockchain`}</strong>{` al fine di mantenere una registrazione della storia della blockchain.`}</p>
    <h2>{`Architettura del sistema`}</h2>
    <p>{`L'architettura del sistema Zenotta rispecchia in gran parte quella di Bitcoin, in quanto la nostra blockchain si basa sul consenso Proof-of-Work (PoW). A differenza del Bitcoin tradizionale, tuttavia, la nostra blockchain è in grado di garantire una maggiore velocità delle transazioni (con conseguenti periodi di liquidazione più rapidi) e il nostro sistema PoW è più leggero e inclusivo (ma di questo parleremo più avanti).`}</p>
    <p>{`L'altra grande differenza tra Zenotta e le blockchain tradizionali è l'introduzione del sistema a doppia entrata (DDE). A differenza delle blockchain tradizionali, la nostra blockchain è in grado di registrare sia il movimento dei token `}<em parentName="p">{`che`}</em>{` il movimento degli asset per quei token. Grazie al nostro `}<strong parentName="p">{`ZDP`}</strong>{` o Zenotta Data Protocol, questi asset possono essere asset di dati possedibili e negoziabili.`}</p>
    <p>{`Per facilitare ciò, l'architettura di Zenotta fa uso di diversi tipi di nodi nella sua rete. Per saperne di più sui diversi tipi di nodi `}{`[qui]`}{` (node-types).`}</p>
    <h2>{`Progettazione del codice`}</h2>
    <p>{`Abbiamo una filosofia di progettazione specifica per quanto riguarda il codice scritto in Zenotta. L'ideale è avere un codice facilmente riutilizzabile, ma soprattutto facilmente comprensibile. Trattiamo il nostro codice come un rapporto client-server, dove il codice è il server e l'utente umano è il client. A tal fine abbiamo adottato l'approccio della `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multitier_architecture#Three-tier_architecture"
      }}>{`Three Tier Architecture`}</a>{` reso popolare da `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_J._Donovan"
      }}>{`John J. Donovan`}</a>{`, che in breve consiste in quanto segue:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Tipo di presentazione`}</strong>{`: Da trattare come l'interfaccia utente. A questo livello dovrebbero esserci le massime astrazioni per ogni modulo che scriviamo, consistenti in poche funzioni facili da usare che possono essere implementate in un'API per il consumo generale.
`}<strong parentName="p"><em parentName="strong">{`Livello di sviluppo: Principiante`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Logic Tier`}</strong>{`: È un livello inferiore a quello di presentazione, dove il codice è più complesso ma è in grado di svolgere funzioni più potenti. A questo livello si trovano la logica e le operazioni commerciali, che aiutano a facilitare le astrazioni di livello superiore del livello di presentazione. `}<strong parentName="p"><em parentName="strong">{`Livello di sviluppo: Intermedio`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Tipo dati`}</strong>{`: È il livello più basso dell'architettura del codice e come tale è il più complesso. Le funzioni e le operazioni a questo livello interagiscono direttamente con le operazioni a livello di sistema, come la gestione dell'I/O e delle connessioni di rete, oltre all'interazione diretta con la blockchain. `}<strong parentName="p"><em parentName="strong">{`Livello sviluppatore: Avanzato`}</em></strong></p>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;