import styles from './SearchableList.module.scss';
import React, { useState, KeyboardEvent, ReactElement, useContext } from 'react';
import ScrollTrigger from 'react-scroll-trigger';


interface SearchableListProps {
    children?: React.ReactNode;
    initialSearch: string;
}

interface ItemProps {
    children?: React.ReactNode;
    word: string;
    code: number;
    description?: string;
}

interface SectionProps {
    children?: React.ReactNode;
    name: string;
}

const SearchContext = React.createContext('');

export const Item = (props: ItemProps) => {
    // const searchString = useContext(SearchContext).toLowerCase();
    const hex = `0x${props.code.toString(16)}`;

    return (
        <li>
            <ul className={styles['searchable-item-card']}>
                <li><strong>{props.word}</strong></li>
                <li><strong>Code:</strong> {hex} ({props.code})</li>
                {props.description && <li>{props.description}</li>}
                <li>
                    {props.children}
                </li>
            </ul>
        </li>
    );
}

export const Section = (props: SectionProps) => {

    const searchString = useContext(SearchContext).toLowerCase();

    const itemsToDisplay = React.Children.toArray(props.children).filter((c) => {
        const child = c as ReactElement;
        return child.props.word.toLowerCase().includes(searchString) ||
            child.props.description.toLowerCase().includes(searchString);
    });

    return itemsToDisplay.length < 1 ? null : (
        <>
            <h2 id={props.name.replace(' ', '-').toLowerCase()}>{props.name}</h2>
            <ul className={styles['searchable-section']}>{itemsToDisplay}</ul>
        </>
    );
}

export const SearchableList = (props: SearchableListProps) => {
    const [searchString, setSearchString] = useState('');
    const [fixed, setFixed] = useState(false);
    // const sections = React.Children.toArray(props.children);

    const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setSearchString(target.value);
    }

    const handleOnEnter = () => {
        if (!fixed) setFixed(false);
    }

    const handleInputViewportProgress = (state: any) => {
        if (state.progress > 0.85) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    }

    return (
        <>
            <ScrollTrigger onEnter={handleOnEnter} onProgress={handleInputViewportProgress}>
                <div className={
                    fixed ?
                        styles['searchable-list-input-fixed-container'] :
                        styles['searchable-list-input-container']}>
                    <input
                        className={styles['searchable-list-input']}
                        onKeyUp={(keycode) => handleSearch(keycode)}
                        placeholder='Filter by OP_CODE or Description...'
                    />
                </div>
            </ScrollTrigger>
            <div className={styles['searchable-list']}>
                <SearchContext.Provider value={searchString}>
                    {props.children}
                </SearchContext.Provider>
            </div>
        </>
    );
}
