import Link from 'components/layout/Link/Link';

/**
 * Link component for the MdxViewer, 
 * different website links with links pointing to external websites 
 * @returns 
 */
export const A = ({ children, href }) => {
    if (href.startsWith('https://') || href.startsWith('http://')) {
        return (
            <Link to={href} out={true}>{children}</Link>
        );
    } else {
        return (
            <Link to={href}>{children}</Link>
        );
    }
};